import cx from "classnames";
import React, { ReactNode } from "react";

import { AnimatedElement } from "../../helpers/animatedElement";
import { QuoteIcon } from "../../helpers/Svg";
import global from "../../scss/global.module.scss";
import styles from "./quote.module.scss";

interface Quote {
  text: ReactNode;
  name: ReactNode;
  job: ReactNode;
}

const Quote: React.FC<Quote> = (props: Quote) => {
  const { text, name, job } = props;

  return (
    <div className={cx(global.container, global.small)}>
      <div>
        <QuoteIcon className={styles.quoteSvg} />
      </div>
      <div className={styles.quoteTexte}>{text}</div>
      <div className={styles.quoteAuthorContainer}>
        <div>
          <h4 className={styles.name}>{name}</h4>
          <span className={styles.job}>{job}</span>
        </div>
        <div>
          <QuoteIcon className={styles.smallQuoteSvg} />
        </div>
      </div>
    </div>
  );
};

export default Quote;
