import anime from "animejs";
import { MiniSignalBinding } from "mini-signals";
import React, { useEffect, useRef } from "react";

import eventDispatcher from "../eventDispatcher/eventDispatcher";
import { isVisible } from "../isVisible";

interface AnimatedProps {
  animation?: "parallaxe" | "rotate";
  direction?: "up" | "down" | "left" | "right";
  coef?: number;
  translateY?: {
    speed: number;
  };
  translateX?: {
    speed: number;
  };
  rotateX?: {
    speed: number;
  };
  rotateY?: {
    speed: number;
  };
  rotateZ?: {
    speed: number;
  };
  children: any;
}

export const AnimatedElement: React.FC<AnimatedProps> = (props: AnimatedProps) => {
  const {
    translateY: translateYSettings,
    translateX: translateXSettings,
    rotateX: rotateXSettings,
    rotateY: rotateYSettings,
    rotateZ: rotateZSettings,
    children,
  } = props;
  const _defaultSettings = {
    translateY: {
      speed: 10,
    },
    translateX: {
      speed: 0,
    },
    rotateX: {
      speed: 0,
    },
    rotateY: {
      speed: 0,
    },
    rotateZ: {
      speed: 0,
    },
  };
  const translateY = useRef({
    ..._defaultSettings.translateY,
    ...translateYSettings,
  }).current;
  const translateX = useRef({
    ..._defaultSettings.translateX,
    ...translateXSettings,
  }).current;
  const rotateX = useRef({ ..._defaultSettings.rotateX, ...rotateXSettings }).current;
  const rotateY = useRef({ ..._defaultSettings.rotateY, ...rotateYSettings }).current;
  const rotateZ = useRef({ ..._defaultSettings.rotateZ, ...rotateZSettings }).current;
  const speedDiviser = 100; // Change the speed range from [0, 100] to [0, 1]
  const updateRef = useRef<MiniSignalBinding>();
  let elem: HTMLElement;
  let initTop = useRef<number>(0).current;
  let y = useRef(0).current;
  let winHeight = useRef(0).current;

  useEffect(() => {
    if (children.ref === null) {
      throw new Error("children.ref is null");
    }
    _resize();
    elem = children.ref.current;
    updateRef.current = eventDispatcher.on("_update", _onUpdate);
    winHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    const tempTop = elem.getBoundingClientRect().top - winHeight;
    initTop = tempTop < 0 ? 0 : tempTop;

    return () => {
      if (updateRef.current) {
        eventDispatcher.off("_update", updateRef.current);
      }
    };
  }, []);

  const _onUpdate = ({ _y }: { _y: number }) => {
    y += _y - initTop - y;
    if (!isVisible(elem)) {
      return;
    }

    _translateY();
    _translateX();
    _rotateX();
    _rotateY();
    _rotateZ();
  };

  const _translateY = () => {
    const _scrollY = (y * translateY.speed) / speedDiviser;
    anime.set(elem, { translateY: _scrollY });
  };

  const _translateX = () => {
    const _scrollX = (y * translateX.speed) / speedDiviser;
    anime.set(elem, { translateX: _scrollX });
  };

  const _rotateX = () => {
    const _rotationX = (y * rotateX.speed) / speedDiviser;
    anime.set(elem, { rotateX: _rotationX });
  };

  const _rotateY = () => {
    const _rotationY = (y * rotateY.speed) / speedDiviser;
    anime.set(elem, { rotateY: _rotationY });
  };

  const _rotateZ = () => {
    const _rotationZ = (y * rotateZ.speed) / speedDiviser;
    anime.set(elem, { rotateZ: _rotationZ });
  };

  const _resize = () => {
    winHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
  };

  return <>{children}</>;
};
