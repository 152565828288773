import { RouteComponentProps } from "@reach/router";
import anime from "animejs";
import cx from "classnames";
import { graphql, useStaticQuery } from "gatsby";
import { MiniSignalBinding } from "mini-signals";
import React, { useEffect, useRef } from "react";
import Announcement from "../blocks/announcement/announcement";
import Intro from "../blocks/intro/intro";
import JoinUs from "../blocks/joinus/joinus";
import KeyInfos, { KeyInfo } from "../blocks/keyinfos/keyinfos";
import { LoginModalOpen } from "../blocks/loginModal/loginModal";
import { NewsletterModalOpen } from "../blocks/newsletterModal/newsletterModal";
import Quote from "../blocks/quote/quote";
import Team from "../blocks/team/team";
import SEO from "../components/seo";
import eventDispatcher from "../helpers/eventDispatcher/eventDispatcher";

import { NavigateData, NavLink } from "../helpers/NavLink";
import { onInitPageLoadTracking, sendGA } from "../helpers/tracking";
import global from "../scss/global.module.scss";

export const MissionPage: React.FC<RouteComponentProps> = (props: RouteComponentProps) => {
  const { location } = props;
  const data = useStaticQuery(MissionPageQuery);
  const navRef = useRef<MiniSignalBinding>();
  const pageRef = useRef(null);
  const newsRef = useRef(null);
  const introSrc = data.introImg.childImageSharp.fluid;
  const delay = 200;

  useEffect(() => {
    onInitPageLoadTracking();
    enterAnimation();
    eventDispatcher.trigger("_initComponent", { delay });
    navRef.current = eventDispatcher.on("_navigate", exitAnimation);

    newsRef.current = setTimeout(() => {
      eventDispatcher.trigger(NewsletterModalOpen);
    }, 3000);

    return () => {
      if (navRef.current) {
        eventDispatcher.off("_navigate", navRef.current);
      }
      if (newsRef.current) {
        clearTimeout(newsRef.current);
      }
    };
  }, []);

  const exitAnimation = (d: NavigateData) => {
    const tl = anime.timeline({
      complete: () => {
        d.callback();
      },
      duration: 600,
      easing: "easeInOutExpo",
    });

    tl.add({
      opacity: [1, 0],
      targets: pageRef.current,
    });
  };
  const enterAnimation = () => {
    const tl = anime.timeline({
      delay,
      duration: 400,
      easing: "linear",
    });

    tl.add({
      opacity: [0, 1],
      targets: pageRef.current,
    });
  };

  return (
    <>
      <SEO
        meta={[
          {
            property: "og:url",
            content: location.href,
          },
        ]}
        title="elinoï - L'équipe des nouveaux recruteurs"
        description="Chez elinoï, nous croyons à un nouveau recrutement moins standardisé et plus humain. Dans notre équipe, des gens à contre-courant du recrutement classique qui connaissent les entreprises et comprennent les talents."
      />
      <div style={{ opacity: 0 }} className={global.pageWrapper} ref={pageRef}>
        <Intro
          fluid={introSrc}
          text={introTexte}
          title={introTitle}
          cta={
            <NavLink
              href="https://get.elinoi.com/elinoi-entreprise"
              className={global.cta}
              onClick={() => {
                sendGA("click", {
                  event_label: "nous rejoindre - top",
                });
              }}
            >
              Nous rejoindre
            </NavLink>
          }
        />

        <KeyInfos
          title={
            <>
              Nos <strong>valeurs</strong>
            </>
          }
          infos={keyInfos}
          cta={
            <span
              className={cx(global.cta, global.ctaPrimary)}
              onClick={() => {
                eventDispatcher.trigger(LoginModalOpen);
                sendGA("click", {
                  event_label: "ouverture modale - s'inscrire - valeur ajoutée",
                });
              }}
            >
              S'inscrire
            </span>
          }
        />

        <Team />

        <Announcement />

        <Quote
          text="Avant de postuler dans une entreprise, j’aurais aimé connaître l’envers du décor : son état d’esprit, ses enjeux, ses perspectives d’évolution plutôt que son nombre de baby-foots. Des infos que je n’ai pas trouvées et qui m’auraient aidé à faire la différence entre des jobs qui se ressemblaient sur le papier."
          name="David Simeoni"
          job="CEO et fondateur d'elinoï"
        />

        <JoinUs
          subtitle="Postuler"
          title="envie de rejoindre l’aventure elinoï ?"
          text="Nous sommes toujours à la recherche de profils entrepreneuriaux et motivés pour nous aider à développer notre projet."
          cta={
            <NavLink
              href="https://get.elinoi.com/elinoi-entreprise"
              className={global.cta}
              onClick={() => {
                sendGA("click", {
                  event_label: "nous rejoindre - bottom",
                });
              }}
            >
              Nous rejoindre
            </NavLink>
          }
        />
      </div>
    </>
  );
};

const introTitle = (
  <>
    <span ref={React.createRef()}>La nouvelle</span>
    <span ref={React.createRef()}>
      <strong>bonne</strong>
    </span>
    <span ref={React.createRef()}>alternative</span>
    <span ref={React.createRef()}>du recrutement.</span>
  </>
);
const introTexte = (
  <>
    Chez elinoï, nous pensons que le recrutement peut et doit mieux faire. Nous sommes convaincus qu’il existe un
    nouveau recrutement moins standardisé, plus humain et plus équitable. Au sein de nos équipes, des gens à
    contre-courant du recrutement classique, qui connaissent les entreprises et comprennent les talents d’aujourd’hui.
    Ensemble, nous nous sommes donnés comme mission de redorer le blason du recrutement.
  </>
);

const keyInfos: KeyInfo[] = [
  {
    big: "01",
    bigColor: "#303030",
    title: "La bienveillance",
    text: (
      <>
        <strong>Au coeur</strong> des échanges et des préoccupations.
      </>
    ),
  },
  {
    big: "02",
    bigColor: "#303030",
    title: "La transparence",
    text: (
      <>
        pour un accompagnement <strong>éclairé et pertinent</strong>.
      </>
    ),
  },
  {
    big: "03",
    bigColor: "#303030",
    title: "L’exigence",
    text: (
      <>
        pour <strong>se dépasser</strong> et aller toujours plus loin.
      </>
    ),
  },
];

export const MissionPageQuery = graphql`
  query {
    introImg: file(relativePath: { eq: "photo-4.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
