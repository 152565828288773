import anime from "animejs";
import React, { ReactNode, useEffect, useRef, useState } from "react";
import eventDispatcher from "../../helpers/eventDispatcher/eventDispatcher";
import Slideshow from "../../helpers/slideshow/slideshow";
import SlideshowItem from "../../helpers/slideshow/slideshowItem";
import { sendGA } from "../../helpers/tracking";
import useWindowResize from "../../hook/useWindowResize.js";
import { $, setRef } from "../../utils/dom";
import uniqid from "../../utils/uniqid";
import styles from "./index.module.scss";
import Timeline from "./timeline";

export interface VerticalSlide {
  text: ReactNode;
  title: ReactNode;
  cta: ReactNode;
}
export interface VerticalSliderInterface {
  title: ReactNode;
  items: VerticalSlide[];
}

const VerticalSlider: React.FC<VerticalSliderInterface> = (props: VerticalSliderInterface) => {
  const id = uniqid();

  const $wrapper = useRef([]);
  const $numRef = useRef([]);

  // State
  const [mobile, setMobile] = useState<boolean>(typeof window !== "undefined" ? window.innerWidth <= 750 : false);

  const splitNums = (num: number) => {
    const nb = num.toString().split("");
    const nbs = [];
    for (let index = 0; index < nb.length; index++) {
      nbs.push(
        <i ref={(el) => setRef(el, $numRef)} className="n">
          {nb[index]}
        </i>,
      );
    }

    return nbs;
  };

  const animateIn = (i: number) => {
    const $w: HTMLDivElement = $wrapper.current[i];
    const $p: any = $w.parentNode;
    const $nums = $($w, "n");
    const $donut = $($w, styles.donut);
    const $text = $($w, styles.text);
    const $halfCircle = $($w, styles.halfCircle);

    const d = $p.offsetHeight;
    const delay = d / 1.6;

    anime.set($nums, { display: "inline-block" });

    const tl = anime.timeline({
      autoplay: false,
    });

    if (!mobile) {
      tl.add(
        {
          targets: $nums,
          duration: d,
          delay: anime.stagger(delay),
          translateY: ["75%", "0%"],
          easing: "linear",
        },
        0,
      );
      tl.add(
        {
          targets: $donut,
          duration: d,
          delay,
          translateY: ["50%", "0%"],
          easing: "linear",
        },
        0,
      );
      tl.add(
        {
          targets: $text,
          duration: d,
          delay,
          opacity: [0, 1],
          translateY: ["100%", "0%"],
          easing: "linear",
        },
        0,
      );
      tl.add(
        {
          targets: $halfCircle,
          duration: d,
          delay,
          rotate: ["90deg", "0deg"],
          easing: "linear",
        },
        0,
      );
    } else {
      tl.add(
        {
          targets: $text,
          duration: d,
          delay,
          translateX: ["25%", "0%"],
          easing: "linear",
        },
        0,
      );
      tl.add(
        {
          targets: $halfCircle,
          duration: d,
          delay,
          rotate: ["90deg", "0deg"],
          easing: "linear",
        },
        0,
      );
    }

    return tl;
  };

  const animateOut = (i: number) => {
    const $w: HTMLDivElement = $wrapper.current[i];
    const $p: any = $w.parentNode;
    const $nums = $($w, "n");
    const $donut = $($w, styles.donut);
    const $halfCircle = $($w, styles.halfCircle);

    const d = $p.offsetHeight;

    const tl = anime.timeline({
      autoplay: false,
    });

    if (!mobile) {
      tl.add(
        {
          targets: $nums,
          duration: d,
          delay: anime.stagger(-d / 5, { start: 0 }),
          translateY: ["0%", "-50%"],
          easing: "linear",
        },
        d / 5,
      );
      tl.add(
        {
          targets: $donut,
          duration: d,
          translateY: ["0%", "-50%"],
          easing: "linear",
        },
        0,
      );
      tl.add(
        {
          targets: $halfCircle,
          duration: d,
          rotate: ["0deg", "-90deg"],
          easing: "linear",
        },
        0,
      );
    } else {
      tl.add(
        {
          targets: $halfCircle,
          duration: d,
          rotate: ["0deg", "-90deg"],
          easing: "linear",
        },
        0,
      );
    }

    return tl;
  };

  const resize = () => {
    setMobile(window.innerWidth <= 750);
  };

  useEffect(() => {
    resize();
    const tlIn: any[] = [];
    const tlOut: any[] = [];

    for (let i = props.items.length - 1; i >= 0; i--) {
      tlIn[i] = animateIn(i);
      tlOut[i] = animateOut(i);
    }

    eventDispatcher.on("animate." + id, (itemsProps) => {
      for (let i = itemsProps.length - 1; i >= 0; i--) {
        const { rIn, rOut } = itemsProps[i];

        if (rIn >= 0 && rIn <= 1) {
          tlIn[i].seek(tlIn[i].duration * rIn);
        }

        if (rOut >= 0 && rOut <= 1) {
          tlOut[i].seek(tlOut[i].duration * rOut);
        }
      }
    });
  }, [id, props.items]);

  useWindowResize(() => resize());

  return (
    <div className={styles.vericalSlider}>
      <h2 className={styles.blockTitle}>{props.title}</h2>

      <Slideshow
        id={id}
        items={props.items}
        styles={styles}
        isVertical={!mobile}
        onIndexChange={() => {
          sendGA("scroll", {
            event_label: "etapes - changement",
          });
        }}
      >
        {props.items.map((item, i) => (
          <SlideshowItem key={i} styles={styles}>
            <div ref={(el) => setRef(el, $wrapper)} className={styles.wrapper}>
              {++i < 10 ? (
                <span className={styles.floatNum}>
                  <i className="n">0</i>
                  <i className="n">{i}</i>
                </span>
              ) : (
                <span className={styles.floatNum}>{splitNums(i)}</span>
              )}

              <div className={styles.halfCircle}></div>
              <div className={styles.circle}></div>
              <div className={styles.donut}></div>

              <span className={styles.label}>_Étape {i}:</span>

              <h3 className={styles.title}>{item.title}</h3>

              <p className={styles.text}>{item.text}</p>
            </div>
            <div className={styles.linkWrapper}>{item.cta}</div>
          </SlideshowItem>
        ))}
      </Slideshow>

      <Timeline id={id} items={props.items} />
    </div>
  );
};

export default VerticalSlider;
