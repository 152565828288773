import React, { PropsWithChildren } from "react";

interface SlideshowItem {
  className?: string;
  styles: { [key: string]: string };
}

const SlideshowItem: React.FC<PropsWithChildren<SlideshowItem>> = (props: PropsWithChildren<SlideshowItem>) => {
  const { children } = props;

  return <>{children}</>;
};

export default SlideshowItem;
