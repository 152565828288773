import { RouteComponentProps } from "@reach/router";
import anime from "animejs";
import cx from "classnames";
import { graphql, useStaticQuery } from "gatsby";
import { MiniSignalBinding } from "mini-signals";
import React, { useEffect, useRef } from "react";
import Announcement from "../blocks/announcement/announcement";
import Clients from "../blocks/clients/clients";
import Intro from "../blocks/intro/intro";
import KeyInfos, { KeyInfo } from "../blocks/keyinfos/keyinfos";
import { LoginModalOpen } from "../blocks/loginModal/loginModal";
import { NewsletterModalOpen } from "../blocks/newsletterModal/newsletterModal";
import TeamVideos from "../blocks/teamvideos/teamvideos";
import Testimonies from "../blocks/testimonies/testimonies";
import VerticalSlider, { VerticalSlide } from "../blocks/verticalSlider";
import SEO from "../components/seo";
import eventDispatcher from "../helpers/eventDispatcher/eventDispatcher";

import { NavigateData } from "../helpers/NavLink";
import { onInitPageLoadTracking, sendGA } from "../helpers/tracking";
import global from "../scss/global.module.scss";

export const IndexPage: React.FC<RouteComponentProps> = (props: RouteComponentProps) => {
  const { location } = props;

  const data = useStaticQuery(IndexQuery);
  const navRef = useRef<MiniSignalBinding>();
  const pageRef = useRef(null);
  const newsRef = useRef(null);
  const introSrc = data.introImg.childImageSharp.fluid;
  const delay = 200;

  useEffect(() => {
    onInitPageLoadTracking();
    enterAnimation();
    eventDispatcher.trigger("_initComponent", { delay });
    navRef.current = eventDispatcher.on("_navigate", exitAnimation);

    newsRef.current = setTimeout(() => {
      eventDispatcher.trigger(NewsletterModalOpen);
    }, 3000);

    return () => {
      if (navRef.current) {
        eventDispatcher.off("_navigate", navRef.current);
      }
      if (newsRef.current) {
        clearTimeout(newsRef.current);
      }
    };
  }, []);

  const exitAnimation = (d: NavigateData) => {
    const tl = anime.timeline({
      complete: () => {
        d.callback();
      },
      duration: 400,
      easing: "easeInOutExpo",
    });

    tl.add({
      opacity: [1, 0],
      targets: pageRef.current,
    });
  };

  const enterAnimation = () => {
    const tl = anime.timeline({
      delay,
      duration: 400,
      easing: "linear",
    });

    tl.add({
      opacity: [0, 1],
      targets: pageRef.current,
    });
  };

  return (
    <>
      <SEO
        meta={[
          {
            property: "og:url",
            content: location.href,
          },
        ]}
      />
      <div style={{ opacity: 0 }} className={global.pageWrapper} ref={pageRef}>
        <Intro
          fluid={introSrc}
          text={introTexte}
          title={introTitle}
          highlight="On s'y met ?"
          cta={
            <span
              className={global.cta}
              onClick={() => {
                eventDispatcher.trigger(LoginModalOpen);
                sendGA("click", {
                  event_label: "ouverture modale - s'inscrire",
                });
              }}
            >
              S'inscrire
            </span>
          }
        />

        <KeyInfos
          cta={
            <span
              className={cx(global.cta, global.ctaPrimary)}
              onClick={() => {
                eventDispatcher.trigger(LoginModalOpen);
                sendGA("click", {
                  event_label: "ouverture modale - et tout ca gratuitement",
                });
              }}
            >
              Rejoindre l'incubateur
            </span>
          }
          infos={keyInfos}
          title={
            <>
              <strong>Sautez le pas</strong> pour trouver le job qui vous épanouit vraiment
            </>
          }
        />
        <Clients />

        <Testimonies />

        <VerticalSlider
          title={
            <>
              <strong>3 étapes</strong> pour trouver le <strong>BON</strong> job :
            </>
          }
          items={jobNeeds}
        />

        <Announcement />

        {/* <TeamVideos /> */}
      </div>
    </>
  );
};

const introTitle = (
  <>
    <span ref={React.createRef()}>Trouvez</span>
    <span ref={React.createRef()}>
      le bon <strong>job</strong> en startup
    </span>
  </>
);
const introTexte = (
  <>
    Le premier moteur d'une entreprise ce sont ses talents. Notre mission : accélérer talents et entreprises dans leur
    développement grâce à un recrutement plus efficace ET plus humain.
  </>
);

const keyInfos: KeyInfo[] = [
  {
    big: "20%",
    title: (
      <>
        des profils
        <br /> sélectionnés.
      </>
    ),
    text: (
      <>
        Nous accompagnons des <strong>profils entrepreneuriaux prêts à passer à l'action !</strong> Chaque talent qui
        intègre l'incubateur a été préalablement sélectionné par nos Talent Managers.
      </>
    ),
  },
  {
    big: "75%",
    title: (
      <>
        de chances
        <br /> d’être recruté.
      </>
    ),
    text: (
      <>
        Vous bénéficiez d'un <strong>accompagnement de A à Z</strong> par notre équipe pour réussir vos entretiens et
        décrocher le job qui vous correspond.
      </>
    ),
  },
  {
    big: "10 000",
    title: (
      <>
        talents <br /> dans l’incubateur
      </>
    ),
    text: (
      <>
        Vous rejoignez une <strong>communauté de tops profils</strong> pour échanger et booster votre carrière.
      </>
    ),
  },
];

const jobNeeds: VerticalSlide[] = [
  {
    title: "Inscription et sélection",
    text: (
      <>
        Postulez en 5 minutes pour rejoindre notre incubateur ! Nos Talent Managers analysent ensuite chaque profil. Si
        votre candidature est retenue, discutez de votre recherche avec l'un d'entre eux avant d'intégrer la communauté.
        Seulement 20% des profils sont sélectionnés !
      </>
    ),
    cta: (
      <span
        className={global.cta}
        onClick={() => {
          eventDispatcher.trigger(LoginModalOpen);
          sendGA("click", {
            event_label: "ouverture modale - s'inscrire - etape 1",
          });
        }}
      >
        S'inscrire
      </span>
    ),
  },
  {
    title: "Ciblage et connexion",
    text: (
      <>
        Oubliez les envois d’offres par dizaines. Nous sélectionnons uniquement les offres de CDI qui vous correspondent
        ! Vous pouvez compter sur nous pour vous donner les informations clés pour passer derrière la porte de chaque
        entreprise et de chaque offre : elles n'auront plus de secret pour vous.
      </>
    ),
    cta: (
      <span
        className={global.cta}
        onClick={() => {
          eventDispatcher.trigger(LoginModalOpen);
          sendGA("click", {
            event_label: "ouverture modale - s'inscrire - etape 2",
          });
        }}
      >
        S'inscrire
      </span>
    ),
  },
  {
    title: "Accélération et recrutement ",
    text: (
      <>
        Vous êtes accompagné de A à Z dans vos process de recrutement : du premier contact à la validation de votre
        période d’essai dans l’entreprise. Notre équipe vous coache pour réussir vos entretiens haut la main !
      </>
    ),
    cta: (
      <span
        className={global.cta}
        onClick={() => {
          eventDispatcher.trigger(LoginModalOpen);
          sendGA("click", {
            event_label: "ouverture modale - s'inscrire - etape 3",
          });
        }}
      >
        S'inscrire
      </span>
    ),
  },
];

export const IndexQuery = graphql`
  query {
    introImg: file(relativePath: { eq: "photo-1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
