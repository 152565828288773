import cx from "classnames";
import React from "react";

import eventDispatcher from "../../helpers/eventDispatcher/eventDispatcher";
import { sendGA } from "../../helpers/tracking";
import global from "../../scss/global.module.scss";
import { LoginModalOpen } from "../loginModal/loginModal";
import styles from "./announcement.module.scss";

const Announcement: React.FC = () => {
  const wordList = () => {
    const arr = [];
    for (let i = 0; i < 10; i++) {
      arr.push(
        <li className={styles.item} key={`word-${i}`}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => {
              eventDispatcher.trigger(LoginModalOpen);
              sendGA("click", {
                event_label: "ouverture modale - rejoignez nous",
              });
            }}
          >
            <text>Rejoignez-nous</text>
          </svg>
        </li>,
      );
    }

    return arr;
  };

  return (
    <div className={cx(styles.announcementWrapper, global.smallSpace)}>
      <ul className={styles.list}>{wordList()}</ul>
    </div>
  );
};

export default Announcement;
