import React, { SyntheticEvent, useState } from "react";
import eventDispatcher from "../../helpers/eventDispatcher/eventDispatcher";
import { sendGA } from "../../helpers/tracking";
import styles from "./index.module.scss";

interface Timeline {
  id: string;
  items: any[];
}

const Timeline: React.FC<Timeline> = (props: Timeline) => {
  const [index, setIndex] = useState(0);

  eventDispatcher.on("indexChange." + props.id, (e) => {
    setIndex(e.index);
  });

  const handleClick = (e: any) => {
    eventDispatcher.trigger("setIndex." + props.id, parseInt(e.target.dataset.index, undefined));
  };

  return (
    <ul className={styles.timeline}>
      {props.items.map((item, i) => (
        <li key={i} className={i === index ? styles.active : ""} data-index={i} onClick={handleClick}>
          <span className={styles.num}>{++i < 10 ? `0${i}` : i}</span>
          <span className={styles.circle}></span>
          <span className={styles.activeCircle}></span>
          <span className={styles.donut}></span>
        </li>
      ))}
    </ul>
  );
};

export default Timeline;
