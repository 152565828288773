import { RouteComponentProps } from "@reach/router";
import anime from "animejs";
import cx from "classnames";
import { MiniSignalBinding } from "mini-signals";
import React, { ReactNode, SyntheticEvent, useEffect, useLayoutEffect, useRef, useState } from "react";

import SEO from "../components/seo";
import eventDispatcher from "../helpers/eventDispatcher/eventDispatcher";
import { NavigateData } from "../helpers/NavLink";
import { LogoSmallOutline } from "../helpers/Svg";
import { onInitPageLoadTracking, sendGA } from "../helpers/tracking";

import global from "../scss/global.module.scss";
import styles from "../scss/pages/offboarding.module.scss";
import { setRef } from "../utils/dom";

interface Formation {
  name: ReactNode;
  text: ReactNode;
}

interface FormationDetail {
  title: ReactNode;
  intro: ReactNode;
  speakingToYou: ReactNode[];
  concretely: ReactNode[];
  concretely2?: ReactNode[];
}

export const Offboarding: React.FC<RouteComponentProps> = (props: RouteComponentProps) => {
  const { location } = props;
  const [isBtnHidden, setIsBtnHidden] = useState<boolean>(true);
  const btnHiddenRef = useRef(true);
  const isRendered = useRef(false);
  const navRef = useRef<MiniSignalBinding>();
  const pageRef = useRef(null);
  const $subscribeContainer = useRef(null);
  const $subscribeContent = useRef(null);
  const delay = 200;
  const headerSiteHeight = 100;
  const $formations = useRef<HTMLDivElement[]>([]);
  const formationsAnchor: string[] = ["formation1", "formation2", "formation3"];
  const formationsPoints: Formation[] = [
    {
      name: <>Retour au boulot, et plus vite que ça.</>,
      text: (
        <>
          “Le temps c’est de l’argent”. Vous savez ce que vous voulez et dans quelle direction vous souhaitez aller. Ce
          dont vous avez besoin, c’est qu’on vous aide à{" "}
          <strong>rebondir vite et bien pour décrocher le nouveau job qui vous correspond</strong>. Alors on s’y met ?
        </>
      ),
    },
    {
      name: <>Prendre son temps oui, mais pas trop.</>,
      text: (
        <>
          Vous fermez un chapitre pour en ouvrir un autre. Pour y arriver sereinement,{" "}
          <strong>vous avez besoin de faire le bilan et de vous poser les bonnes questions</strong> avant de remettre le
          pied à l’étrier pour décrocher le job qu’il vous faut. Ça tombe bien on vous aide sur toute la ligne.
        </>
      ),
    },
    {
      name: <>Doucement mais sûrement.</>,
      text: (
        <>
          Rome ne s’est pas construite en un jour. Et bien c’est la même chose pour votre parcours professionnel.{" "}
          <strong>Vous souhaitez prendre votre temps et être accompagné sur la durée</strong> pour mûrir votre réflexion
          et votre prochain projet&#8239;? C’est juste ici que ça se passe.
        </>
      ),
    },
  ];
  const formationDetails: FormationDetail[] = [
    {
      title: <>#1 - Retour au boulot, et plus vite que ça.</>,
      intro: (
        <>
          Ce que vous cherchez ? Tout simplement <strong>un interlocuteur privilégié</strong> qui{" "}
          <strong>vous connecte rapidement</strong> à de nouvelles opportunités et qui <strong>vous accompagne</strong>{" "}
          lors de vos process pour réussir vos entretiens.
        </>
      ),
      speakingToYou: [
        <>Votre critère #1 à date c’est de décrocher un nouveau job.</>,
        <>Vous savez (à peu près) vers quelle(s) fonction(s) vous souhaitez vous tourner.</>,
      ],
      concretely: [
        <>
          <strong>Vous intégrez directement notre incubateur</strong> sans passer par la case sélection. Vous avez donc
          accès à notre communauté de 8 000 talents et à tous les avantages qui en découlent : événements, rendez-vous
          métier, réseau, ateliers coaching, etc.
        </>,
        <>
          <strong>Vous êtes connecté en priorité sur nos offres pertinentes</strong> pour embrayer vite et bien sur de
          nouveaux process de recrutement.
        </>,
        <>
          <strong>Vous profitez de l’accompagnement de nos experts</strong> sur chaque étape de chaque process avec l’un
          de nos partenaires et ce, jusqu’à la signature de votre contrat : conseils, exercices, feedbacks, challenges,
          etc. On vous donne toutes les clés pour réussir.
        </>,
        <>
          Même après votre embauche, <strong>vous continuez d’avoir accès à notre communauté</strong> (et oui, nous
          rejoindre c’est pour la vie).
        </>,
      ],
    },
    {
      title: <>#2 - Prendre son temps oui, mais pas trop.</>,
      intro: (
        <>
          Vous n’êtes pas prêt à repartir sur les chapeaux de roue pour ré-embrayer sur de nouveaux process de
          recrutement mais votre objectif n’est pas non plus d’attendre 6 mois pour retrouver un job.
        </>
      ),
      speakingToYou: [
        <>
          Vous souhaitez faire le bilan de vos compétences, mais pas que. Faire aussi le point sur vos attentes et sur
          ce qui vous motive pour repartir du bon pied et surtout dans la direction qui vous va bien.
        </>,
        <>Vous avez besoin d’un coup de pouce pour vous familiariser avec les métiers de l’écosystème.</>,
        <>
          Vous souhaitez mettre en lumière les ponts que vous pouvez réaliser entre vos expériences précédentes et
          d’autres opportunités. Oui c’est normal que vous ne trouviez pas ça évident, on est là pour ça.
        </>,
      ],
      concretely: [
        <>
          <strong>Vous intégrez directement notre incubateur</strong> sans passer par la case sélection. Vous avez donc
          accès à notre communauté de 8 000 talents et à tous les avantages qui en découlent : événements, rendez-vous
          métier, réseau, ateliers coaching, etc.
        </>,
        <>
          Vous êtes mis en relation avec <strong>un Talent Manager dédié qui vous suit</strong> tout au long de votre
          expérience.
        </>,
        <>
          Vous bénéficiez d’
          <strong>un coaching approfondi et personnalisé (1H30)</strong> avec votre Talent Manager pour faire le bilan
          de vos compétences et faire les ponts entre vos expériences et les opportunités possibles.
        </>,
        <>
          <strong>Vous êtes connecté sur nos offres pertinentes</strong> pour embrayer vite et bien sur de nouveaux
          process de recrutement.
        </>,
        <>
          <strong>Vous profitez de l’accompagnement de nos experts</strong> sur chaque étape de chaque process avec l’un
          de nos partenaires et ce, jusqu’à la signature de votre contrat : conseils, exercices, feedbacks, challenges,
          etc. On vous donne toutes les clés pour réussir.
        </>,
        <>
          Même après votre embauche, <strong>vous continuez d’avoir accès à notre communauté</strong> (et oui, nous
          rejoindre c’est pour la vie).
        </>,
      ],
    },
    {
      title: <>#3 - Doucement mais sûrement.</>,
      intro: (
        <>
          “Chi va piano va sano”. Vous avez envie de <strong>bien rebondir mais à votre rythme</strong>. Et avant ça
          vous avez surtout besoin de <strong>prendre du temps pour vous</strong>.
        </>
      ),
      speakingToYou: [
        <>
          Vous êtes un peu (voire beaucoup, voire pas du tout) traumatisé par votre ancienne expérience et vous n’êtes
          clairement pas prêt à remettre la tête dans un process tout de suite.
        </>,
        <>
          Vous avez envie de prendre le temps de vous poser et de faire le point sur vos envies, vos valeurs et la
          direction que vous souhaitez donner à votre parcours professionnel. Mais à votre rythme. Et puis entre nous,
          vous ne savez pas par quel bout prendre le sujet.
        </>,
        <>
          Vous savez que vous vous remettrez à chercher un nouveau job bientôt mais clairement pas pour tout de suite -
          maintenant - aujourd’hui - dans l’heure.
        </>,
      ],
      concretely: [
        <>
          <strong>Vous intégrez directement notre incubateur</strong> sans passer par la case sélection. Vous avez donc
          accès à notre communauté de 8 000 talents et à tous les avantages qui en découlent : événements, rendez-vous
          métier, réseau, ateliers coaching, etc.
        </>,
        <>
          Vous êtes mis en relation avec{" "}
          <strong>un Talent Manager dédié qui vous suit tout au long de votre expérience</strong>.
        </>,
        <>
          Vous bénéficiez d’
          <strong>un coaching approfondi et personnalisé (1H30)</strong> avec votre Talent Manager pour faire le bilan
          de vos compétences et faire les ponts entre vos expériences et les opportunités possibles.
        </>,
        <>
          Vous profitez de <strong>5 points de suivi</strong> de 30 min avec votre Talent Manager que vous pouvez
          espacer à votre guise pour suivre la cadence qui vous va le mieux.
        </>,
      ],
      concretely2: [
        <>
          <strong>Vous êtes connecté sur nos offres pertinentes</strong> pour embrayer vite et bien sur de nouveaux
          process de recrutement.
        </>,
        <>
          <strong>Vous profitez de l’accompagnement de nos experts</strong> sur chaque étape de chaque process avec l’un
          de nos partenaires et ce, jusqu’à la signature de votre contrat : conseils, exercices, feedbacks, challenges,
          etc. On vous donne toutes les clés pour réussir.
        </>,
        <>
          Même après votre embauche, <strong>vous continuez d’avoir accès à notre communauté</strong> (et oui, nous
          rejoindre c’est pour la vie).
        </>,
      ],
    },
  ];

  const exitAnimation = (d: NavigateData) => {
    const tl = anime.timeline({
      complete: () => {
        d.callback();
      },
      duration: 600,
      easing: "easeInOutExpo",
    });

    tl.add({
      opacity: [1, 0],
      targets: pageRef.current,
    });
  };
  const enterAnimation = () => {
    const tl = anime.timeline({
      delay,
      duration: 400,
      easing: "linear",
    });

    tl.add({
      opacity: [0, 1],
      targets: pageRef.current,
    });
  };

  const checkSubscriptionMobilePosition = () => {
    if (!isRendered.current) {
      return;
    }
    const wW = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const wH = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    const offset = -30;
    const refTop = $subscribeContainer.current.getBoundingClientRect().top - offset;
    const isInViewPort = refTop < wH;
    if (wW > 800) {
      return;
    }

    if (isInViewPort && !btnHiddenRef.current) {
      btnHiddenRef.current = true;
    } else if (!isInViewPort && btnHiddenRef.current) {
      btnHiddenRef.current = false;
    }
    setIsBtnHidden(btnHiddenRef.current);
    requestAnimationFrame(checkSubscriptionMobilePosition);
  };

  useEffect(() => {
    onInitPageLoadTracking();
    isRendered.current = true;
    enterAnimation();
    eventDispatcher.trigger("_initComponent", { delay });
    navRef.current = eventDispatcher.on("_navigate", exitAnimation);
    checkSubscriptionMobilePosition();

    return () => {
      isRendered.current = false;
      if (navRef.current) {
        eventDispatcher.off("_navigate", navRef.current);
      }
    };
  }, []);

  const scrollToPosition = (top: number, duration = 600) => {
    const scrollElement = window.document.scrollingElement || window.document.body || window.document.documentElement;
    anime({
      targets: scrollElement,
      duration,
      scrollTop: top,
      easing: "easeInOutQuad",
    });
  };

  const onClickMobileScrollBanner = (event: SyntheticEvent) => {
    event.preventDefault();
    const top = $subscribeContainer.current.offsetTop - headerSiteHeight;
    scrollToPosition(top);
  };

  const onClickAnchor = (index: number, label: string, event: SyntheticEvent) => {
    event.preventDefault();
    const top = $formations.current[index].offsetTop - headerSiteHeight;
    const duration = 500 + 200 * index;
    scrollToPosition(top, duration);
    sendGA("click", {
      event_label: label,
    });
  };

  const createKeyPoints = () => {
    const pointsItem = formationsPoints.map((point, i) => {
      const classItem = cx(styles.formationPoint, styles[`point${i + 1}`]);
      const anchor = formationsAnchor[i];
      return (
        <li key={"keypoint_" + i} className={classItem}>
          <a className={styles.pointLink} href={`#${formationsAnchor[i]}`} onClick={(e) => onClickAnchor(i, anchor, e)}>
            <h3 className={styles.pointName}>{point.name}</h3>
            <p className={styles.pointText}>{point.text}</p>
            <div className={styles.pointCtaContainer}>
              <span className={styles.pointCta}>Découvrir le programme</span>
            </div>
          </a>
        </li>
      );
    });
    return pointsItem;
  };

  const createFormationDetails = () => {
    const formations = formationDetails.map((f, i) => {
      const classItem = cx(styles.formationDetail, styles[`detail${i + 1}`]);
      return (
        <div key={"formation_detail_" + i} className={classItem} ref={(el) => setRef(el, $formations)}>
          <h2 className={styles.formationTitle}>{f.title}</h2>
          <p>{f.intro}</p>
          <div className={styles.speakingToYou}>
            <span className={styles.detailSubpartTitle}>Ça vous parle si :</span>
            <ul>
              {f.speakingToYou.map((sty, j) => {
                return <li key={`sty_${j}`}>{sty}</li>;
              })}
            </ul>
          </div>
          <div className={styles.concretely}>
            <span className={styles.detailSubpartTitle}>Concrètement, ce qu’on vous propose&#8239;:</span>
            <ul>
              {f.concretely.map((ctl, j) => {
                return <li key={`ctl_${j}`}>{ctl}</li>;
              })}
            </ul>
            {f.concretely2 && f.concretely2.length ? (
              <>
                <span>Quand vous êtes prêt (dans la limite des 6 mois) :</span>
                <ul>
                  {f.concretely2.map((ctl, j) => {
                    return <li key={`ctl2_${j}`}>{ctl}</li>;
                  })}
                </ul>
              </>
            ) : null}
          </div>
        </div>
      );
    });
    return formations;
  };

  return (
    <>
      <SEO
        meta={[
          {
            property: "og:url",
            content: location.href,
          },
        ]}
        title="elinoï - Trouvez votre job à votre rythme"
        description="Trouvez votre nouveau job à votre rythme. Retrouvez un job ailleurs. Faire un bilan de compétences. Être accompagné sur la durée. Profitez d’un suivi personnalisé."
      />
      <div style={{ opacity: 0 }} className={global.pageWrapper} ref={pageRef}>
        <div className={styles.introWrapper}>
          <div className={styles.outlineLogoContainer}>
            <LogoSmallOutline />
          </div>
          <div className={styles.introContainer}>
            <div className={cx(styles.halfCircleContainer, global.container, global.medium, global.right)}>
              <div className={styles.halfCircle}></div>
            </div>
            <h2 className={styles.title} ref={React.createRef()}>
              <span>
                Trouvez <strong>votre</strong> job
              </span>
              <span>
                à <strong>votre</strong> rythme
              </span>
            </h2>
            <p className={styles.textIntro}>
              À tous ceux qui ont quitté leur entreprise et qui souhaitent rebondir professionnellement, mais à leur
              manière.
            </p>
          </div>
        </div>
        <div className={styles.formationPointsContainer}>
          <h2 className={cx(global.title, styles.formationTitle)}>
            <strong>À chacun son rythme :</strong>
          </h2>
          <div>
            <ul className={styles.formationPointsList}>{createKeyPoints()}</ul>
          </div>
        </div>
        <div className={styles.formationPointsContainer}>
          <div className={styles.formationDetailsSubscriptionContainer}>
            <div className={styles.detailsContainer}>{createFormationDetails()}</div>
            <div className={styles.subscribeContainer} ref={$subscribeContainer}>
              <div className={styles.subscribeContent} ref={$subscribeContent}>
                <h3 className={styles.subscribeTitle}>
                  Parlons peu mais <br />
                  <strong>parlons bien</strong>.
                </h3>
                <p>
                  Ce qu’on vous propose en bref, c’est un accompagnement sur-mesure et personnalisé pour vous aider à
                  rebondir à votre rythme.
                </p>

                <p>
                  <strong>Sur quelle durée ?</strong>
                  <br /> Entre 1 et 6 mois.
                </p>
                <p>
                  <strong>Où ?</strong>
                  <br /> D’où vous le souhaitez, tout est 100% en ligne.
                </p>
                <p>
                  <strong>À quel prix ?</strong> <br />
                  Entre 990 euros HT et 1990 euros HT selon votre parcours et la durée de votre suivi.
                </p>

                <div className={styles.subscribeCtaContainer}>
                  <a
                    className={styles.subscribeCta}
                    href="https://calendly.com/team-elinoi/rebondir-avec-elinoi"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Rebondir avec elinoï
                  </a>
                </div>
                <div className={styles.taglineContainer}>
                  <span className={styles.tagline}>
                    On vous aide aujourd’hui
                    <br /> à préparer demain&#8239;!
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={cx(styles.mobileScrollBannerContainer, { [styles.isHidden]: isBtnHidden }, global.noBottomSpace)}
        >
          <a
            className={styles.mobileScrollBanner}
            href="#redondir-avec-elinoi"
            onClick={(e) => onClickMobileScrollBanner(e)}
          >
            Rebondir avec elinoï
          </a>
        </div>
      </div>
    </>
  );
};
