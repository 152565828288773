import { RouteComponentProps, Redirect } from "@reach/router";
import qs from "query-string";
import React from "react";

export const ReferralRedirect: React.FC<RouteComponentProps<{ token: string }>> = (
  props: RouteComponentProps<{ token: string }>,
) => {
  let toPath = "/";
  const hasReferealToken = /^[a-f0-9]{8}$/.test(props.token);
  if (hasReferealToken) {
    const params = {
      rf: props.token,
      utm_campaign: props.token,
      utm_medium: "ref sn",
      utm_source: "parrainage",
    };
    toPath = `/?${qs.stringify(params)}`;
  }
  return (
    <>
      <Redirect to={toPath} noThrow />
    </>
  );
};
