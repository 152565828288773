import React, { useRef, useState } from "react";
import eventDispatcher from "../eventDispatcher/eventDispatcher";
import { sendGA } from "../tracking";
import baseStyles from "./slideshow.module.scss";
interface Navigation {
  id: string;
  items: any[];
  styles: { [key: string]: string };
}
const Navigation: React.FC<Navigation> = (props: Navigation) => {
  const { id, items, styles } = props;

  // State
  const [index, setIndex] = useState(0);

  const handlePrev = () => {
    eventDispatcher.trigger("prev." + props.id);
    sendGA("scroll", {
      event_label: "temoignages - fleche",
    });
  };

  const handleNext = () => {
    eventDispatcher.trigger("next." + props.id);
    sendGA("scroll", {
      event_label: "temoignages - fleche",
    });
  };

  eventDispatcher.on("indexChange." + props.id, (e) => {
    setIndex(e.index);
  });

  return (
    <div className={`${baseStyles.btnWrapper} ${styles.btnWrapper}`}>
      <span
        className={`${index === 0 ? baseStyles.hidden : ""} ${baseStyles.prevBtn} ${styles.prevBtn || ""}`}
        onClick={handlePrev}
      >
        &#8592;
      </span>
      <span
        className={`${index === items.length - 1 ? baseStyles.hidden : ""} ${baseStyles.nextBtn} ${
          styles.nextBtn || ""
        }`}
        onClick={handleNext}
      >
        &#8594;
      </span>
    </div>
  );
};

export default Navigation;
