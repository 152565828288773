const allText = {
  text1:
    "Attention, peu de talents continuent à ce poste quand ils prennent de l'expérience. Ils montent souvent sur des postes de Sales Senior puis \"Head of\" dans leur domaine ou prennent les commandes d'une partie de l'équipe en tant que Manager. Si c'est le cas, vous pouvez vous référer au haut de la fourchette de salaire indiquée.",
  text2:
    "Chiffres à prendre avec précaution ! Peu de talents se font embaucher sur ce type de poste à ce niveau d'expérience. Dans ces cas rares, la startup perçoit souvent un potentiel chez un talent et il s'agit le plus souvent d'une ouverture d'un nouveau pôle dans une startup en plein développement. ",
  text3:
    "En sortie d'école, les salaires sont un peu plus bas que la moyenne en Sales car cette fonction a une incidence moins directe sur le CA de l'entreprise. La rémunération augmente ensuite avec l'expérience. ",
  text4:
    "Chiffres à prendre avec précaution ! Il s'agit d'un métier récent, nous disposons donc de peu de data à ce niveau d'expérience. ",
  text5:
    "Il existe peu de talents sur le marché à ce poste avec ce niveau d'expérience. En effet, il s'agit d'un métier très spécialiste : nous disposons donc de peu de data à ce niveau d'expérience car les talents s'orientent vers de nouveaux postes. ",
  text6:
    "Salaire assez bas par rapport au reste du marché en raison des candidats en école de communication qui ont des prétentions salariales moindres et exercent ainsi une pression à la baisse des salaires. ",
  text7:
    "Attention, peu de talents obtiennent ce poste en sortie d'école. De manière générale les postes \"Ops\" sont souvent verrouillés en sortie d'école car les startups préfèrent prendre des profils plus expérimentés (consulting, terrain, etc.). ",
  text8:
    "Chiffres à prendre avec précaution ! Les périmètres s'élargissent avec l'expérience et il est donc difficile d'établir une vérité générale sur le salaire. ",
  text9:
    "Chiffres à prendre avec précaution. En effet, nous disposons de peu de data sur ce métier à ce niveau d'expérience. ",
  text10:
    "Attention, peu de talents continuent à ce poste quand ils prennent de l'expérience. La plupart évoluent sur d'autres fonctions RHs ou prennent des postes de Manager. ",
  text11:
    "La moyenne des salaires est plus basse car il s'agit d'un poste ouvert à des talents issus de cursus très différents et souvent moins chers que les écoles de commerce. On observe parfois des salaires bien au-dessus. C'est aussi un rôle assez rare en start-up.",
};

export const hotDemande = [
  "Sales Development Representative",
  "Account Executive",
  "Key Account Manager",
  "Business Developer (generalist)",
  "Account Manager",
  "Customer Success Manager",
  "Customer Service",
];

export const objText = {
  "Sales Development Representative": {
    "6 - 10 ans": allText.text1,
    "11 - 15 ans": allText.text1,
    "15+ ans": allText.text1,
  },
  "Account Executive": {
    "11 - 15 ans": allText.text1,
    "15+ ans": allText.text1,
  },
  "Key Account Manager": {
    "0 - 2 ans": allText.text2,
  },
  "Business Developer (generalist)": {
    "11 - 15 ans": allText.text1,
    "15+ ans": allText.text1,
  },
  "Account Manager": {
    "11 - 15 ans": allText.text1,
    "15+ ans": allText.text1,
  },
  "Customer Success Manager": {
    "0 - 2 ans": allText.text3,
    "11 - 15 ans": allText.text1,
    "15+ ans": allText.text1,
  },
  "Customer Service": {
    "0 - 2 ans": allText.text3,
    "6 - 10 ans": allText.text1,
    "11 - 15 ans": allText.text1,
    "15+ ans": allText.text1,
  },
  "Growth & Acquisition Manager": {
    "6 - 10 ans": allText.text4,
    "11 - 15 ans": allText.text4,
    "15+ ans": allText.text4,
  },
  "CRM Manager": {
    "6 - 10 ans": allText.text5,
    "11 - 15 ans": allText.text5,
    "15+ ans": allText.text5,
  },
  "Communication / PR": {
    "0 - 2 ans": allText.text6,
  },
  "Operations Manager": {
    "0 - 2 ans": allText.text7,
  },
  Operations: {
    "6 - 10 ans": allText.text8,
    "11 - 15 ans": allText.text8,
    "15+ ans": allText.text8,
  },
  "Data Scientist": {
    "11 - 15 ans": allText.text9,
    "15+ ans": allText.text9,
  },
  "Business Intelligence": {
    "11 - 15 ans": allText.text9,
    "15+ ans": allText.text9,
  },
  "Data Analyst": {
    "11 - 15 ans": allText.text9,
    "15+ ans": allText.text9,
  },
  "UX/UI Designer": {
    "all-text":
      "Les salaires d'UX et UI sont assez bas. Généralement, les startups sous 30 personnes, trouvent souvent un développeur front-end ou un Content Manager débrouillard pour accomplir la tâche. Ils embauchent un UI et UX une fois l'entreprise plus développée et les rôles spécialisés. Le peu de demande crée mécaniquement une baisse des salaires.",
  },
  "Office Manager": {
    "all-text":
      "La moyenne des salaires est plus basse car il s'agit d'un poste ouvert à des talents issus de cursus très différents et souvent moins chers que les écoles de commerce. On observe parfois des salaires bien au-dessus. ",
  },
  RAF: {
    "6 - 10 ans": allText.text9,
    "11 - 15 ans": allText.text9,
  },
  "Finance & Accounting": {
    "6 - 10 ans": allText.text1,
    "11 - 15 ans": allText.text1,
    "15+ ans": allText.text1,
  },
  "Talent Acquisition Manager": {
    "6 - 10 ans": allText.text10,
    "11 - 15 ans": allText.text10,
    "15+ ans": allText.text10,
  },
  "RH (generalist)": {
    "0 - 2 ans": allText.text11,
    "3 - 5 ans": allText.text11,
  },
  "Chief Technical Officer": {
    "0 - 2 ans": allText.text2,
    "3 - 5 ans": allText.text2,
  },
  "Chief Marketing Officer": {
    "0 - 2 ans": allText.text2,
    "3 - 5 ans": allText.text2,
  },
  "Head of Sales": {
    "0 - 2 ans": allText.text2,
    "3 - 5 ans": allText.text2,
  },
  "Chief Operating Officer": {
    "0 - 2 ans": allText.text2,
    "3 - 5 ans": allText.text2,
  },
  "City/Country Launcher": {
    "0 - 2 ans":
      "Chiffres à prendre avec précaution ! Peu de talents se font embaucher sur ce type de poste à ce niveau d'expérience. Dans ces cas rares, il s'agit le plus souvent de postes tournés Sales dans un premier temps. ",
  },
};
