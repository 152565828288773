import { graphql, useStaticQuery } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import Helmet from "react-helmet";
const { config } = require("./../config");

function SEO({
  description,
  lang,
  meta,
  title,
}: {
  description: string;
  lang?: string;
  meta: { property: string; content: string }[];
  title: string;
}) {
  const metaDescription = description || config.siteDescription;
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title || config.siteTitle}
      meta={[
        {
          name: "description",
          content: metaDescription,
        },
        {
          name: "twitter:description",
          content: metaDescription,
        },
        {
          name: "twitter:card",
          content: "summary",
        },
        {
          name: "twitter:creator",
          content: config.siteAuthor,
        },
        {
          name: "twitter:title",
          content: title || config.siteTitle,
        },
        {
          property: "og:description",
          content: metaDescription,
        },
        {
          property: "og:image",
          content: "https://elinoi-uploads.s3.eu-west-3.amazonaws.com/social.header.png",
        },
        {
          property: "og:image:secure_url",
          content: "https://elinoi-uploads.s3.eu-west-3.amazonaws.com/social.header.png",
        },
        {
          property: "og:image:height",
          content: "648",
        },
        {
          property: "og:image:width",
          content: "754",
        },
        {
          property: "og:title",
          content: title || config.siteTitle,
        },
        {
          property: "og:type",
          content: "website",
        },
      ].concat(meta)}
    />
  );
}

SEO.defaultProps = {
  description: config.siteDescription,
  lang: "fr",
  meta: [],
  title: config.siteTitle,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default SEO;
