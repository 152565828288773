import anime from "animejs";
import cx from "classnames";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import { MiniSignalBinding } from "mini-signals";
import React, { ReactNode, useEffect, useRef, useState } from "react";
import { MdReplay } from "react-icons/md";

import { chunkArray } from "../../helpers/chunkArray";
import eventDispatcher from "../../helpers/eventDispatcher/eventDispatcher";
import { NavLink } from "../../helpers/NavLink";
import { sendGA } from "../../helpers/tracking";
import global from "../../scss/global.module.scss";
import { setRef } from "../../utils/dom";
import styles from "./team.module.scss";

export interface Member {
  img: any;
  name: ReactNode;
  job: ReactNode;
  link: string;
  linkLabel?: ReactNode;
  description: ReactNode;
}

interface Team {
  text?: ReactNode;
  title?: ReactNode;
  linkLabel?: ReactNode;
}

export const TeamQuery = graphql`
  query {
    bertille: file(relativePath: { eq: "team/bertille.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    chloe: file(relativePath: { eq: "team/chloe.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    clemence: file(relativePath: { eq: "team/clemence.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    cloe: file(relativePath: { eq: "team/cloe.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    david: file(relativePath: { eq: "team/david.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    elise: file(relativePath: { eq: "team/elise.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    enzo: file(relativePath: { eq: "team/enzo.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    gaelle: file(relativePath: { eq: "team/gaelle.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    heloise: file(relativePath: { eq: "team/heloise.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    jeanne: file(relativePath: { eq: "team/jeanne.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    magali: file(relativePath: { eq: "team/magali.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    margaux: file(relativePath: { eq: "team/margaux.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    marine: file(relativePath: { eq: "team/marine.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    marineB: file(relativePath: { eq: "team/marineB.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    marineL: file(relativePath: { eq: "team/marineL.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    mathilde: file(relativePath: { eq: "team/mathilde.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    meryl: file(relativePath: { eq: "team/meryl.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    remy: file(relativePath: { eq: "team/remy.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    theo: file(relativePath: { eq: "team/theo.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    thibaut: file(relativePath: { eq: "team/thibaut.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

const Team: React.FC<Team> = (props: Team) => {
  const data = useStaticQuery(TeamQuery);
  const {
    text = "Chaque jour, nos équipes vous accompagnent dans le développement de votre carrière et de votre business. Chez nous vous ne trouverez aucun RH mais des experts passionnés par l’écosystème startups qui ont à coeur de vous aider à grandir.",
    title = (
      <>
        Notre <strong>équipe</strong>
      </>
    ),
    linkLabel = "Voir son profil LinkedIn",
  } = props;

  const members: Member[] = [
    //////// PAGE 1 - START ////////
    {
      img: data.david.childImageSharp.fluid,
      name: "David",
      job: "CEO & fondateur",
      description: <>« A fondé elinoï pour repenser le recrutement actuel »</>,
      link: "https://www.linkedin.com/in/davidsimeoni",
    },
    {
      img: data.heloise.childImageSharp.fluid,
      name: "Héloïse",
      job: "Business Developer Manager",
      description: <>« Déniche et développe de nouveaux partenariats »</>,
      link: "https://www.linkedin.com/in/h%C3%A9lo%C3%AFse-girard-11340483",
    },
    {
      img: data.magali.childImageSharp.fluid,
      name: "Magali",
      job: "Marketing & Growth Manager",
      description: <>{"« Définit l'identité de la marque elinoï et la fait rayonner »"}</>,
      link: "https://www.linkedin.com/in/magali-andr%C3%A9-695512109/",
    },
    {
      img: data.jeanne.childImageSharp.fluid,
      name: "Jeanne",
      job: "Experience Manager",
      description: <>« Améliore sans relâche l’expérience elinoï »</>,
      link: "https://www.linkedin.com/in/jeanne-vandecastele-30128090",
    },
    {
      img: data.theo.childImageSharp.fluid,
      name: "Théo",
      job: "Développeur Fullstack",
      description: <>« Transforme les lignes de code en nouvelles fonctionnalités d’elinoï »</>,
      link: "https://www.linkedin.com/in/theoattali",
    },
    {
      img: data.meryl.childImageSharp.fluid,
      name: "Méryl",
      job: "Project Manager - Stratégie BtoB",
      description: <>« Développe et structure nos équipes &amp; nos offres »</>,
      link: "https://www.linkedin.com/in/m%C3%A9ryl-gindre-a4068547/",
    },
    //////// PAGE 1 - END ////////
    //////// PAGE 2 - START ////////
    {
      img: data.chloe.childImageSharp.fluid,
      name: "Chloé",
      job: "Business Intelligence Manager",
      description: <>« Transforme les données brutes en solutions business »</>,
      link: "https://www.linkedin.com/in/chloe-voury-62086b14b",
    },
    {
      img: data.elise.childImageSharp.fluid,
      name: "Élise",
      job: "Business Developer",
      description: <>« Déniche et développe de nouveaux partenariats »</>,
      link: "https://www.linkedin.com/in/elise-renauld-9a588897/",
    },
    {
      img: data.mathilde.childImageSharp.fluid,
      name: "Mathilde",
      job: "Talent Incubator Manager",
      description: <>{"« Accompagne et coache les talents de l'incubateur »"}</>,
      link: "https://www.linkedin.com/in/mathilde-anceau-457b5a85",
    },
    {
      img: data.marineL.childImageSharp.fluid,
      name: "Marine",
      job: "Talent Incubator Manager",
      description: <>{"« Accompagne et coache les talents de l'incubateur »"}</>,
      link: "https://www.linkedin.com/in/marine-lambert-6a9664122/",
    },
    {
      img: data.enzo.childImageSharp.fluid,
      name: "Enzo",
      job: "Développeur Front-End junior",
      description: <>« Transforme les lignes de code en nouvelles fonctionnalités d’elinoï »</>,
      link: "https://www.linkedin.com/in/enzo-z-756967136/",
    },
    {
      img: data.clemence.childImageSharp.fluid,
      name: "Clémence",
      job: "Account Manager",
      description: <>« Connecte nos talents aux meilleures startups »</>,
      link: "https://www.linkedin.com/in/cl%C3%A9mence-r%C3%A9rolle/",
    },
    //////// PAGE 2 - END ////////
    //////// PAGE 3 - START ////////
    {
      img: data.thibaut.childImageSharp.fluid,
      name: "Thibaut",
      job: "Business Intelligence Junior",
      description: <>« Transforme les données brutes en solutions business »</>,
      link: "https://www.linkedin.com/in/thibaut-morel/",
    },
    {
      img: data.bertille.childImageSharp.fluid,
      name: "Bertille",
      job: "Account Manager",
      description: <>« Connecte nos talents aux meilleures startups »</>,
      link: "https://www.linkedin.com/in/bertille-sallard-b30913b4/",
    },
    {
      img: data.cloe.childImageSharp.fluid,
      name: "Cloé",
      job: "Talent Incubator Manager",
      description: <>{"« Accompagne et coache les talents de l'incubateur »"}</>,
      link: "https://www.linkedin.com/in/clo%C3%A9-bourel-59798bb0/",
    },
    {
      img: data.remy.childImageSharp.fluid,
      name: "Rémy",
      job: "Développeur Front-End",
      description: <>« Transforme les lignes de code en nouvelles fonctionnalités d’elinoï »</>,
      link: "https://www.linkedin.com/in/remydragyn/",
    },
    {
      img: data.margaux.childImageSharp.fluid,
      name: "Margaux",
      job: "Account Manager",
      description: <>« Connecte nos talents aux meilleures startups »</>,
      link: "https://www.linkedin.com/in/margaux-ehrmann/",
    },
    {
      img: data.marineB.childImageSharp.fluid,
      name: "Marine B.",
      job: "Bras droit Marketing & Growth",
      description: <>« Définit l'identité de la marque elinoï et la fait rayonner »</>,
      link: "https://www.linkedin.com/in/marinebarbarre/",
    },
    //////// PAGE 3 - END ////////
  ];

  const resizeRef = useRef<MiniSignalBinding>();
  const [mobile, setMobile] = useState<boolean>(typeof window !== "undefined" ? window.innerWidth <= 750 : false);

  const $overlays = useRef<HTMLElement[]>([]);
  const [page, setPage] = useState<number>(0);
  const perPage = mobile ? 4 : 6;
  let currPage = useRef<number>(0).current;
  const membersPages = chunkArray(members, perPage, true);
  const maxPage = useRef<number>(membersPages.length - 1).current;
  const isAnimating = useRef<boolean>(false);
  const onClickSwitchCta = () => {
    if (isAnimating.current) {
      return;
    }
    isAnimating.current = true;
    currPage = page + 1 > maxPage ? 0 : page + 1;
    swtichPage(currPage);
    sendGA("refresh", {
      event_label: "equipe - refresh",
    });
  };
  const swtichPage = (pageIndex: number) => {
    const tl = anime.timeline({
      duration: 600,
      easing: "cubicBezier(0.74, 0.04, 0.34, 0.95)",
      complete: () => {
        $overlays.current = [];
        setPage(pageIndex);
      },
    });

    tl.add({
      translateY: ["-101%", 0],
      targets: $overlays.current,
      delay: anime.stagger(80),
    });
  };

  const showTeamItem = () => {
    const tl = anime.timeline({
      duration: 600,
      easing: "cubicBezier(0.74, 0.04, 0.34, 0.95)",
      complete: () => {
        isAnimating.current = false;
      },
    });

    tl.add({
      translateY: [0, "101%"],
      targets: $overlays.current,
      delay: anime.stagger(80),
    });
  };

  useEffect(() => {
    resizeRef.current = eventDispatcher.on("_resize", resize);

    return () => {
      if (resizeRef.current) {
        eventDispatcher.off("_resize", resizeRef.current);
      }
    };
  }, []);

  useEffect(() => {
    showTeamItem();
  }, [page]);

  const resize = ({ wW }: { wW: number }) => {
    setMobile(wW <= 750);
  };

  return (
    <>
      <div className={global.container}>
        <div className={cx(global.container, global.medium, global.right)}>
          <h2 className={global.title}>{title}</h2>
          <p className={styles.text}>{text}</p>
        </div>
      </div>
      <div className={styles.teamMembersWrapper}>
        <div className={cx(styles.teamMembersContainer, global.container)}>
          <ul className={styles.list}>
            {membersPages[page].map((m, i: number) => {
              const { description, img, job, link, name, type } = m;

              if (type && m.type === "placeholder") {
                return (
                  <li key={`member-${i}`} className={styles.item}>
                    <div className={styles.memberContent}>
                      <a
                        href="https://www.welcometothejungle.com/fr/companies/elinoi"
                        className={styles.placeholderMemberLink}
                        target="_blank"
                        rel="noreferrer noopener"
                        onClick={() => {
                          sendGA("click", {
                            event_label: "equipe - Rejoignez-nous - placeholder",
                          });
                        }}
                      >
                        <span className={styles.imgOverlay} ref={(el) => setRef(el, $overlays)}></span>
                        <div className={styles.teamJoinsUsContainer}>
                          <span className={styles.teamJoinsUs}>Rejoignez-nous !</span>
                        </div>
                      </a>
                    </div>
                  </li>
                );
              }

              return (
                <li key={`member-${i}`} className={styles.item}>
                  <div className={styles.memberContent}>
                    <span className={styles.imgOverlay} ref={(el) => setRef(el, $overlays)}></span>
                    <div className={styles.imgContainer}>
                      <Img fluid={img} />
                    </div>
                    <div className={styles.infosContainer}>
                      <h4 className={styles.name}>{name}</h4>
                      <span className={styles.job}>{job}</span>
                      <NavLink
                        href={link}
                        className={styles.link}
                        onClick={() => {
                          sendGA("click", {
                            event_label: `equipe - linkedin - ${name}`,
                          });
                        }}
                      >
                        {m.linkLabel ? m.linkLabel : linkLabel}
                      </NavLink>
                      <p className={styles.description}>{description}</p>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
          <div className={styles.switchContainer}>
            <span className={styles.switchCta} onClick={onClickSwitchCta}>
              <MdReplay />
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Team;
