import anime from "animejs";
import cx from "classnames";
import { MiniSignalBinding } from "mini-signals";
import React, { ReactNode, useEffect, useRef, ReactElement } from "react";

import { AnimatedElement } from "../../helpers/animatedElement";
import eventDispatcher from "../../helpers/eventDispatcher/eventDispatcher";
import { LogoSmallOutline } from "../../helpers/Svg";

import global from "../../scss/global.module.scss";
import styles from "./intro.module.scss";

interface IntroProps {
  cta?: ReactNode;
  title: ReactElement;
  text: ReactNode;
  fluid?: any;
  highlight?: string;
}

const Intro: React.FC<IntroProps> = (props: IntroProps) => {
  const initRef = useRef<MiniSignalBinding>();
  const { cta = null, title, text, highlight } = props;
  const titleRefs = useRef(null);
  const refTitle = useRef(null);
  const refHalf = useRef(null);
  const refText = useRef(null);
  const refCatImg = useRef(null);
  const refOutline = useRef(null);

  useEffect(() => {
    initRef.current = eventDispatcher.on("_initComponent", init);
    titleRefs.current = title && title.props.children.map((c: any) => c.ref.current);

    return () => {
      if (initRef.current) {
        eventDispatcher.off("_initComponent", initRef.current);
      }
    };
  }, []);

  const init = ({ delay }: { delay: number }) => {
    enterAnimation(delay);
  };

  const enterAnimation = (delay = 0) => {
    const tl = anime.timeline({
      delay,
      duration: 1200,
      easing: "cubicBezier(0.74, 0.04, 0.34, 0.95)",
    });

    tl.add({
      translateY: (_el: HTMLElement, i: number) => {
        const y = 24 * (i + 1 * 1.2);
        return [y, 0];
      },
      targets: titleRefs.current,
      delay: anime.stagger(40),
    })
      .add(
        {
          translateY: [60, 0],
          opacity: [0, 1],
          duration: 1000,
          targets: refTitle.current,
        },
        0,
      )
      .add(
        {
          rotate: [100, 0],
          opacity: [0, 1],
          duration: 800,
          targets: refHalf.current,
        },
        300,
      )
      .add(
        {
          translateY: [100, 0],
          opacity: [0, 1],
          duration: 800,
          targets: refText.current,
        },
        200,
      )
      .add(
        {
          translateY: [140, 0],
          opacity: [0, 1],
          duration: 800,
          targets: refCatImg.current,
        },
        200,
      )
      .add(
        {
          opacity: [0, 0.6],
          duration: 400,
          targets: refOutline.current,
          easing: "linear",
        },
        "-=400",
      );
  };

  return (
    <div className={styles.introWrapper}>
      <div className={styles.outlineContainer} ref={refOutline}>
        <LogoSmallOutline />
      </div>
      <AnimatedElement translateY={{ speed: 20 }}>
        <div className={cx(styles.introContainer, global.container)} ref={React.createRef()}>
          <div className={cx(styles.halfCircleContainer, global.container, global.medium, global.right)}>
            <AnimatedElement translateY={{ speed: 12 }} rotateZ={{ speed: -12 }}>
              <div className={styles.halfCircle} ref={refHalf}></div>
            </AnimatedElement>
          </div>
          <div className={cx(global.container, global.small, global.right)} ref={refTitle}>
            <AnimatedElement translateY={{ speed: 4 }}>
              <h2 className={styles.title} ref={React.createRef()}>
                {title}
              </h2>
            </AnimatedElement>
          </div>
          <div className={cx(styles.textContainer, global.container, global.medium, global.right)}>
            <div className={styles.text} ref={refText}>
              {text}
            </div>
          </div>

          <div className={cx(styles.ctaImgContainer, global.container, global.medium, global.right)} ref={refCatImg}>
            <div className={styles.highlightCtaContainer}>
              {highlight ? <span className={cx(styles.highlightSentence, global.uppercase)}>On s'y met ?</span> : null}
              <div className={styles.ctaContainer}>{cta ? cta : null}</div>
            </div>
            <div className={styles.imgContainer}>{/* <Img fluid={fluid} /> */}</div>
          </div>
        </div>
      </AnimatedElement>
    </div>
  );
};

export default Intro;
