import cx from "classnames";
import { MiniSignalBinding } from "mini-signals";
import React, { ReactNode, useRef } from "react";

import { AnimatedElement } from "../../helpers/animatedElement";

import global from "../../scss/global.module.scss";
import styles from "./keyinfos.module.scss";

export interface KeyInfo {
  big: string;
  bigColor?: string;
  title: ReactNode;
  text: ReactNode;
}

interface KeyInfos {
  cta?: ReactNode;
  title: ReactNode;
  infos: KeyInfo[];
}

const KeyInfos: React.FC<KeyInfos> = (props: KeyInfos) => {
  const { infos, cta = null, title } = props;

  const flexBasis = "25%";
  // const flexBasis = `${100 / (infos.length + 1)}%`;
  const containerClassName = infos.length >= 3 ? "small" : "medium";

  return (
    <div className={cx(styles.keyinfosContainer, global.container)}>
      <div className={styles.smallLogoContainer} ref={React.createRef()}>
        <AnimatedElement translateY={{ speed: 0 }} rotateZ={{ speed: 10 }}>
          <div className={styles.halfCircle} ref={React.createRef()}></div>
        </AnimatedElement>
        <AnimatedElement translateY={{ speed: 12 }}>
          <div className={styles.donut} ref={React.createRef()}></div>
        </AnimatedElement>
        <AnimatedElement translateY={{ speed: 6 }}>
          <div className={styles.circle} ref={React.createRef()}></div>
        </AnimatedElement>
      </div>
      <div className={cx(styles.titleContainer, global.container, global.medium, global.right)}>
        <h2 className={cx(styles.title, global.title)}>{title}</h2>
      </div>
      <ul className={styles.list}>
        {infos.map((info, i) => {
          const color = info.bigColor || undefined;
          return (
            <li key={`keyinfo-${i}`} style={{ flexBasis }}>
              <h3 className={styles.itemTitle}>
                <span className={styles.nb} style={{ color }}>
                  {info.big}
                </span>{" "}
                {info.title}
              </h3>
              <div className={styles.text}>{info.text}</div>
            </li>
          );
        })}
      </ul>
      <div className={cx(styles.ctaContainer, global.container, global[containerClassName], global.right)}>{cta}</div>
    </div>
  );
};

export default KeyInfos;
