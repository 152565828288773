import { IReview } from "../../utils/interfaces";

export const reviewsData: IReview[] = [
  {
    name: "Enzo",
    responded: "janvier 2021",
    comment:
      "elinoï impulse une nouvelle façon d’interagir et de trouver un emploi réellement adapté. Je trouve que c’est super bien rodé. Merci à l’équipe.",
  },
  {
    name: "Emma",
    responded: "janvier 2021",
    comment:
      "Le Talent Manager est à l'écoute du candidat, propose des offres intéressantes et relance. J'ai trouvé mon 1er contact humain et enrichissant !",
  },
  {
    name: "Archibald",
    responded: "décembre 2020",
    comment: "Accompagnement de qualité, outil très fonctionnel et équipes très sympathiques !",
  },
  {
    name: "Elie",
    responded: "novembre 2020",
    comment:
      "Super écoute, bonne compréhension de mes envies et de mes points forts/points faibles. Discussion très agréable avec un bon feedback à la fin.",
  },
  {
    name: "Julie",
    responded: "novembre 2020",
    comment:
      "La Talent manager est très à l'écoute, comprend nos besoins pour s'en rapprocher au maximum, et propose un très bon suivi.",
  },
  {
    name: "Louise",
    responded: "novembre 2020",
    comment:
      "Je me suis inscrite il y a pas longtemps et pour moi le service est top ! Peut-être qu’il pourrait être intéressant de recevoir des notifications par sms ou mail lorsque ses éléments évoluent sur notre espace profil mais sinon parfait 😉",
  },
  {
    name: "Ingrid",
    responded: "octobre 2020",
    comment:
      "J'ai vraiment apprécié, le fait qu'on étudie mon profil et que l'on me donne des conseils avec bienveillance. Avoir un échange téléphonique avec une personne nous permet de se sentir valorisé même si nous ne sommes pas retenus pour une candidature.",
  },
  {
    name: "Elodie",
    responded: "octobre 2020",
    comment:
      "Je trouve que le positionnement d'elinoï sur le recrutement est tout à fait en adéquation avec les problématiques des talents et des entreprises aujourd'hui ! prendre en compte aussi les valeurs de chacun permet un vrai potentiel matching.",
  },
  {
    name: "Nicolas",
    responded: "octobre 2020",
    comment: "Restez comme vous êtes :)",
  },
  {
    name: "Romain",
    responded: "septembre 2020",
    comment:
      "Très bonne gestion de l'onboarding jusqu'au suivi des candidats. Les différents contacts que l'on peut avoir avec les équipes d'elinoï sont toujours des personnes que l'on sent investi(e)s et soucieux(ses) de bien faire.",
  },
  {
    name: "Jérémie",
    responded: "août 2020",
    comment: "Les informations sur les opportunités sont hyper complètes !",
  },
  {
    name: "Amélie",
    responded: "juillet 2020",
    comment:
      "Je trouve la plateforme très bien faite que ce soit en terme d'ergonomie ou même la présentation et le descriptif des offres. Et toute l'équipe est très gentille et professionnelle et de bons conseils.",
  },
  {
    name: "Raphaël",
    responded: "mai 2020",
    comment:
      "En plus d'avoir eu affaire à des personnes extrêmement sympathiques et souriantes lors des 2 appels téléphoniques avec vos talents manager (ce n'est pas un mythe que de dire que le sourire s'entend au téléphone.) J'ai été extrêmement bien conseillé et j'ai eu la réponse à toutes mes questions par rapport aux offres. Un gros pouce bleu à votre équipe !",
  },
  {
    name: "Anne-Sophie",
    responded: "juin 2020",
    comment: "J'ai eu un super échange avec Marine ! Très humaine et très à l'écoute. Bravo à elle !",
  },
  {
    name: "Audrey",
    responded: "mai 2020",
    comment: "Vous êtes parfaits ❤",
  },
  {
    name: "Tom",
    responded: "avril 2020",
    comment: "Tout est au top jusqu'à maintenant, bravo !",
  },
  {
    name: "Dan",
    responded: "mars 2020",
    comment: "Ma candidature n'a pas été retenue, mais j'ai reçu des conseils de prospection précieux.",
  },
  {
    name: "Wilfried",
    responded: "mars 2020",
    comment: "Beaucoup de transparence. Equipe dispo, pro et sympa.",
  },
  {
    name: "Matthieu",
    responded: "mars 2020",
    comment:
      "J'apprécie beaucoup la plateforme et lors des entretiens ont obtient plus d'information sur le poste. Tout ça grâce au contact humain que l'on a avec elinoï qui nous aide et nous montre nos axes d'amélioration.",
  },
  {
    name: "Cindy",
    responded: "février 2020",
    comment:
      "Un grand merci à Cloé pour le temps accordé qui a pris la peine de me parler en détail du monde start-up, a été à l'écoute de mon profil et qui finalement m'a fait réaliser que je me trompais peut-être de direction dans ma recherche d'emploi. Conversation enrichissante tout en étant sympathique. Merci !",
  },
  {
    name: "Arthur",
    responded: "février 2020",
    comment:
      "La plateforme est très user friendly et très bien organisée. J'ai aussi apprécié le contact humain et régulier avec les différents interlocuteurs de l'équipe elinoï.",
  },
];
