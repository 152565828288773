import anime from "animejs";
import cx from "classnames";
import { graphql, useStaticQuery } from "gatsby";
import Img, { FluidObject } from "gatsby-image";
import React, { ReactNode, useEffect, useRef } from "react";

import eventDispatcher from "../../helpers/eventDispatcher/eventDispatcher";
import Navigation from "../../helpers/slideshow/navigation";
import Slideshow from "../../helpers/slideshow/slideshow";
import SlideshowItem from "../../helpers/slideshow/slideshowItem";
import { sendGA } from "../../helpers/tracking";

import useWindowResize from "../../hook/useWindowResize.js";
import global from "../../scss/global.module.scss";
import { $, setRef } from "../../utils/dom";
import uniqid from "../../utils/uniqid";

import styles from "./testimonies.module.scss";

interface Options {
  title?: ReactNode;
  linkLabel?: ReactNode;
  link?: string;
  alternativeOverlay?: boolean;
  items?: Item[];
}

interface Item {
  text: ReactNode;
  name: string;
  post: string;
  company: string;
  img: FluidObject;
}

const Testimonies: React.FC<Options> = (props: Options) => {
  const {
    title = (
      <>
        Rejoindre notre incubateur c'est les <strong>rejoindre eux</strong>
      </>
    ),
    linkLabel = "Découvrir",
    alternativeOverlay = false,
    items,
  } = props;
  const data = useStaticQuery(TestimoniesQuery);

  const imgWidth: number[] = [];
  const id = uniqid();
  const $wrapper = useRef([]);
  const $imgContainer = useRef([]);

  const $infosSpan: HTMLCollectionOf<HTMLElement>[] = [];
  const $imgWrapper: HTMLCollectionOf<HTMLElement>[] = [];
  const $img: HTMLCollectionOf<HTMLElement>[] = [];

  const DEFAULT_ITEMS: Item[] = [
    {
      text: (
        <>
          elinoï a cette capacité à dénicher des opportunités incroyables avant qu'elle soient connues du grand public.
          Les équipes ont su faciliter mon recrutement qui n'a pris que 2 semaines au total.
        </>
      ),
      img: data.pierreImg.childImageSharp.fluid,
      post: "Directeur commercial",
      company: "Deliveroo",
      name: "Pierre",
    },
    {
      text: (
        <>
          elinoï m'a ouvert tout un tas de nouvelles perspectives à un moment où j'étais perdue dans mes recherches de
          CDI. J'ai énormément apprécié la qualité des conseils prodigués et toutes les offres de postes que j'ai reçues
          m’ont aidée à identifier le poste le plus en accord avec mes attentes.
        </>
      ),
      img: data.lucileImg.childImageSharp.fluid,
      post: "Strategic Planner",
      company: "Seelk",
      name: "Lucile",
    },
    {
      text: (
        <>
          Avant et pendant mes entretiens avec Yespark, elinoï m'a donné beaucoup d'infos qui m’ont aidée à me forger
          une bonne idée de leur culture et de ce qu'ils pouvaient attendre de moi. Connaître ces infos allège
          énormément le stress des entretiens.
        </>
      ),
      img: data.alixImg.childImageSharp.fluid,
      post: "Customer Care Manager",
      company: "Yespark",
      name: "Alix",
    },
    {
      text: (
        <>
          Ce que m’a apporté elinoï, c’est un vrai gain de temps. Je n’en n’avais pas beaucoup, je n’étais pas en
          recherche active et je n’ai pas eu grand chose à faire : pas de CV, ni lettre de motivation. Ça m’a vraiment
          libéré l’esprit.
        </>
      ),
      img: data.fabienImg.childImageSharp.fluid,
      post: "Business Developer",
      company: "Coorpacademy",
      name: "Fabien",
    },
  ];

  const testimoniesItems: Item[] = items || DEFAULT_ITEMS;

  useEffect(() => {
    // Selectors
    for (let i = testimoniesItems.length - 1; i >= 0; i--) {
      $infosSpan[i] = $($wrapper.current[i], styles.infos + " span");
      $imgWrapper[i] = $($imgContainer.current[i], styles.img);
      $img[i] = $($imgContainer.current[i], styles.imgTranslate);
    }

    resize();

    // Animations
    eventDispatcher.on("animate." + id, (itemsProps) => {
      for (let i = itemsProps.length - 1; i >= 0; i--) {
        const { rIn } = itemsProps[i];
        const r = 1 - rIn;

        $imgWrapper[i][0].style.transform = `translate3d(${(imgWidth[i] / 2) * r}px, 0, 0)`;
        $img[i][0].style.transform = `translate3d(${(-imgWidth[i] / 2) * r}px, 0, 0)`;

        if (rIn >= -1 && rIn <= 2) {
          $infosSpan[i][0].style.transform = `translate3d(${50 * r}px, 0, 0)`;
          $infosSpan[i][1].style.transform = `translate3d(${100 * r}px, 0, 0)`;
          $infosSpan[i][2].style.transform = `translate3d(${200 * r}px, 0, 0)`;
        }
      }
    });
  }, [id, items]);

  const handleChangeIndex = (e: any) => {
    eventDispatcher.trigger("setIndex." + id, parseInt(e.target.dataset.index, undefined));
    sendGA("click", {
      event_label: "temoignages - photo",
    });
  };

  const handleMouseEnter = (e: any) => {
    const el = $(e.target, styles.hr)[0];
    const infos = $(e.target, styles.infos)[0];
    const wI = infos.offsetWidth;
    const w = el.offsetWidth;

    anime({
      targets: el,
      duration: 800,
      translateX: wI - w,
      easing: "easeInOutExpo",
    });
  };

  const handleMouseLeave = (e: any) => {
    const el = $(e.target, styles.hr)[0];

    anime({
      targets: el,
      duration: 800,
      translateX: 0,
      easing: "easeInOutExpo",
    });
  };

  const resize = () => {
    for (let i = $img.length - 1; i >= 0; i--) {
      imgWidth[i] = $img[i][0].offsetWidth;
    }
  };

  useWindowResize(() => resize());

  return (
    <div className={styles.testimonies}>
      <div className={global.container}>
        <div className={cx(styles.titleContainer, global.container, global.medium, global.right)}>
          <h2 className={global.title}>{title}</h2>
        </div>
      </div>

      <Slideshow id={id} items={testimoniesItems} styles={styles}>
        {testimoniesItems.map((item, i) => (
          <SlideshowItem key={i} className={styles.slideshowItem} styles={styles}>
            <div ref={(el) => setRef(el, $wrapper)} className={styles.wrapper}>
              <p>{item.text}</p>

              <div className={styles.infos}>
                <span className={styles.name}>{item.name}</span>
                <span>{item.post}</span>
                <span>@ {item.company}</span>
              </div>
            </div>
          </SlideshowItem>
        ))}
      </Slideshow>

      <Navigation id={id} items={testimoniesItems} styles={styles} />

      <ul className={styles.imageContainer}>
        {testimoniesItems.map((item, i) => (
          <li
            key={i}
            ref={(el) => setRef(el, $imgContainer)}
            data-index={i}
            onClick={handleChangeIndex}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <div className={styles.infos}>
              <span>{linkLabel}</span>
              <span className={styles.name}>{item.name}</span>
              <span className={styles.post}>{item.post}</span>
              <span className={styles.company}>@ {item.company}</span>
              <i className={styles.hr}></i>
            </div>

            <div className={styles.imgWrapper}>
              <div className={styles.imgFiltered}>
                <div className={styles.imgFilteredTranslate}>
                  <Img fluid={item.img} alt={item.name} />
                  <div
                    className={cx(styles.overlay, {
                      [styles.alternative]: alternativeOverlay,
                    })}
                  ></div>
                </div>
              </div>

              <div className={styles.img}>
                <Img fluid={item.img} alt={item.name} className={styles.imgTranslate} />
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Testimonies;

export const TestimoniesQuery = graphql`
  query {
    pierreImg: file(relativePath: { eq: "testimonies/pierre.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 815) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    lucileImg: file(relativePath: { eq: "testimonies/lucile.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 815) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    alixImg: file(relativePath: { eq: "testimonies/alix.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 815) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    fabienImg: file(relativePath: { eq: "testimonies/fabien.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 815) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
