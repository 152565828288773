import { useEffect } from "react";

/**
 * Static store
 * We ensure there's only one of each type
 */
export class Store {}
Store.events = {};

/**
 * useWindowEvent
 */
const useWindowEvent = (eventName, cb) => {
  useEffect(() => {
    if (cb && eventName) {
      if (!Store.events[eventName]) {
        const event = { cbs: [cb] };
        event.cb = () => event.cbs.forEach((eachCb) => eachCb());
        window.addEventListener(eventName, event.cb);
        Store.events[eventName] = event;
      } else {
        Store.events[eventName].cbs.push(cb);
      }
    }

    return () => {
      // cleanup event
      if (eventName) {
        const event = Store.events[eventName];
        if (event) {
          const index = event.cbs.indexOf(cb);
          if (index !== -1) {
            event.cbs.splice(index, 1);
            if (event.cbs.length === 0) {
              window.removeEventListener(eventName, event.cb);
              Store.events[eventName] = null;
            }
          }
        }
      }
    };
  }, [eventName, cb]);
};

export default useWindowEvent;
