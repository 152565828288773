import cx from "classnames";
import React, { ReactNode } from "react";

import { AnimatedElement } from "../../helpers/animatedElement";
import { NavLink } from "../../helpers/NavLink";
import global from "../../scss/global.module.scss";
import styles from "./joinus.module.scss";

interface JoinUs {
  text: ReactNode;
  title: ReactNode;
  subtitle: ReactNode;
  cta?: ReactNode;
}

const JoinUs: React.FC<JoinUs> = (props: JoinUs) => {
  const { text, title, subtitle, cta = null } = props;

  return (
    <div className={cx(styles.joinUsContainer, global.container, global.noBottomSpace)}>
      <div className={styles.smallLogoContainer} ref={React.createRef()}>
        <AnimatedElement translateY={{ speed: 0 }} rotateZ={{ speed: -8 }}>
          <div className={styles.halfCircle} ref={React.createRef()}></div>
        </AnimatedElement>
        <div className={styles.donut} ref={React.createRef()}></div>
        <div className={styles.circle} ref={React.createRef()}></div>
      </div>
      <div className={cx(global.container, global.xsmall, global.right)}>
        <span className={styles.subtitle}>{subtitle}</span>
      </div>
      <div className={cx(global.container, global.small, global.right)}>
        <h2 className={styles.title}>{title}</h2>
      </div>
      <div className={cx(global.container, global.xsmall, global.right)}>
        <p className={styles.text}>{text}</p>
        <div className={styles.ctaContainer}>{cta}</div>
      </div>
    </div>
  );
};

export default JoinUs;
