import { RouteComponentProps } from "@reach/router";
import { PDFDownloadLink } from "@react-pdf/renderer";
import anime from "animejs";
import cx from "classnames";
import { Field, Form, Formik } from "formik";
import { MiniSignalBinding } from "mini-signals";
import React, { useEffect, useRef, useState } from "react";
import { FaInfoCircle } from "react-icons/fa";

import { isValidPhoneNumber } from "react-phone-number-input";
import * as Yup from "yup";
import { CheckboxRadio } from "../../components/Formik/CheckboxRadio";
import { ErrorMessage } from "../../components/Formik/ErrorMessage";
import { Input } from "../../components/Formik/Input";
import { PhoneNumberInput } from "../../components/Formik/PhoneNumberInput";
import SEO from "../../components/seo";
import eventDispatcher from "../../helpers/eventDispatcher/eventDispatcher";
import { NavigateData } from "../../helpers/NavLink";
import { LogoSmallOutline } from "../../helpers/Svg";

import "../../scss/partials/_form.scss";
import { onInitPageLoadTracking } from "../../helpers/tracking";
import global from "../../scss/global.module.scss";
import styles from "../../scss/pages/nous-contacter.module.scss";
import { postNewContact } from "../../utils/api";
import { PDFCGU } from "../cgu";
const { routes } = require("./../../routes.ts");

interface FormValues {
  name: string;
  isDenicheur: boolean;
  isClassicContract: boolean;
  contacts: {
    firstname: string;
    lastname: string;
    phone: string;
    email: string;
    isCheckedCgu: boolean;
  }[];
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required(),
  isDenicheur: Yup.boolean(),
  isClassicContract: Yup.boolean().when("isDenicheur", {
    is: false,
    then: Yup.boolean().oneOf([true], "Veulliez sélectionner au minimum une option"),
    otherwise: Yup.boolean(),
  }),
  contacts: Yup.array().of(
    Yup.object().shape({
      firstname: Yup.string().required(),
      lastname: Yup.string().required(),
      email: Yup.string().email().required(),
      phone: Yup.string()
        .required()
        .test("validPhoneNumber", "Veuillez entrer un numéro de téléphone valide", (value) => {
          if (typeof value === "string") {
            return isValidPhoneNumber(value);
          } else {
            return false;
          }
        }),
      isCheckedCgu: Yup.boolean().oneOf([true], "Ce champs est obligatoire"),
    }),
  ),
});

export const SignUpCompagny: React.FC<RouteComponentProps> = (props: RouteComponentProps) => {
  const { location } = props;
  const navRef = useRef<MiniSignalBinding>();
  const pageRef = useRef(null);
  const delay = 200;
  const $formContainer = useRef<HTMLDivElement>(null);
  const $form = useRef<HTMLFormElement>(null);
  const $successMessageContainer = useRef<HTMLDivElement>(null);
  const [showDenicheurSuccessMessage, setShowDenicheurSuccessMessage] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState(false);
  const checkParams = (param: string, val: string) => {
    const results = new URLSearchParams(props.location.search).get(param);
    if (results) {
      return results.split(",").includes(val);
    }
    return false;
  };
  const [denicheurChecked, setDenicheurChecked] = useState<boolean>(checkParams("offre", "denicheur"));

  useEffect(() => {
    onInitPageLoadTracking();
    enterAnimation();
    eventDispatcher.trigger("_initComponent", { delay });
    navRef.current = eventDispatcher.on("_navigate", exitAnimation);

    return () => {
      if (navRef.current) {
        eventDispatcher.off("_navigate", navRef.current);
      }
    };
  }, []);

  const exitAnimation = (d: NavigateData) => {
    const tl = anime.timeline({
      complete: () => {
        d.callback();
      },
      duration: 600,
      easing: "easeInOutExpo",
    });

    tl.add({
      opacity: [1, 0],
      targets: pageRef.current,
    });
  };
  const enterAnimation = () => {
    const tl = anime.timeline({
      delay,
      duration: 400,
      easing: "linear",
    });

    tl.add({
      opacity: [0, 1],
      targets: pageRef.current,
    });
  };

  const handleChange = (e: any): void => {
    e.target.checked ? setDenicheurChecked(true) : setDenicheurChecked(false);
  };

  const onSubmitForm = (values: FormValues, { setFieldError }: any) => {
    const contacts = [{ ...values.contacts[0], isAutoCompleted: true }];
    const vals = {
      ...values,
      contacts,
      status: "closed",
      isAutoCompleted: true,
    };
    if (submitting) {
      return;
    }
    setSubmitting(true);

    postNewContact(vals)
      .then(() => {
        showSuccessMessage(vals);
        setSubmitting(false);
        scrollToPosition();
      })
      .catch((error) => {
        if (error.response && 400 === error.response.status) {
          setSubmitting(false);
          if (error.response.data.violations) {
            for (const violation of error.response.data.violations) {
              setFieldError(violation.propertyPath, violation.message);
            }
          }
        }
        routes;
      });
  };

  const showSuccessMessage = (values: FormValues) => {
    const formContainerRect =
      $formContainer && $formContainer.current && $formContainer.current.getBoundingClientRect();
    const h = (formContainerRect && formContainerRect.height) || null;

    if (values.isDenicheur && !values.isClassicContract) {
      setShowDenicheurSuccessMessage(true);
    }

    const tl = anime.timeline({
      duration: 400,
      easing: "easeInOutExpo",
    });

    tl.add({
      duration: 0,
      height: h,
      targets: $formContainer.current,
    })
      .add({
        opacity: 0,
        targets: $form.current,
      })
      .add({
        opacity: [0, 1],
        targets: $successMessageContainer.current,
        begin: () => {
          if ($form.current) {
            $form.current.style.display = "none";
          }
          if ($successMessageContainer.current) {
            $successMessageContainer.current.style.display = "block";
          }
        },
      });
  };

  const scrollToPosition = () => {
    if ($successMessageContainer.current) {
      const scrollElement = window.document.scrollingElement || window.document.body || window.document.documentElement;
      anime({
        targets: scrollElement,
        duration: 1000,
        scrollTop: 0,
        easing: "easeInOutQuad",
      });
    }
  };

  return (
    <>
      <SEO
        meta={[
          {
            property: "og:url",
            content: location.href,
          },
        ]}
      />
      <div style={{ opacity: 0 }} className={global.pageWrapper} ref={pageRef}>
        <div className={styles.introWrapper}>
          <div className={styles.outlineLogoContainer}>
            <LogoSmallOutline />
          </div>
          <div className={styles.introContainer}>
            <h2 className={styles.title} ref={React.createRef()}>
              <span>Inscription</span>
              <span>
                <strong>entreprise</strong>
              </span>
            </h2>
          </div>
        </div>
        <div className={cx(global.container, global.xsmall)}>
          <div className={styles.companyFormContainer} ref={$formContainer}>
            <Formik
              onSubmit={onSubmitForm}
              initialValues={{
                name: "",
                isDenicheur: checkParams("offre", "denicheur"),
                isClassicContract: checkParams("offre", "classic"),
                contacts: [
                  {
                    firstname: "",
                    lastname: "",
                    phone: "",
                    email: "",
                    isCheckedCgu: false,
                  },
                ],
              }}
              validationSchema={validationSchema}
              validateOnChange={true}
            >
              {() => (
                <Form className={cx("form")} ref={$form}>
                  <div className="form-row-container">
                    <h4 className="form-part-title">Je suis intéressé par l’offre :</h4>
                    <div className="form-row double relative">
                      <ErrorMessage name="isClassicContract" className="form-error-contract" />
                      <div className="form-group">
                        <div className="form-group-content checkbox-group-content info-icon-group-content">
                          <div className={styles.denicheurWrapper}>
                            <div className={styles.denicheurField}>
                              <Field
                                component={CheckboxRadio}
                                name="isDenicheur"
                                type="checkbox"
                                label="Le dénicheur"
                                defaultChecked
                                onChangeCustom={(e: any) => handleChange(e)}
                              />
                              <div className="info-hover">
                                <FaInfoCircle />
                                <span className="info-tooltip">
                                  Le Dénicheur vous offre un tarif préférentiel et vous garantit une complète autonomie.
                                  Notre équipe vous contactera pour vous en détailler les éléments clés.
                                </span>
                              </div>
                            </div>
                            {denicheurChecked ? (
                              <div className={styles.denicheurInfo}>
                                <span>
                                  La réception du Dénicheur est gratuite. Dans le cas d’un recrutement via cette Talent
                                  Letter, le montant hors taxes de la commission s’élèvera à 15% du montant total du
                                  package annuel de rémunération défini au titre du contrat de travail du Candidat.
                                </span>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="form-group-content checkbox-group-content info-icon-group-content">
                          <Field
                            component={CheckboxRadio}
                            name="isClassicContract"
                            type="checkbox"
                            label="Le contrat classique"
                            showErrorMessage={false}
                            defaultChecked
                          />
                          <div className={"info-hover"}>
                            <FaInfoCircle />
                            <span className="info-tooltip mobileRight has-text-link">
                              <a
                                target="_blank"
                                rel="noreferrer noopener"
                                className="tooltip-text-link"
                                href={routes.app.recruter.path}
                              >
                                L’offre classique elinoï décrite via <u>ce lien</u>
                              </a>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-row double">
                      <div className="form-group">
                        <div className="form-group-content">
                          <Field component={Input} name="name" type="text" label="Le nom de votre entreprise" />
                        </div>
                      </div>
                    </div>
                    <div className="form-row double">
                      <div className="form-group">
                        <div className="form-group-content">
                          <Field component={Input} name="contacts[0].firstname" type="text" label="Votre prénom" />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="form-group-content">
                          <Field component={Input} name="contacts[0].lastname" type="text" label="Votre nom" />
                        </div>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group">
                        <div className="form-group-content">
                          <Field component={Input} name="contacts[0].email" type="email" label="Votre email" />
                        </div>
                      </div>
                    </div>
                    <div className="form-row double">
                      <div className="form-group">
                        <div className="form-group-content">
                          <Field
                            component={PhoneNumberInput}
                            name="contacts[0].phone"
                            label={
                              <div>
                                <label className="label-fix" htmlFor="contacts[0].phone">
                                  Votre numéro de téléphone
                                </label>
                                <div className="info-hover">
                                  <FaInfoCircle />
                                  <span className="info-tooltip">
                                    Un rapide entretien téléphonique permettra à nos équipes de mieux comprendre vos
                                    besoins et de vous présenter aux candidats. Nous ne partageons cette information
                                    avec personne.
                                  </span>
                                </div>
                              </div>
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group">
                        <div className="form-group-content checkbox-group-content">
                          <Field
                            component={CheckboxRadio}
                            name="contacts[0].isCheckedCgu"
                            type="checkbox"
                            showErrorMessage={false}
                            label={
                              <span>
                                J&apos;accepte les{" "}
                                <a href="/cgu" target="_blank">
                                  Conditions Générales d’utlisation
                                </a>{" "}
                                de elinoi.com applicables à / aux offre(s) sélectionnée(s)
                              </span>
                            }
                          />
                        </div>
                        <ErrorMessage name="contacts[0].isCheckedCgu" className="form-error-fix" />
                      </div>
                    </div>
                    <div className="form-row justify-right">
                      <button className={cx(styles.formSubmitCta, global.cta, global.ctaPrimary)} type="submit">
                        S&apos;inscrire
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>

            <div className={styles.successMessageContainer} ref={$successMessageContainer}>
              <h2 className={global.title}>
                Vous avez bien <strong>validé</strong> <br /> les <strong>CGU elinoï</strong> !
              </h2>
              <div>
                {showDenicheurSuccessMessage ? (
                  <p className={styles.text}>
                    Notre équipe vous contactera rapidement pour vous donner toutes les informations sur l&apos;offre du
                    dénicheur.
                  </p>
                ) : null}
                <p className={styles.text}>Vous pouvez les télécharger ici :</p>
                <p className={cx(styles.text, styles.cguDownloadContainer)}>
                  {
                    <PDFDownloadLink
                      className={cx(global.cta, global.ctaPrimary)}
                      document={<PDFCGU />}
                      fileName="elinoi-cgu.pdf"
                    >
                      {({ loading }) => (loading ? "Création du PDF..." : "Télécharger les CGU")}
                    </PDFDownloadLink>
                  }
                </p>
                <p className={styles.text}>
                  A très vite, <br /> l&apos;équipe elinoï
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
