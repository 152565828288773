import { RouteComponentProps } from "@reach/router";
import anime from "animejs";
import cx from "classnames";
import { Formik, Field, Form } from "formik";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import { MiniSignalBinding } from "mini-signals";
import React, { useRef, useEffect, useState, ChangeEvent } from "react";
import ReactPixel from "react-facebook-pixel";
import LinkedInTag from "react-linkedin-insight";
import Select from "react-select";

import Intro from "../blocks/intro/intro";
import SEO from "../components/seo";
import { AnimatedElement } from "../helpers/animatedElement";
import eventDispatcher from "../helpers/eventDispatcher/eventDispatcher";

import { NavigateData } from "../helpers/NavLink";
import { onInitPageLoadTracking, sendGA } from "../helpers/tracking";
import global from "../scss/global.module.scss";
import styles from "../scss/pages/simulateur-de-salaire.module.scss";

import client from "../utils/httpClient";
import { Salary, Family, Role } from "../utils/interfaces";
import { objText, hotDemande } from "../utils/texts-simulateur";

export const SimulateurDeSalaire: React.FC<RouteComponentProps> = (props: RouteComponentProps) => {
  const { location } = props;
  const data = useStaticQuery(graphql`
    query {
      logoSignature: file(relativePath: { eq: "simulateur-de-salaire/signature-gris.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      logoEMail: file(relativePath: { eq: "simulateur-de-salaire/logo-email.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      logoFacebook: file(relativePath: { eq: "simulateur-de-salaire/logo-facebook.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      logoLinkedin: file(relativePath: { eq: "simulateur-de-salaire/logo-linkedin.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const navRef = useRef<MiniSignalBinding>();
  const pageRef = useRef(null);
  const delay = 200;

  const [mobile] = useState<boolean>(typeof window !== "undefined" ? window.innerWidth <= 750 : false);
  const [showResult, setShowResult] = useState(false);
  const [customTxt, setCustomTxt] = useState<string | null>(null);
  const [salaries, setSalaries] = useState<Salary[]>([]);
  const [experienceYears, setExperienceYears] = useState<string>();
  const [jobValue, setJobValue] = useState<string>();
  const [activeFamily, setActiveFamily] = useState("");
  const [clickedRole, setClickedRole] = useState("");
  const [families, setFamilies] = useState<Family[]>([]);
  const [showedSalary, setShowedSalary] = useState<Salary>();
  const isHotJob = hotDemande.includes(jobValue) && ["0 - 2 ans", "3 - 5 ans", "6 - 10 ans"].includes(experienceYears);
  const subjectText: string = encodeURI("Tous les bons ont un prix de marché. Lequel ?");

  const bodyText: string = encodeURIComponent(
    "Hello,\n\nJ’ai pensé que ce simulateur de salaire elinoï pourrait t’intéresser ! Il te permet de visualiser les salaires moyens sur le marché selon ton métier et ton expérience, le tout en 3 clics. Pas mal non ?\n\n<a href='https://elinoi.com/simulateur-de-salaire'>Découvrir combien je vaux</a>\n\nN’hésite pas à le partager autour de toi,\n\nÀ très vite, ",
  );

  const shareLink: string = "elinoi.com/simulateur-de-salaire";

  const linkedinLink = `http://www.linkedin.com/shareArticle?mini=true&url=https://${encodeURI(shareLink)}`;

  const facebookLink = `https://www.facebook.com/sharer/sharer.php?u=https://${encodeURI(shareLink)}`;

  const triggerConversion = () => {
    LinkedInTag.track(process.env.GATSBY_LINKEDIN_CONVERSION_SIMU_BTOC_ID);
    ReactPixel.track("Lead", { content_name: "lead BtoC simulateur salaire" });
  };

  useEffect(() => {
    client.get("/api/v2/salary_simulators").then((response) => {
      setSalaries(response.data["hydra:member"]);
    });
  }, []);

  useEffect(() => {
    if (salaries && salaries.length) {
      const familiesGroup: Family[] = [];
      const roles: Role[] = [];
      salaries.map((salary: Salary) => {
        let family: Family;
        let role: Role;
        if (!familiesGroup.map((res: Family) => res.label).includes(salary.family)) {
          family = {
            "@id": salary["@id"],
            label: salary.family,
            options: [],
          };
          salaries.map((resultSalary: Salary) => {
            if (
              resultSalary.family === family.label
              && resultSalary.experienceYears === "0 - 2 ans"
              && resultSalary.manager === false
              && resultSalary.localisation === "Paris"
            ) {
              role = {
                "@id": `id-${resultSalary.role}`,
                value: resultSalary.role,
                familyName: resultSalary.family,
              };
              roles.push(role);
            }
          });
          family = {
            ...family,
            options: roles.filter((roleResult: Role) => roleResult.familyName === family.label),
          };
          familiesGroup.push(family);
        }
      });
      setFamilies(familiesGroup);
    }
  }, [salaries]);

  useEffect(() => {
    if (experienceYears && jobValue) {
      if (!showResult) {
        setShowResult(true);
      } else {
        sendGA("click", {
          event_label: "simu-btoc - rechercher - autres simu",
        });
      }
      salaries
        ? salaries.map((salary: Salary) => {
            if (
              salary.experienceYears === experienceYears
              && salary.role === jobValue
              && salary.manager === false
              && salary.localisation === "Paris"
            ) {
              setShowedSalary(salary);
            }
          })
        : null;
    }
  }, [experienceYears, jobValue]);

  useEffect(() => {
    if (showResult) {
      triggerConversion();
      sendGA("click", {
        event_label: "simu-btoc - rechercher - 1re simu",
      });
    }
  }, [showResult]);

  useEffect(() => {
    if (showedSalary) {
      if (objText[showedSalary.role] && objText[showedSalary.role][showedSalary.experienceYears]) {
        setCustomTxt(objText[showedSalary.role][showedSalary.experienceYears]);
      } else {
        setCustomTxt(null);
      }
      if (objText[showedSalary.role] && objText[showedSalary.role]["all-text"]) {
        setCustomTxt(objText[showedSalary.role]["all-text"]);
      }
    } else {
      setCustomTxt(null);
    }
  }, [showedSalary, experienceYears]);

  useEffect(() => {
    onInitPageLoadTracking();
    enterAnimation();
    eventDispatcher.trigger("_initComponent", { delay });
    navRef.current = eventDispatcher.on("_navigate", exitAnimation);

    return () => {
      if (navRef.current) {
        eventDispatcher.off("_navigate", navRef.current);
      }
    };
  }, []);

  const exitAnimation = (d: NavigateData) => {
    const tl = anime.timeline({
      complete: () => {
        d.callback();
      },
      duration: 600,
      easing: "easeInOutExpo",
    });

    tl.add({
      opacity: [1, 0],
      targets: pageRef.current,
    });
  };

  const enterAnimation = () => {
    const tl = anime.timeline({
      delay,
      duration: 400,
      easing: "linear",
    });

    tl.add({
      opacity: [0, 1],
      targets: pageRef.current,
    });
  };

  const scrollToPosition = (top, duration) => {
    const scrollElement = window.document.scrollingElement || window.document.body || window.document.documentElement;
    anime({
      targets: scrollElement,
      duration,
      scrollTop: top,
      easing: "easeInOutQuad",
    });
  };

  const onClickFamily = (fam: Family) => {
    setActiveFamily(fam["@id"]);
  };

  const onClickLoginCta = () => {
    // eventGA
    sendGA("click", {
      event_label: `simu-btoc - ce metier m'intéresse - ${jobValue} - ${experienceYears}`,
    });
    eventDispatcher.trigger("loginModal:Open");
  };

  const onClickRole = (roleSelected: Role) => {
    setJobValue(roleSelected.value);
    setClickedRole(roleSelected["@id"]);
  };

  const handleChange = (value: ChangeEvent<HTMLInputElement>) => {
    setExperienceYears(value.target.value);
    mobile ? scrollToPosition(500, 2000) : null;
  };

  const onChange = (value: any) => {
    setJobValue(value.value);
  };

  useEffect(() => {
    if (!!experienceYears) {
      sendGA("click", {
        event_label: `simu-btoc - years - ${experienceYears}`,
      });
    }
  }, [experienceYears]);
  useEffect(() => {
    if (!!jobValue) {
      sendGA("click", {
        event_label: `simu-btoc - metier - ${jobValue}`,
      });
    }
  }, [jobValue]);

  return (
    <div style={{ opacity: 0 }} className={global.pageWrapper} ref={pageRef}>
      <SEO
        meta={[
          {
            property: "og:url",
            content: location.href,
          },
        ]}
        title="elinoï - Votre futur job parmi plus de 200 startups"
        description="elinoï est un incubateur de talents qui vous aide à trouver votre futur job en startup. Business development, marketing, produit, opérations : inscrivez-vous en 5 minutes pour découvrir nos offres de CDI."
      />

      <Intro text={mobile ? introTextMobile : introTextDesktop} title={introTitle} />
      <div className={styles.salarySimulatorBlock}>
        <div className={styles.leftBlockWrapper}>
          <div className={styles.smallLogoContainer} ref={React.createRef()}>
            {!mobile ? (
              <>
                <AnimatedElement translateY={{ speed: 0 }} rotateZ={{ speed: -14 }}>
                  <div className={styles.halfCircle} ref={React.createRef()}></div>
                </AnimatedElement>
                <AnimatedElement translateY={{ speed: -10 }}>
                  <div className={styles.donut} ref={React.createRef()}></div>
                </AnimatedElement>
                <AnimatedElement translateY={{ speed: -5 }}>
                  <div className={styles.circle} ref={React.createRef()}></div>
                </AnimatedElement>
              </>
            ) : null}
          </div>
          <div className={styles.leftBlock}>
            {!showResult ? (
              <>
                <div className={cx(styles.textBlock, global.uppercase)}>
                  <span>_Parlons peu</span>
                  <span>Parlons argent</span>
                </div>
                <div className={cx(styles.title, styles.textBlock)}>
                  <h1>Simulateur de salaire</h1>
                </div>
                <div className={cx(styles.enumBlock, styles.textBlock)}>
                  <span>C'est tout simple et très rapide. En quelques clics : </span>
                  <ol>
                    <li>
                      <strong>1- </strong>Sélectionnez le poste qui vous intéresse
                    </li>
                    <li>
                      <strong>2- </strong>Indiquez votre expérience professionnelle
                    </li>
                    <li>
                      <strong>3- </strong>Découvrez la fourchette de salaire brut et variable annuel associée
                    </li>
                  </ol>
                </div>
              </>
            ) : (
              <>
                {isHotJob ? (
                  <div className={styles.badgeContainer} onClick={onClickLoginCta}>
                    <span className={styles.badgeContent}>
                      <span className={styles.badgeWording}>Postuler</span>
                    </span>
                  </div>
                ) : null}
                <div className={cx(styles.textBlock, global.uppercase)}>
                  <span>_Votre</span>
                  <span>Salaire :</span>
                </div>
                <div className={styles.textBlock}>
                  <span className={styles.superTitle}>Salaire fixe (annuel brut)</span>
                  <h2 className={styles.title}>
                    {showedSalary?.fixMin} à {showedSalary?.fixMax} k€
                  </h2>
                </div>
                <div className={styles.textBlock}>
                  <span className={styles.superTitle}>Variable potentiel (annuel brut)</span>
                  <h2 className={styles.title}>
                    {showedSalary?.varMin} à {showedSalary?.varMax} k€
                  </h2>
                </div>
                <div className={styles.ctaContainer}>
                  <button
                    type="button"
                    className={cx(global.cta, styles.cta, { [styles.isHot]: isHotJob })}
                    onClick={onClickLoginCta}
                  >
                    Je cherche un nouveau job
                  </button>
                </div>
                <div className={cx(styles.textBlock, styles.explication)}>{customTxt}</div>
                <div className={styles.hotJobContainer}>
                  {isHotJob ? (
                    <>
                      Les startups recrutent beaucoup sur ce poste ! <br /> C'est LE moment de postuler 🔥🔥🔥 !
                    </>
                  ) : null}
                </div>
              </>
            )}
          </div>
        </div>
        <div className={styles.rightBlockWrapper}>
          <div className={styles.rightBlock}>
            <>
              <span className={cx(styles.spanTitleFamilies, global.uppercase)}>Sélectionnez un poste</span>
              {families
                && families.map((family: Family) => {
                  const isActive = activeFamily === family["@id"];
                  return (
                    <div
                      key={`family-${family["@id"]}`}
                      className={styles.familyBlock}
                      onClick={() => onClickFamily(family)}
                    >
                      <div className={styles.familyName}>
                        <span>{family.label}</span>
                      </div>
                      <div className={cx(styles.jobsContainer, { [styles.open]: isActive })}>
                        {family.options.map((option: Role) => {
                          const isClicked = clickedRole === option["@id"];
                          return (
                            <div
                              key={`role-${option["@id"]}`}
                              className={cx(styles.jobBlock, { [styles.clickedRole]: isClicked })}
                              onClick={() => onClickRole(option)}
                            >
                              {option.value}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
            </>
            <>
              <div className={styles.selectWrapper}>
                <span>
                  Selectionnez un <strong>poste</strong>
                </span>
                <Select
                  menuPlacement="bottom"
                  classNamePrefix="jobSelect"
                  getOptionValue={(option) => option["@id"]}
                  getOptionLabel={(option: Role) => option.value}
                  onChange={(value) => onChange(value)}
                  options={families}
                  placeholder="Rechercher un poste"
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      primary25: "#DADADA",
                      primary: "#EEEEEE",
                    },
                  })}
                />
              </div>
            </>
            <div className={styles.radioGroupWrapper}>
              <span className={cx(styles.spanTitle, global.uppercase)}>
                Indiquez votre <strong>expérience</strong> professionnelle
              </span>
              <Formik
                initialValues={{
                  picked: "",
                }}
                onSubmit={async (values) => {
                  await new Promise((r) => setTimeout(r, 500));
                  alert(JSON.stringify(values, null, 2));
                }}
              >
                {() => (
                  <Form onChange={(value) => handleChange(value)}>
                    <div className={styles.radioGroup} role="group" aria-labelledby="my-radio-group">
                      <label className={styles.radio}>
                        <Field type="radio" name="picked" value="0 - 2 ans" />
                        <span>0 - 2 ans</span>
                      </label>
                      <label className={styles.radio}>
                        <Field type="radio" name="picked" value="3 - 5 ans" />
                        <span>3 - 5 ans</span>
                      </label>
                      <label className={styles.radio}>
                        <Field type="radio" name="picked" value="6 - 10 ans" />
                        <span>6 - 10 ans</span>
                      </label>
                      <label className={styles.radio}>
                        <Field type="radio" name="picked" value="11 - 15 ans" />
                        <span>11 - 15 ans</span>
                      </label>
                      <label className={styles.radio}>
                        <Field type="radio" name="picked" value="15+ ans" />
                        <span>+ 15 ans</span>
                      </label>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
      <div className={cx(styles.outroWrapper, global.container, global.small)}>
        <div className={styles.logoBlock}>
          <a
            href={`mailto:non-défini@elinoi.com?subject=${subjectText}&body=${bodyText}`}
            target="_blank"
            rel="noreferrer"
          >
            <Img className={styles.icon} fluid={data.logoEMail.childImageSharp.fluid} alt="mail logo" />
          </a>
          <a href={facebookLink} target="_blank" rel="noreferrer">
            <Img className={styles.icon} fluid={data.logoFacebook.childImageSharp.fluid} alt="facebook logo" />
          </a>
          <a href={linkedinLink} target="_blank" rel="noreferrer">
            <Img className={styles.icon} fluid={data.logoLinkedin.childImageSharp.fluid} alt="linkedin logo" />
          </a>
        </div>
        <div className={styles.outro}>
          <div className={styles.title}>
            Ce simulateur de salaire en startup ... <strong>comment a-t-il été créé ?</strong>
          </div>
          <div className={styles.text}>
            <p>Ces données sont la synthèse d’un scrapping puis d'une analyse rigoureuse :</p>
            <p>
              1/ Du marché sur plus de 30 000 offres d'emplois publiées par des centaines de startups et jeunes PMEs
              françaises
            </p>
            <p>
              2/ De notre expérience en tant que partenaire de recrutement depuis 5 ans et des salaires d’entrée des
              centaines de talents recrutés via l’incubateur.
            </p>
            <p>
              Si certains chiffres ou associations peuvent vous étonner, sachez que nous avons ensuite développé un
              algorithme qui, au vu des données récoltées sur le marché, pondère et nous donne la simulation de salaire
              pour ce cas particulier même s’il est peu commun. Ces informations sont indicatives. Elle n’ont pas valeur
              de vérité absolue et ont pour unique but de vous donner une vision précise et réaliste du marché de
              l’emploi aujourd’hui. Si vous avez des feedbacks à nous faire, n'hésitez pas à contacter{" "}
              <a href="mailto:contact@elinoi.com">contact@elinoi.com</a>.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const introTitle = (
  <>
    <span ref={React.createRef()}>
      Les bons ont un <strong>prix</strong>
    </span>
    <span ref={React.createRef()}>de marché.</span>
  </>
);

const introTextDesktop = (
  <>
    Chez elinoï, on est convaincu que tout travail mérite salaire. Mais lequel ? Nos équipes ont créé ce simulateur pour
    vous aider à mieux vous projeter dans un métier selon votre expérience professionnelle grâce au traitement des
    données de plusieurs dizaines de milliers d’offres et de plus de 3 ans d’analyse du marché.
  </>
);

const introTextMobile = <>Chez elinoï, on est convaincu que tout travail mérite salaire. Mais lequel ?</>;
