export const chunkArray = (arr: any[], chunkSize = 3, addPlaceholder = false) => {
  let index = 0;
  const arrLength = arr.length;
  const tempArray = [];
  let myChunk = [];

  for (index = 0; index < arrLength; index += chunkSize) {
    myChunk = arr.slice(index, index + chunkSize);

    if (myChunk.length < chunkSize && addPlaceholder) {
      myChunk = addPlaceHolder(arr.slice(index, index + chunkSize), chunkSize);
    }
    tempArray.push(myChunk);
  }

  return tempArray;
};

export const addPlaceHolder = (arr: any[], nbRef: number) => {
  const diff = nbRef - arr.length;

  for (let i = 0; i < diff; i++) {
    arr.push({ type: "placeholder" });
  }

  return arr;
};
