import { Router, Redirect } from "@reach/router";
import React from "react";
import { CGU } from "../views/cgu";
import { FAQ } from "../views/faq";
import { IndexPage } from "../views/index";
import { LinkedInRedirect } from "../views/login-check";
import { MentionsLegales } from "../views/mentions-legales";
import { MissionPage } from "../views/notre-mission";
import { Offboarding } from "../views/offboarding";
import { ReferralRedirect } from "../views/redirects/referral";
import { SignUpCompagny } from "../views/sign_up/company";
import { SimulateurDeSalaire } from "../views/simulateur-de-salaire";
import { AccompagnerPage } from "../views/vous-accompagner";
import { RecruterPage } from "../views/vous-aider-a-recruter";
const { routes } = require("../routes");

const App = () => {
  return (
    <>
      <Router>
        <Redirect from="/conditions-generales-utilisation" to={routes.app.cgu.path} noThrow />
        <Redirect from={routes.app.contactUs.path} to={routes.app.recruter.path} noThrow />
        <AccompagnerPage path={routes.app.talent.path} />
        <CGU path={routes.app.cgu.path} />
        <FAQ path={routes.app.faq.path} />
        <LinkedInRedirect path={routes.app.loginCheck.path} />
        <MentionsLegales path={routes.app.mentionsLegales.path} />
        <MissionPage path={routes.app.aboutUs.path} />
        <Offboarding path={routes.app.offboarding.path} />
        <SimulateurDeSalaire path={routes.app.simulateurDeSalaire.path} />
        <RecruterPage path={routes.app.recruter.path} />
        <SignUpCompagny path={routes.app.signupCompany.path} />

        <ReferralRedirect path=":token" />

        <IndexPage path="/" />
      </Router>
    </>
  );
};
export default App;
