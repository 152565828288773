import { FieldProps } from "formik";
import * as React from "react";
import { ErrorMessage } from "../ErrorMessage";

interface Props extends FieldProps {
  className?: string;
  defaultChecked?: boolean;
  label?: string;
  id?: string;
  type: string;
  placeholder?: string;
  showErrorMessage?: boolean;
  onChangeCustom?: (e: any) => void;
  value: any;
}

export const CheckboxRadio: React.FC<Props> = ({
  defaultChecked = false,
  field,
  form: { setFieldTouched },
  id,
  label,
  showErrorMessage = true,
  type,
  value,
  onChangeCustom,
  ...rest
}: Props) => {
  const fieldId = id ? id : field.name;

  const onBlurInput = (e: React.SyntheticEvent) => {
    setFieldTouched(field.name, true);
    field.onBlur(e);
  };
  const onChangeInput = (e: React.SyntheticEvent) => {
    field.onChange(e);
    if (typeof onChangeCustom === "function") {
      onChangeCustom(e);
    }
  };
  return (
    <>
      <input
        name={field.name}
        type={type}
        id={fieldId}
        value={value}
        onBlur={onBlurInput}
        onChange={onChangeInput}
        checked={defaultChecked ? field.value : null}
        {...rest}
      />
      <label className="checkbox-label" htmlFor={fieldId}>
        <span className="customRadio"></span>
        {label}
      </label>

      {showErrorMessage && <ErrorMessage name={field.name} />}
    </>
  );
};
