/**
 * Dom function
 */

const $ = (c, className) => c.querySelectorAll("." + className);

const setRef = (el, array) => {
  if (el && !array.current.includes(el)) {
    array.current.push(el);
  }
};

export { $, setRef };
