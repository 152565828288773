import { RouteComponentProps } from "@reach/router";
import anime from "animejs";
import cx from "classnames";
import { graphql, useStaticQuery } from "gatsby";
import { MiniSignalBinding } from "mini-signals";
import React, { useEffect, useRef } from "react";
import Announcement from "../blocks/announcement/announcement";
import CoachingOffers, { CoachOffer } from "../blocks/coachingoffers/coachingoffers";
import Intro from "../blocks/intro/intro";
import KeyInfos, { KeyInfo } from "../blocks/keyinfos/keyinfos";
import { LoginModalOpen } from "../blocks/loginModal/loginModal";
import { NewsletterModalOpen } from "../blocks/newsletterModal/newsletterModal";
import TeamVideos from "../blocks/teamvideos/teamvideos";
import Testimonies from "../blocks/testimonies/testimonies";
import UserReviews from "../blocks/userReviews/userReviews";
import SEO from "../components/seo";
import eventDispatcher from "../helpers/eventDispatcher/eventDispatcher";

import { NavigateData } from "../helpers/NavLink";
import { onInitPageLoadTracking, sendGA } from "../helpers/tracking";
import global from "../scss/global.module.scss";

export const AccompagnerPage: React.FC<RouteComponentProps> = (props: RouteComponentProps) => {
  const { location } = props;
  const data = useStaticQuery(VousAccompagnerPageQuery);
  const navRef = useRef<MiniSignalBinding>();
  const pageRef = useRef(null);
  const newsRef = useRef(null);
  const introSrc = data.introImg.childImageSharp.fluid;
  const delay = 200;

  useEffect(() => {
    onInitPageLoadTracking();
    enterAnimation();
    eventDispatcher.trigger("_initComponent", { delay });
    navRef.current = eventDispatcher.on("_navigate", exitAnimation);

    newsRef.current = setTimeout(() => {
      eventDispatcher.trigger(NewsletterModalOpen);
    }, 3000);

    return () => {
      if (navRef.current) {
        eventDispatcher.off("_navigate", navRef.current);
      }
      if (newsRef.current) {
        clearTimeout(newsRef.current);
      }
    };
  }, []);

  const exitAnimation = (d: NavigateData) => {
    const tl = anime.timeline({
      complete: () => {
        d.callback();
      },
      duration: 600,
      easing: "easeInOutExpo",
    });

    tl.add({
      opacity: [1, 0],
      targets: pageRef.current,
    });
  };

  const enterAnimation = () => {
    const tl = anime.timeline({
      delay,
      duration: 400,
      easing: "linear",
    });

    tl.add({
      opacity: [0, 1],
      targets: pageRef.current,
    });
  };

  const onClickIntroCta = () => {
    eventDispatcher.trigger(LoginModalOpen);
    // event GA
  };

  return (
    <>
      <SEO
        meta={[
          {
            property: "og:url",
            content: location.href,
          },
        ]}
      />
      <div style={{ opacity: 0 }} className={global.pageWrapper} ref={pageRef}>
        <Intro
          fluid={introSrc}
          text={introTexte}
          title={introTitle}
          cta={
            <>
              <span
                onClick={() => {
                  eventDispatcher.trigger(LoginModalOpen);
                  sendGA("click", {
                    event_label: "ouverture modale - s'inscrire - top",
                  });
                }}
                className={global.cta}
              >
                Candidater
              </span>
            </>
          }
        />

        <KeyInfos
          title={
            <>
              La <strong>valeur ajoutée</strong> elinoï
            </>
          }
          infos={keyInfos}
          cta={
            <span
              className={cx(global.cta, global.ctaPrimary)}
              onClick={() => {
                eventDispatcher.trigger(LoginModalOpen);
                sendGA("click", {
                  event_label: "ouverture modale - s'inscrire - valeur ajoutée",
                });
              }}
            >
              Rejoindre l'incubateur
            </span>
          }
        />

        <CoachingOffers
          coachOffers={coachOffers}
          linkLabel="Découvrir"
          link="/"
          subtitle
          title={
            <>
              Ce que l'<strong>on vous propose</strong>
            </>
          }
        />

        <Testimonies
          title={
            <>
              Ils en parlent <strong>mieux que nous</strong>
            </>
          }
        />

        <Announcement />

        <UserReviews />

        {/* <TeamVideos /> */}
      </div>
    </>
  );
};

const introTitle = (
  <>
    <span ref={React.createRef()}>Sonnez</span>
    <span ref={React.createRef()}>enfin à la</span>
    <span ref={React.createRef()}>
      <strong>bonne porte</strong>.
    </span>
  </>
);
const introTexte = (
  <>
    Candidatez en 5 min top chrono pour être accompagné dans votre recherche de job et être connecté aux meilleures
    startups.
  </>
);

const keyInfos: KeyInfo[] = [
  {
    big: "01",
    bigColor: "#303030",
    title: "Vous accélérer dans votre recherche de job",
    text: (
      <>
        <strong>Vous connecter</strong> aux projets et opportunités qui vous correspondent et vous accompagner dans vos
        process de recrutement.
      </>
    ),
  },
  {
    big: "02",
    bigColor: "#303030",
    title: "Vous ouvrir les portes d'une communauté de 9 000 tops profils. ",
    text: (
      <>
        <strong>Des rendez-vous</strong> métiers. Des rencontres conviviales régulières. Un réseau onnecté de talents
        pour échanger au quotidien.
      </>
    ),
  },
];

const coachOffers: CoachOffer[] = [
  {
    subtitle: "01",
    slug: "etre-accompagne-A-Z",
    title: (
      <>
        être <br /> accompagné <br /> de A à Z
      </>
    ),
    text:
      "Pour postuler aux offres qui correspondent vraiment à votre profil et à vos attentes et réussir vos entretiens.",
  },
  {
    subtitle: "02",
    slug: "etre-mieux-informe",
    title: (
      <>
        être <br /> mieux <br /> informé
      </>
    ),
    text:
      "Pour connaître l'envers du décor et avoir les informations clés pour postuler de manière éclairée et intégrer une entreprise qui correspond à vos valeurs et vos envies.",
  },
  {
    subtitle: "03",
    slug: "aller-plus-vite",
    title: (
      <>
        aller <br /> plus <br /> vite
      </>
    ),
    text:
      "Grâce à nos équipes qui connaissent sur le bout des doigts nos startups partenaires et vous accompagnent tout au long de vos process.",
  },
];

export const VousAccompagnerPageQuery = graphql`
  query {
    introImg: file(relativePath: { eq: "photo-2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
