import cx from "classnames";
import React, { ReactNode } from "react";

import { AnimatedElement } from "../../helpers/animatedElement";
import eventDispatcher from "../../helpers/eventDispatcher/eventDispatcher";
import Slideshow from "../../helpers/slideshow/slideshow";
import SlideshowItem from "../../helpers/slideshow/slideshowItem";
import { LogoSmall } from "../../helpers/Svg";
import { sendGA } from "../../helpers/tracking";

import global from "../../scss/global.module.scss";
import uniqid from "../../utils/uniqid";
import { LoginModalOpen } from "../loginModal/loginModal";
import styles from "./coachingoffers.module.scss";

export interface CoachOffer {
  linkLabel?: ReactNode;
  link?: string;
  subtitle?: ReactNode;
  text: ReactNode;
  title: ReactNode;
  slug: string;
}

interface CoachOffers {
  coachOffers: CoachOffer[];
  linkLabel: ReactNode;
  link: string;
  subtitle: ReactNode;
  title: ReactNode;
}

const CoachingOffers: React.FC<CoachOffers> = (props: CoachOffers) => {
  const { coachOffers, link, linkLabel, title, subtitle } = props;
  const id = uniqid();

  return (
    <div className={styles.coachingWrapper}>
      <div className={cx(styles.coachingContainer, global.container)}>
        <div className={styles.smallLogoContainer} ref={React.createRef()}>
          <AnimatedElement translateY={{ speed: 0 }} rotateZ={{ speed: -14 }}>
            <div className={styles.halfCircle} ref={React.createRef()}></div>
          </AnimatedElement>
          <AnimatedElement translateY={{ speed: -10 }}>
            <div className={styles.donut} ref={React.createRef()}></div>
          </AnimatedElement>
          <AnimatedElement translateY={{ speed: -5 }}>
            <div className={styles.circle} ref={React.createRef()}></div>
          </AnimatedElement>
        </div>
        <div className={cx(styles.titleContainer, global.container, global.medium, global.right)}>
          <h2 className={global.title}>{title}</h2>
        </div>
        <ul className={styles.list}>
          {coachOffers.map((cOffer, i) => {
            const subT = cOffer.subtitle || subtitle;
            const ll = cOffer.linkLabel || linkLabel;
            return (
              <li key={`coachoffer-${cOffer.slug}`} className={styles.item}>
                <div className={styles.itemContent}>
                  <div className={styles.itemInnerContent}>
                    <span className={styles.itemSubTitle}>{subT}</span>
                    <h3 className={styles.itemTitle}>{cOffer.title}</h3>
                    <div className={styles.text}>{cOffer.text}</div>
                    <div>
                      <span
                        className={cx(global.cta)}
                        onClick={() => {
                          // event GA
                          eventDispatcher.trigger(LoginModalOpen);
                          sendGA("click", {
                            event_label: `ouverture modale - DECOUVRIR - ${cOffer.slug}`,
                          });
                        }}
                      >
                        {ll}
                      </span>
                    </div>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
      <div className={styles.mobileSlideShow}>
        <Slideshow id={id} items={coachOffers} styles={styles}>
          {coachOffers.map((cOffer, i) => {
            const subT = cOffer.subtitle || subtitle;
            const ll = cOffer.linkLabel || linkLabel;
            return (
              <SlideshowItem key={i} className={styles.item} styles={styles}>
                <div className={styles.itemContent}>
                  <div className={styles.itemInnerContent}>
                    <span className={styles.itemSubTitle}>{subT}</span>
                    <h3 className={styles.itemTitle}>{cOffer.title}</h3>
                    <div className={styles.text}>{cOffer.text}</div>
                    <div>
                      <span
                        className={cx(global.cta)}
                        onClick={() => {
                          // event GA
                          eventDispatcher.trigger(LoginModalOpen);
                        }}
                      >
                        {ll}
                      </span>
                    </div>
                  </div>
                </div>
              </SlideshowItem>
            );
          })}
        </Slideshow>
      </div>
    </div>
  );
};

export default CoachingOffers;
