import { RouteComponentProps } from "@reach/router";
import anime from "animejs";
import cx from "classnames";
import { MiniSignalBinding } from "mini-signals";
import React, { useEffect, useRef } from "react";

import SEO from "../components/seo";
import eventDispatcher from "../helpers/eventDispatcher/eventDispatcher";
import { NavigateData } from "../helpers/NavLink";
import { LogoSmallOutline } from "../helpers/Svg";
import { onInitPageLoadTracking } from "../helpers/tracking";

import global from "../scss/global.module.scss";
import styles from "../scss/pages/faq.module.scss";

interface Question {
  question: React.ReactNode;
  answer: React.ReactNode;
}

export const FAQ: React.FC<RouteComponentProps> = (props: RouteComponentProps) => {
  const { location } = props;
  const navRef = useRef<MiniSignalBinding>();
  const pageRef = useRef(null);
  const delay = 200;

  useEffect(() => {
    onInitPageLoadTracking();
    enterAnimation();
    eventDispatcher.trigger("_initComponent", { delay });
    navRef.current = eventDispatcher.on("_navigate", exitAnimation);

    return () => {
      if (navRef.current) {
        eventDispatcher.off("_navigate", navRef.current);
      }
    };
  }, []);

  const exitAnimation = (d: NavigateData) => {
    const tl = anime.timeline({
      complete: () => {
        d.callback();
      },
      duration: 600,
      easing: "easeInOutExpo",
    });

    tl.add({
      opacity: [1, 0],
      targets: pageRef.current,
    });
  };
  const enterAnimation = () => {
    const tl = anime.timeline({
      delay,
      duration: 400,
      easing: "linear",
    });

    tl.add({
      opacity: [0, 1],
      targets: pageRef.current,
    });
  };
  const questions: Question[] = [
    {
      question: (
        <>
          À qui s'adresse <strong>elinoï</strong> ?
        </>
      ),
      answer: (
        <ul>
          <li>
            elinoï accueille tous les Talents à la recherche d'un CDI, mais ne conviendra pas à des personnes
            recherchant des contrats courts (CDD, stage, alternance) ou des postes de freelance.
          </li>
          <li>
            elinoï propose tous les types de poste (market, business development, operations, product, etc.), à
            l'exception des métiers de développeur web et mobile.
          </li>
        </ul>
      ),
    },
    {
      question: (
        <>
          Où voir <strong>les offres</strong> qui <strong>me sont proposées</strong> ?
        </>
      ),
      answer: (
        <ul>
          <li>
            Un email hebdomadaire est envoyé chaque lundi aux Talents qui reçoivent une sélection d'opportunités
            sélectionnées rien que pour eux.
          </li>
          <li>
            Vous pouvez retrouver toutes vos opportunités en vous connectant depuis le site elinoï à votre espace
            personnel et retrouver les offres dans l'onglet "Mes opportunités".
          </li>
        </ul>
      ),
    },
    {
      question: (
        <>
          Je souhaite <strong>postuler à une offre</strong>, comment faire ?
        </>
      ),
      answer: (
        <ul>
          <li>Depuis le mail hebdomadaire ou depuis mon espace "Mes opportunités", je clique sur l'offre;</li>
          <li>Cette dernière semble correspondre à mes attentes et à mon profil, je clique sur "Je suis intéressé";</li>
          <li>
            Un Talent Manager elinoï vous contactera directement s’il pense que votre candidature est pertinente. Par
            téléphone, vous pourrez discuter plus en détails de l’offre avant d’être mis en relation avec la startup le
            cas échéant.
          </li>
        </ul>
      ),
    },
    {
      question: (
        <>
          Qui sont les <strong>startups partenaires d'elinoï</strong> ?
        </>
      ),
      answer: (
        <p>
          elinoï sélectionne les meilleures startups pour les membres de notre incubateur de Talents. Toutes sont
          viables financièrement (à moyen et long terme) mais cela peut aussi bien être une startup de 10 personnes qui
          a levé en seed, qu'une startup de 150 personnes qui a levé plusieurs dizaines de millions d'euros. À vous de
          voir ce qui te correspond ! Quelques exemples : Treatwell, ManoMano, MonBanquet, Alkemics, etc. Nous ne
          travaillons pas avec une startup chez qui nous n'irions nous-mêmes pas travailler.
        </p>
      ),
    },
    {
      question: (
        <>
          Sur <strong>quels métiers</strong> agit elinoï ?
        </>
      ),
      answer: (
        <p>
          Business developer, Sales, Marketing, Growth-hacker, Product, UX-UI, Data Science, RH, etc. Tout sauf nos amis
          les développeurs !
        </p>
      ),
    },
    {
      question: <>Je ne reçois pas les offres d'elinoï, pourquoi ?</>,
      answer: (
        <ul>
          <li>
            Vérifiez dans vos spams si notre mail ne s'y est pas glissé, et ajoutez-nous à vos contacts si c'est le cas
            pour que ça ne se reproduise plus !
          </li>
          <li>
            Les offres sont envoyées de manière ultra-ciblée tous les lundis. Il se peut que pour l'instant aucune ne
            corresponde à ton profil...
          </li>
          <li>
            Les offres sont envoyées le lundi matin, si votre entrée dans le club date de moins d'une semaine, il faudra
            patienter !
          </li>
          <li>Votre profil n'est peut-être pas complété à 100% !</li>
        </ul>
      ),
    },
    {
      question: (
        <>
          Comment bien remplir <strong>mon profil</strong> ?
        </>
      ),
      answer: (
        <ul>
          <li>Je remplis toutes les informations nécessaires pour être certain de recevoir mes opportunités;</li>
          <li>
            Je diversifie les rôles voulus : il est possible de sélectionner plusieurs "types de poste" et plusieurs
            "postes";
          </li>
          <li>
            Je reste réaliste : en tant que junior, je ne mets pas en salaire souhaité 70-90K€, j'ai fait 3 ans de
            marketing, je ne coche pas uniquement "Country Manager" en poste voulu. En manquant de réalisme vous ne
            serez jamais recontacté suite à vos candidatures et surtout vous risquez de passer à côté d'offres qui vous
            correspondent vraiment !
          </li>
        </ul>
      ),
    },
    {
      question: (
        <>
          Je postule mais <strong>je n'ai pas de nouvelles</strong>, pourquoi ?
        </>
      ),
      answer: (
        <ul>
          <li>
            Chaque candidature est analysée par un membre de l'équipe. Si celle-ci se révèle pertinente, nous vous
            recontacterons.
          </li>
          <li>
            Concernant vos candidatures, vérifiez que vous avez rempli votre profil de manière cohérente et réaliste
            (cf. point du dessus).
          </li>
        </ul>
      ),
    },
    {
      question: (
        <>
          Je ne souhaite plus recevoir le <strong>mail hebdomadaire de mes opportunités</strong>, que faire ?
        </>
      ),
      answer: (
        <p>
          Rendez-vous directement dans les paramètres de votre espace personnel. Il vous suffit d'y désactiver les
          "alertes Opportunités".
        </p>
      ),
    },
  ];
  return (
    <>
      <SEO
        meta={[
          {
            property: "og:url",
            content: location.href,
          },
        ]}
        title="elinoï - Foire aux questions"
      />
      <div style={{ opacity: 0 }} className={global.pageWrapper} ref={pageRef}>
        <div className={styles.introWrapper}>
          <div className={styles.outlineLogoContainer}>
            <LogoSmallOutline />
          </div>
          <div className={styles.introContainer}>
            <div className={cx(styles.halfCircleContainer, global.container, global.medium, global.right)}>
              <div className={styles.halfCircle}></div>
            </div>
            <h2 className={styles.title} ref={React.createRef()}>
              <span>
                <strong>Foire</strong> aux
              </span>
              <span>
                <strong>questions</strong>
              </span>
            </h2>
          </div>
        </div>

        <div className={cx(global.container, global.xsmall)}>
          <ul className={styles.faqList}>
            {questions.map((f, i) => {
              return (
                <li key={`faq-${i}`} className={styles.faqItem}>
                  <h2 className={cx(global.title, styles.faqTitle)}>{f.question}</h2>
                  <div className="faq-answer">{f.answer}</div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
};
