import { createPopup } from "@typeform/embed";
import anime from "animejs";
import cx from "classnames";
import { graphql, useStaticQuery } from "gatsby";
import React, { useEffect, useRef, useState } from "react";
import { MdReplay } from "react-icons/md";
import { chunkArray } from "../../helpers/chunkArray";

import { sendGA } from "../../helpers/tracking";

import global from "../../scss/global.module.scss";
import { setRef } from "../../utils/dom";
import styles from "./clients.module.scss";
import "@typeform/embed/build/css/popup.css";

const Clients: React.FC = (props) => {
  const data = useStaticQuery(ClientImages);
  const {} = props;

  const imgArr: { [key: string]: string }[] = Object.values(data);

  const $logos = useRef<HTMLElement[]>([]);
  const $list = useRef<HTMLUListElement>(null);
  const perPage = 7;
  let currPage = useRef<number>(0).current;
  const [page, setPage] = useState<number>(0);
  const imgPages = chunkArray(imgArr, perPage);
  const maxPage = useRef<number>(imgPages.length - 1).current;
  const isAnimating = useRef<boolean>(false);
  const { toggle } = createPopup("GtWm3oSI", {
    hideHeaders: true,
  });
  const onClickSwitchCta = () => {
    if (isAnimating.current) {
      return;
    }
    isAnimating.current = true;
    currPage = page + 1 > maxPage ? 0 : page + 1;
    swtichPage(currPage);
    sendGA("refresh", {
      event_label: "clients - refresh",
    });
  };
  const swtichPage = (pageIndex: number) => {
    let refHeight = 50;
    if ($list && $list.current) {
      refHeight = $list.current.children[0].getBoundingClientRect().height;
    }
    const y = refHeight + 5;

    const tl = anime.timeline({
      duration: 600,
      easing: "cubicBezier(0.74, 0.04, 0.34, 0.95)",
      complete: () => {
        $logos.current = [];
        setPage(pageIndex);
      },
    });

    tl.add({
      translateY: [0, y],
      targets: $logos.current,
      delay: anime.stagger(40),
    });
  };

  const showTeamItem = () => {
    let refHeight = 50;

    if ($list.current) {
      refHeight = $list.current.children[0].getBoundingClientRect().height;
    }
    const y = (refHeight + 5) * -1;

    const tl = anime.timeline({
      duration: 600,
      easing: "cubicBezier(0.74, 0.04, 0.34, 0.95)",
      complete: () => {
        isAnimating.current = false;
      },
    });

    tl.add({
      translateY: [y, 0],
      targets: $logos.current,
      delay: anime.stagger(40),
    });
  };

  useEffect(() => {
    showTeamItem();
  }, [page]);

  return (
    <div className={global.container}>
      <div className={cx(styles.titleContainer, global.container, global.medium, global.right)}>
        <h2 className={cx(styles.title, global.title)}>
          Ils <strong>recrutent</strong> avec elinoï
        </h2>
      </div>
      <div className={styles.listSwitchContainer}>
        <div className={styles.switchContainer}>
          <span className={styles.switchCta} onClick={onClickSwitchCta}>
            <MdReplay />
          </span>
        </div>
        <ul className={styles.list} ref={$list}>
          {imgPages[page].map((img, i) => {
            return (
              <li key={`clientImg-${i}`} className={styles.clientItem}>
                <img src={img.publicURL} ref={(el) => setRef(el, $logos)} />
              </li>
            );
          })}
        </ul>
      </div>
      <div className={cx(styles.clientTextContainer, global.container, global.medium, global.right)}>
        <p className={styles.clientText}>
          Comme eux vous souhaitez changer votre façon de recruter ?<br />
          Échangez avec nos équipes !
        </p>
      </div>
      <div className={cx(styles.ctaContainer, global.container, global.small, global.right)}>
        <button
          type="button"
          onClick={() => {
            sendGA("click", {
              event_label: "clients - nous contacter",
            });
            toggle();
          }}
          className={cx(global.cta, global.ctaPrimary, global.btnCta)}
        >
          Nous contacter
        </button>
      </div>
    </div>
  );
};

export default Clients;

export const ClientImages = graphql`
  query {
    payfit: file(relativePath: { eq: "clients/payfit.png" }) {
      publicURL
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ankorstore: file(relativePath: { eq: "clients/ankorstore.png" }) {
      publicURL
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    wavestone: file(relativePath: { eq: "clients/wavestone.png" }) {
      publicURL
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    side: file(relativePath: { eq: "clients/side.png" }) {
      publicURL
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    luko: file(relativePath: { eq: "clients/luko.png" }) {
      publicURL
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    alkemics: file(relativePath: { eq: "clients/alkemics.png" }) {
      publicURL
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    smartkeyword: file(relativePath: { eq: "clients/smart-keyword.png" }) {
      publicURL
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    playplay: file(relativePath: { eq: "clients/playplay.png" }) {
      publicURL
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    treatwell: file(relativePath: { eq: "clients/treatwell.png" }) {
      publicURL
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    welcometothejungle: file(relativePath: { eq: "clients/welcome-to-the-jungle.png" }) {
      publicURL
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    salutbeaute: file(relativePath: { eq: "clients/salut-beaute.png" }) {
      publicURL
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    coorpacademy: file(relativePath: { eq: "clients/coorpacademy.png" }) {
      publicURL
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    dropcontact: file(relativePath: { eq: "clients/dropcontact.png" }) {
      publicURL
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    insider: file(relativePath: { eq: "clients/insider.png" }) {
      publicURL
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    cajoo: file(relativePath: { eq: "clients/cajoo.png" }) {
      publicURL
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    jobmaker: file(relativePath: { eq: "clients/jobmaker.png" }) {
      publicURL
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    kapten: file(relativePath: { eq: "clients/kapten.png" }) {
      publicURL
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    kronik: file(relativePath: { eq: "clients/kronik.png" }) {
      publicURL
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    lbf: file(relativePath: { eq: "clients/lbf.png" }) {
      publicURL
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    hosman: file(relativePath: { eq: "clients/hosman.png" }) {
      publicURL
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    malt: file(relativePath: { eq: "clients/malt.png" }) {
      publicURL
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ouistock: file(relativePath: { eq: "clients/ouistock.png" }) {
      publicURL
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    receiptbank: file(relativePath: { eq: "clients/receiptbank.png" }) {
      publicURL
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    thebradery: file(relativePath: { eq: "clients/thebradery.png" }) {
      publicURL
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    neufcentscare: file(relativePath: { eq: "clients/900care.png" }) {
      publicURL
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    urbansportsclub: file(relativePath: { eq: "clients/urban-sports-club.png" }) {
      publicURL
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    virgil: file(relativePath: { eq: "clients/virgil.png" }) {
      publicURL
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    lucca: file(relativePath: { eq: "clients/lucca.png" }) {
      publicURL
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
