import { RouteComponentProps } from "@reach/router";
import anime from "animejs";
import cx from "classnames";
import { MiniSignalBinding } from "mini-signals";
import React, { useEffect, useRef } from "react";
import { LinkedInPopUp } from "react-linkedin-login-oauth2";

import { USERSPACE_URI } from "../components/linkedInCta/linkedInCta";
import SEO from "../components/seo";

import eventDispatcher from "../helpers/eventDispatcher/eventDispatcher";
import { NavigateData } from "../helpers/NavLink";
import { LogoSmallOutline } from "../helpers/Svg";
import { onInitPageLoadTracking } from "../helpers/tracking";

import global from "../scss/global.module.scss";
import styles from "../scss/pages/login-check.module.scss";

export const LinkedInRedirect: React.FC<RouteComponentProps> = () => {
  const navRef = useRef<MiniSignalBinding>();
  const pageRef = useRef(null);
  const delay = 200;

  useEffect(() => {
    onInitPageLoadTracking();
    enterAnimation();
    eventDispatcher.trigger("_initComponent", { delay });
    navRef.current = eventDispatcher.on("_navigate", exitAnimation);

    return () => {
      if (navRef.current) {
        eventDispatcher.off("_navigate", navRef.current);
      }
    };
  }, []);

  const exitAnimation = (d: NavigateData) => {
    const tl = anime.timeline({
      complete: () => {
        d.callback();
      },
      duration: 600,
      easing: "easeInOutExpo",
    });

    tl.add({
      opacity: [1, 0],
      targets: pageRef.current,
    });
  };
  const enterAnimation = () => {
    const tl = anime.timeline({
      delay,
      duration: 400,
      easing: "linear",
    });

    tl.add({
      opacity: [0, 1],
      targets: pageRef.current,
    });
  };
  return (
    <>
      <SEO
        meta={[
          {
            name: "robots",
            content: "noindex",
          },
        ]}
      />
      <LinkedInPopUp />
      <div className={cx(styles.pageWrapper, global.pageWrapper)} ref={pageRef}>
        <div className={styles.introWrapper}>
          <div className={styles.outlineLogoContainer}>
            <LogoSmallOutline />
          </div>
          <div className={styles.introContainer}>
            <div className={cx(styles.halfCircleContainer, global.container, global.medium, global.right)}>
              <div className={styles.halfCircle}></div>
            </div>
            <h2 className={styles.title} ref={React.createRef()}>
              <span>Redirection vers</span>
              <span>
                <strong>votre espace</strong>
              </span>
            </h2>
          </div>
        </div>
        <div className={cx(styles.pageContent, global.container, global.xsmall)}>
          <div className={cx(styles.redirectTitle, global.title)}>Si vous n'etes pas redirigé automatiquement</div>
          <a href={USERSPACE_URI} className={cx(global.cta, global.ctaPrimary)}>
            cliquez ici
          </a>
        </div>
      </div>
    </>
  );
};
