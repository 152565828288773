import { RouteComponentProps } from "@reach/router";
import { createPopup } from "@typeform/embed";
import anime from "animejs";
import cx from "classnames";
import { graphql, useStaticQuery } from "gatsby";
import { MiniSignalBinding } from "mini-signals";
import React, { useEffect, useRef } from "react";
import Announcement from "../blocks/announcement/announcement";
import Clients from "../blocks/clients/clients";
import Intro from "../blocks/intro/intro";
import KeyInfos, { KeyInfo } from "../blocks/keyinfos/keyinfos";
import { NewsletterModalOpen } from "../blocks/newsletterModal/newsletterModal";
import Testimonies from "../blocks/testimonies/testimonies";
import VerticalSlider, { VerticalSlide } from "../blocks/verticalSlider";
import SEO from "../components/seo";
import eventDispatcher from "../helpers/eventDispatcher/eventDispatcher";
import "@typeform/embed/build/css/popup.css";
import { NavigateData } from "../helpers/NavLink";
import { onInitPageLoadTracking, sendGA } from "../helpers/tracking";
import global from "../scss/global.module.scss";

export const RecruterPage: React.FC<RouteComponentProps> = (props: RouteComponentProps) => {
  const { location } = props;
  const data = useStaticQuery(RecruterPageQuery);
  const navRef = useRef<MiniSignalBinding>();
  const pageRef = useRef(null);
  const newsRef = useRef(null);
  const introSrc = data.introImg.childImageSharp.fluid;
  const delay = 200;
  const { toggle } = createPopup("GtWm3oSI", {
    hideHeaders: true,
  });
  const testimoniesTtems = [
    {
      text: (
        <>
          La qualité des Account Managers d’elinoï fait une très grande différence. Nous avions travaillé avec
          différents acteurs du recrutement et l'expérience client n'est pas comparable. Ils ont compris notre culture,
          nos métiers et notre stratégie. Cela nous a fait gagner beaucoup de temps.
        </>
      ),
      img: data.alexisImg.childImageSharp.fluid,
      post: "CEO",
      company: "Fabernovel Code",
      name: "Alexis",
    },
    {
      text: (
        <>
          Quand elinoï recrute pour nous, on a l’impression que les équipes recrutent pour chez eux. elinoï prend soin
          de choisir les bonnes entreprises et les bons candidats afin qu’ils soient parfaitement en phase. C’est un
          partenaire très fiable dont on ne pourrait plus se passer.
        </>
      ),
      img: data.marahImg.childImageSharp.fluid,
      post: "People Operations Manager",
      company: "Alkemics",
      name: "Marah",
    },
    {
      text: (
        <>
          elinoï m’a permis d’ouvrir mon champ des possibles lors de mes recrutements, en me faisant rencontrer des
          candidats pertinents que je n’aurais pas sourcés seule. L’écoute et la pertinence sont deux vraies forces
          d’elinoï.
        </>
      ),
      img: data.claudeImg.childImageSharp.fluid,
      post: "CEO & Founder",
      company: "Magic Makers",
      name: "Claude",
    },
    {
      text: (
        <>
          L'équipe d'elinoï nous a aidés à définir ce dont on avait besoin pour nos futures offres, et nous a même
          conseillés sur la meilleure façon d'attirer les talents chez PayFit. Ceux qui nous ont rejoints par ce biais
          incarnent parfaitement notre culture.
        </>
      ),
      img: data.firminImg.childImageSharp.fluid,
      post: "Co-founder & CEO",
      company: "PayFit",
      name: "Firmin",
    },
  ];

  const methodeSteps: VerticalSlide[] = [
    {
      title: (
        <>
          Vous <br /> connaître
        </>
      ),
      text: (
        <>
          Un Account Manager (expert métier elinoï) et un Talent Incubator Manager (expert talent elinoï) viennent vous
          rencontrer dans vos bureaux afin de mieux connaître votre business, s’imprégner de votre culture d’entreprise
          et challenger le job pour lequel vous recrutez.
        </>
      ),
      cta: (
        <button
          type="button"
          onClick={() => {
            sendGA("click", {
              event_label: "slider - nous contacter - etape 1",
            });
            toggle();
          }}
          className={cx(global.cta, global.btnCta)}
        >
          Nous contacter
        </button>
      ),
    },
    {
      title: (
        <>
          Diffuser <br /> votre offre
        </>
      ),
      text: (
        <>
          Dans un souci d’efficacité, votre offre est mise en ligne, visible et accessible aux 10 000 talents de notre
          incubateur dans les 24h qui suivent le rendez-vous avec nos experts.
        </>
      ),
      cta: (
        <button
          type="button"
          onClick={() => {
            sendGA("click", {
              event_label: "slider - nous contacter - etape 2",
            });
            toggle();
          }}
          className={cx(global.cta, global.btnCta)}
        >
          Nous contacter
        </button>
      ),
    },
    {
      title: "Lancer un 1er Sprint",
      text: (
        <>
          Notre équipe lance un premier “sprint” sur une semaine durant laquelle elle vous envoie une première shortlist
          de 3 à 5 talents. L’objectif ? Nous permettre d’affiner nos recherches selon vos critères et de vous connecter
          à des candidats qui correspondent à ce que vous recherchez. En bref, vous gagnez du temps.
        </>
      ),
      cta: (
        <button
          type="button"
          onClick={() => {
            sendGA("click", {
              event_label: "slider - nous contacter - etape 3",
            });
            toggle();
          }}
          className={cx(global.cta, global.btnCta)}
        >
          Nous contacter
        </button>
      ),
    },
    {
      title: "Être et rester agile",
      text: (
        <>
          Chaque semaine, notre équipe vous connecte à de nouveaux talents sélectionnés en amont et prend le temps de
          faire le point avec vous lors de rendez-vous hebdomadaires dédiés. Vous faites part de vos retours, nous
          redéfinissons ensemble votre besoin pour ajuster et affiner les recherches en vue du prochain sprint.
        </>
      ),
      cta: (
        <button
          type="button"
          onClick={() => {
            sendGA("click", {
              event_label: "slider - nous contacter - etape 4",
            });
            toggle();
          }}
          className={cx(global.cta, global.btnCta)}
        >
          Nous contacter
        </button>
      ),
    },
    {
      title: "Recruter le talent qu’il vous faut",
      text: (
        <>
          Nos experts vous accompagnent jusqu’à l’intégration du talent recruté dans votre startup et prennent le temps
          de faire un bilan de notre collaboration pour préparer au mieux les prochaines. À noter, un processus de
          recrutement ne prend généralement pas plus de 2 mois.
        </>
      ),
      cta: (
        <button
          type="button"
          onClick={() => {
            sendGA("click", {
              event_label: "slider - nous contacter - etape 5",
            });
            toggle();
          }}
          className={cx(global.cta, global.btnCta)}
        >
          Nous contacter
        </button>
      ),
    },
  ];

  useEffect(() => {
    onInitPageLoadTracking();
    enterAnimation();
    eventDispatcher.trigger("_initComponent", { delay });
    navRef.current = eventDispatcher.on("_navigate", exitAnimation);

    newsRef.current = setTimeout(() => {
      eventDispatcher.trigger(NewsletterModalOpen);
    }, 3000);

    return () => {
      if (navRef.current) {
        eventDispatcher.off("_navigate", navRef.current);
      }
      if (newsRef.current) {
        clearTimeout(newsRef.current);
      }
    };
  }, []);

  const exitAnimation = (d: NavigateData) => {
    const tl = anime.timeline({
      complete: () => {
        d.callback();
      },
      duration: 600,
      easing: "easeInOutExpo",
    });

    tl.add({
      opacity: [1, 0],
      targets: pageRef.current,
    });
  };

  const enterAnimation = () => {
    const tl = anime.timeline({
      delay,
      duration: 400,
      easing: "linear",
    });

    tl.add({
      opacity: [0, 1],
      targets: pageRef.current,
    });
  };

  return (
    <>
      <SEO
        meta={[
          {
            property: "og:url",
            content: location.href,
          },
        ]}
        title="elinoï - Recrutez les futurs talents de votre startup"
      />
      <div style={{ opacity: 0 }} className={global.pageWrapper} ref={pageRef}>
        <Intro
          fluid={introSrc}
          text={introTexte}
          title={introTitle}
          cta={
            <>
              <button type="button" onClick={toggle} className={cx(global.cta, global.btnCta)}>
                Recruter avec elinoï
              </button>
            </>
          }
        />

        <KeyInfos
          title={
            <>
              La <strong>valeur ajoutée</strong> elinoï
            </>
          }
          infos={keyInfos}
          cta={
            <button
              type="button"
              onClick={() => {
                sendGA("click", {
                  event_label: "nous contacter",
                });
                toggle();
              }}
              className={cx(global.cta, global.ctaPrimary, global.btnCta)}
            >
              Nous contacter
            </button>
          }
        />

        <VerticalSlider
          title={
            <>
              Notre méthode en <strong>5 étapes</strong>
            </>
          }
          items={methodeSteps}
        />

        <Clients />

        <Testimonies
          title={
            <>
              Ils en parlent <strong>mieux que nous</strong>
            </>
          }
          items={testimoniesTtems}
          alternativeOverlay={true}
        />

        <Announcement />
      </div>
    </>
  );
};

const introTitle = (
  <>
    <span ref={React.createRef()}>Parlons</span>
    <span ref={React.createRef()}>enfin la</span>
    <span ref={React.createRef()}>
      <strong>même langue</strong>.
    </span>
  </>
);
const introTexte = (
  <>
    Les recruteurs et entreprises ont souvent du mal à s’accorder et trouver les bonnes personnes s’avère (très)
    chronophage. Pourtant, c’est elles qui font toute la valeur d’une entreprise.
    <br />
    Chez nous, vous aurez affaire à des recruteurs qui savent de quoi ils parlent. Nous connaissons votre jargon,
    comprenons vos enjeux et nous efforçons de proposer les bonnes personnes.
  </>
);

const keyInfos: KeyInfo[] = [
  {
    big: "95%",
    title: "des talents placés valident leur période d’essai.",
    text: (
      <>
        <strong>Vous aider</strong> à recruter les meilleurs talents.
      </>
    ),
  },
  {
    big: "10 000",
    title: "talents dans l’incubateur",
    text: (
      <>
        <strong>Faire</strong> rayonner votre marque employeur.
      </>
    ),
  },
];

const RecruterPageQuery = graphql`
  query {
    introImg: file(relativePath: { eq: "photo-3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    alexisImg: file(relativePath: { eq: "testimonies/alexis.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 815) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    marahImg: file(relativePath: { eq: "testimonies/marah.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 815) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    claudeImg: file(relativePath: { eq: "testimonies/claude.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 815) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    firminImg: file(relativePath: { eq: "testimonies/firmin.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 815) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
