import { RouteComponentProps } from "@reach/router";
import { Document, Font, Page, StyleSheet, Text, View, PDFDownloadLink } from "@react-pdf/renderer";
import anime from "animejs";
import cx from "classnames";
import { MiniSignalBinding } from "mini-signals";
import React, { useEffect, useRef } from "react";
import SEO from "../components/seo";
import ApexNewBook from "../fonts/subset-ApexNew-Book.ttf";
import ApexNewBookItalic from "../fonts/subset-ApexNew-BookItalic.ttf";
import ApexNewHeavyItalic from "../fonts/subset-ApexNew-HeavyItalic.ttf";
import ApexNewMedium from "../fonts/subset-ApexNew-Medium.ttf";
import AvantGardeMedium from "../fonts/subset-AvantGarde-Medium.ttf";
import AvantGardeBold from "../fonts/subset-ITCAvantGardeStd-Bold.ttf";
import eventDispatcher from "../helpers/eventDispatcher/eventDispatcher";
import { NavigateData } from "../helpers/NavLink";
import { LogoSmallOutline } from "../helpers/Svg";
import { onInitPageLoadTracking } from "../helpers/tracking";

import global from "../scss/global.module.scss";
import styles from "../scss/pages/cgu.module.scss";

Font.register({
  family: "ApexNew",
  fonts: [
    { src: ApexNewBook }, // font-style: normal, font-weight: normal
    { src: ApexNewBookItalic, fontStyle: "italic" },
    { src: ApexNewMedium, fontWeight: 500 },
    { src: ApexNewHeavyItalic, fontStyle: "italic", fontWeight: 700 },
  ],
});
Font.register({
  family: "AvantGarde",
  fonts: [
    { src: AvantGardeMedium, fontWeight: 500 },
    { src: AvantGardeBold, fontWeight: 600 },
  ],
});
Font.registerHyphenationCallback((word) => [word]);

const pdfStyles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 26,
  },
  header: {
    fontSize: 9,
    marginBottom: 20,
    textAlign: "center",
    color: "#888",
  },
  quote: {
    fontSize: 12,
    fontFamily: "ApexNew",
    fontWeight: 500,
    lineHeight: 1.4,
    paddingHorizontal: 26,
    color: "#333",
    marginVertical: 20,
  },
  bottomSpace: {
    marginBottom: 50,
  },
  bold: {
    fontWeight: 500,
    color: "#333",
  },
  titleh1: {
    fontSize: 22,
    fontFamily: "AvantGarde",
    fontWeight: 500,
    color: "#5010f2",
    textTransform: "uppercase",
    marginVertical: 5,
  },
  titleh2: {
    fontSize: 16,
    fontFamily: "AvantGarde",
    fontWeight: 600,
    color: "#333",
    textTransform: "uppercase",
    marginTop: 14,
    marginBottom: 10,
  },
  titleh3: {
    fontSize: 14,
    fontFamily: "AvantGarde",
    fontWeight: 600,
    color: "#333",
    marginTop: 10,
    marginBottom: 8,
  },
  text: {
    fontSize: 10,
    fontFamily: "ApexNew",
    lineHeight: 1.6,
    color: "#666",
    marginBottom: 8,
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableCol: {
    width: "50%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableColHeader: {
    paddingVertical: 2,
    paddingHorizontal: 8,
    backgroundColor: "#ede7ff",
  },
  tableCell: {
    margin: "auto",
    paddingVertical: 6,
    fontSize: 10,
  },
});

export const PDFCGU = () => (
  <Document>
    <Page style={pdfStyles.body}>
      <Text style={pdfStyles.header} fixed>
        elinoï - conditions générales d'utilisation
      </Text>
      <Text style={{ ...pdfStyles.text, borderBottom: 1, borderBottomColor: "#cbcbcb", paddingBottom: 12 }}>
        Le présent document constitue les Conditions Générales d’Utilisation du site www.elinoi.com et plus
        généralement, du service fourni par elinoï.
        {"\n"}
        Veuillez noter que les Conditions Générales d’elinoï sont susceptibles d’être modifiées à tout moment : vous
        êtes donc invité à les consulter régulièrement.
      </Text>
      <Text style={{ ...pdfStyles.text, ...pdfStyles.quote }}>
        EN COMPLÉTANT LE PROCESSUS D’INSCRIPTION ET CLIQUANT SUR « J’ACCEPTE », VOUS DÉCLAREZ QUE :{"\n"}
        {"\n"}
        (1) VOUS AVEZ LU, COMPRIS ET ACCEPTE D’ÊTRE LIÉ PAR LES PRÉSENTES CONDITIONS GÉNÉRALES D’UTILISATION ;{"\n"}
        {"\n"}
        (2) VOUS AVEZ L’ÂGE LÉGAL REQUIS POUR CONCLURE UN CONTRAT AVEC ELINOI,
        {"\n"}
        {"\n"}
        ET (3) VOUS ÊTES HABILITÉ À CONCLURE UN ENGAGEMENT DE MANIÈRE PERSONNELLE, OU POUR LE COMPTE DE LA SOCIÉTÉ
        MENTIONNÉE LORS DE VOTRE INSCRIPTION.
        {"\n"}
        {"\n"}
        DANS LE CAS OÙ VOUS N’ACCEPTERIEZ PAS D’ÊTRE LIÉ PAR LES PRÉSENTES CONDITIONS GÉNÉRALES D’UTILISATION, VOUS NE
        SERIEZ PAS EN MESURE D’ACCÉDER AU SERVICE.
      </Text>
      <Text style={pdfStyles.titleh3}>Définitions</Text>
      <Text style={pdfStyles.text}>
        <Text style={pdfStyles.bold}>SITE</Text> : désigne l’interface WEB accessible depuis le monde entier à l’adresse
        URL www.elinoi.com
      </Text>
      <Text style={pdfStyles.text}>
        <Text style={pdfStyles.bold}>elinoï</Text> : désigne la société elinoï, SAS inscrite au RCS de PARIS sous le
        numéro 799 174 701 en date du 18 décembre 2013, situé au 106 rue de Richelieu - 75002 PARIS
      </Text>
      <Text style={pdfStyles.text}>
        <Text style={pdfStyles.bold}>PROFIL</Text> : désigne le compte créé lors de l’inscription par l’Utilisateur,
        accessible par elinoï et comportant les noms, prénom, date de naissance, adresse IP et les informations
        relatives au Talent
      </Text>
      <Text style={pdfStyles.text}>
        <Text style={pdfStyles.bold}>TALENT</Text> : désigne une personne à la recherche d’un emploi dans une start-up,
        s’étant connectée au SITE par le biais de LINKEDIN ou avec son adresse email et qui a été acceptée dans
        l'incubateur par elinoï après validation de son profil dont la confirmation lui aura été communiquée par email
      </Text>
      <Text style={pdfStyles.text}>
        <Text style={pdfStyles.bold}>START-UP</Text> : désigne toutes start-ups souhaitant recruter et ayant pris
        contact avec elinoï sur le site via le formulaire de contact.
      </Text>
      <Text style={pdfStyles.text}>
        <Text style={pdfStyles.bold}>UTILISATEUR (S)</Text> : désigne à la fois les talents et les start-ups qui se sont
        connectées aux services.
      </Text>
      <Text style={pdfStyles.text}>
        L’ensemble des termes employés avec une majuscule aux présentes auront le même sens et la même portée que ceux
        qui leur ont été donnés au présent article.
      </Text>
      <Text style={pdfStyles.titleh2}>RELATION CONTRACTUELLE ET OBJET</Text>
      <Text style={pdfStyles.text}>
        Les présentes conditions d’utilisation (les « CGU ») régissent l’utilisation par l’Utilisateur depuis tout pays
        du site Web elinoï disponible à l’adresse URL suivante : www.elinoi.com, de son contenu et des Services (les «
        services ») mis à disposition par la société elinoï SAS, au capital social de 1.000 euros, immatriculée au RCS
        de PARIS sous le numéro 799 174 701 et dont le siège social est situé au 106 rue de Richelieu - 75002 PARIS,
        ci-après dénommée « elinoï ».
      </Text>

      <Text style={pdfStyles.text}>
        En accédant aux Services et en les utilisant, l’Utilisateur accepte d’être lié par les présentes CGU qui
        établissent une relation contractuelle entre lui et elinoï. Si l’Utilisateur n’accepte pas les présentes CGU, il
        ne pourra pas accéder aux Services ni les utiliser.
      </Text>

      <Text style={pdfStyles.text}>
        Des dispositions supplémentaires peuvent s’appliquer à certains Services, notamment des règles spécifiques à un
        événement particulier ou à une activité particulière, et les dites dispositions supplémentaires seront
        communiquées à l’Utilisateur dans le cadre des Services concernés. Aux fins des Services concernés, les
        dispositions supplémentaires viennent s’ajouter aux CGU, desquelles elles doivent être réputées faire partie.
        Les dispositions supplémentaires prévaudront sur les présentes Conditions en cas d’incompatibilité dans le cadre
        des Services concernés.
      </Text>

      <Text style={pdfStyles.titleh1}>PARTIE I - DISPOSITIONS RELATIVES AUX TALENTS</Text>
      <Text style={pdfStyles.titleh2}>1/ PRÉSENTATION DU SITE ET DES SERVICES</Text>
      <Text style={pdfStyles.text}>
        Le SITE est un site internet de recrutement professionnel mettant en relation des Talents à la recherche d’un
        emploi et des start-ups qui cherchent à recruter.
      </Text>

      <Text style={pdfStyles.text}>Le SITE propose aux Utilisateurs les Services suivants :</Text>

      <Text style={pdfStyles.text}>
        - se connecter au SITE et créer son Profil par le biais de LINKEDIN ou de son adresse email;
      </Text>
      <Text style={pdfStyles.text}>
        - pour le Talent : envoyer à elinoï son nom, prénom, date de naissance et des réponses à des questions posées
        par elinoï permettant d’en savoir plus sur la personnalité du Talent et sur son job idéal;
      </Text>
      <Text style={pdfStyles.text}>
        - être intégré dans l'incubateur de Talents si le profil professionnel correspond;
      </Text>
      <Text style={pdfStyles.text}>- se voir présenter des opportunités professionnelles pour les Talents;</Text>
      <Text style={pdfStyles.text}>
        - se voir présenter des candidatures pour les start-ups, après avoir pris contact avec elinoï via le formulaire
        de contact présent sur le Site;
      </Text>
      <Text style={pdfStyles.text}>
        - être contacté par le recruteur elinoï par email ou directement par téléphone;
      </Text>
      <Text style={pdfStyles.text}>- l’envoi d’une newsletter;</Text>
      <Text style={pdfStyles.text}>- des évènements.</Text>

      <Text style={pdfStyles.text}>
        Le SITE est réservé à des Utilisateurs majeurs. En conséquence, les personnes âgées de moins de 18 ans ne
        pourront pas s’inscrire et devenir Utilisateurs
      </Text>

      <Text style={pdfStyles.titleh2}>2/ INSCRIPTION</Text>
      <Text style={pdfStyles.text}>Le Talent pourra s'inscrire via LINKEDIN.</Text>
      <Text style={pdfStyles.text}>
        Afin de faciliter l’inscription aux Services et la création du Profil Talent, le SITE utilise le service
        LINKEDIN ce qui lui permet de récupérer des données publiées sur LINKEDIN telles que :
      </Text>
      <Text style={pdfStyles.text}>
        - être contacté par le recruteur elinoï par email ou directement par téléphone;
      </Text>
      <Text style={pdfStyles.text}>- le nom;</Text>
      <Text style={pdfStyles.text}>- les prénoms;</Text>
      <Text style={pdfStyles.text}>- la photo;</Text>
      <Text style={pdfStyles.text}>
        - la date de naissance (à partir de laquelle le SITE déterminera l’âge de l’Utilisateur);
      </Text>
      <Text style={pdfStyles.text}>- les expériences professionnelles.</Text>
      <Text style={pdfStyles.text}>elinoï s’engage à ne rien publier sur le compte LINKEDIN des Talents.</Text>

      <Text style={pdfStyles.titleh3}>Sécurité</Text>
      <Text style={pdfStyles.text}>
        L’Utilisateur est responsable du maintien de la confidentialité des détails de son compte, et est pleinement
        responsable de toutes les activités qui se produisent sur son compte.
      </Text>

      <Text style={pdfStyles.titleh3}>Désactivation du profil</Text>
      <Text style={pdfStyles.text}>
        Le Talent peut désactiver son Profil via le SITE s’il ne souhaite plus être visible dans le fichier d’elinoï.
      </Text>
      <Text style={pdfStyles.text}>
        En cas de réactivation du Compte dans la période de 2 ans suite à la désactivation du Profil, les données
        conservées seront réaffectées à son Profil et le Profil redeviendra visible.
      </Text>

      <Text style={pdfStyles.titleh2}>3/ PRÉSENTATION DES SERVICES</Text>

      <Text style={pdfStyles.titleh3}>Sélection du talent</Text>
      <Text style={pdfStyles.text}>
        Le Talent soumet son profil à elinoï après s’être inscrit sur le SITE et avoir répondu aux questions ainsi
        qu'aux demandes d’informations lors de la création du Profil.
      </Text>
      <Text style={pdfStyles.text}>
        Il reçoit alors un email de validation sur l’adresse email indiquée lui confirmant son inscription dans
        l’incubateur de talents ou lui indiquant que le profil ne correspond pas.
      </Text>

      <Text style={pdfStyles.titleh3}>Entrée dans l’incubateur de talents</Text>
      <Text style={pdfStyles.text}>
        Dans le cas où son entrée dans l’incubateur de talents est validée par elinoï, le Talent peut alors se voir
        proposer des postes correspondants à ses attentes. elinoï prendra alors contact avec le Talent par email à
        l’adresse email indiquée et/ou par téléphone.
      </Text>

      <Text style={pdfStyles.titleh3}>Parrainage</Text>
      <Text style={pdfStyles.text}>
        Le Talent pourra inviter d'autres Talents à rejoindre l'incubateur et gagner une prime de 300 € s'ils sont
        recrutés en indiquant l’adresse email de la personne concernée.
      </Text>

      <Text style={pdfStyles.titleh1}>PARTIE II - DISPOSITIONS RELATIVES AUX RECRUTEURS</Text>

      <Text style={pdfStyles.titleh2}>1/ OBLIGATIONS D’elinoï</Text>
      <Text style={pdfStyles.titleh3}>1.1 - Sur l’offre classique</Text>
      <Text style={pdfStyles.text}>
        Pendant la phase d’accompagnement à la recherche de candidats, selon la formule classique (ie hors offre
        Dénicheur)
      </Text>
      <Text style={pdfStyles.text}>elinoï s’engage à faire ses meilleurs efforts à l’effet de :</Text>

      <Text style={pdfStyles.text}>
        1. diffuser sur le job board elinoï la fiche entreprises et la/les fiche(s) offre(s).
      </Text>
      <Text style={pdfStyles.text}>
        2. présenter au client des candidats susceptibles de répondre aux Qualifications définies par le client pour
        chacun des postes.
      </Text>
      <Text style={pdfStyles.text}>
        3. transmettre au client les informations communiquées par les candidats concernant leur profil et leurs
        qualifications.
      </Text>
      <Text style={pdfStyles.text}>
        4. assurer le suivi du processus de recrutement en lien avec le client et les candidats.
      </Text>

      <Text style={pdfStyles.text}>
        elinoï organisera la mise en relation du client avec les candidats. elinoï s’engage à proposer au client des
        candidats, sans aucune discrimination ou considération de genre, d’origine, de nationalité, d’âge, d’handicap,
        etc.
      </Text>
      <Text style={pdfStyles.text}>
        elinoï ne sera pas tenue de vérifier les informations communiquées par les candidats.
      </Text>

      <Text style={pdfStyles.titleh3}>1.2 - Sur l’offre Dénicheur</Text>
      <Text style={pdfStyles.text}>
        i. présenter au client des candidats susceptibles de l’intéresser, sur une offre définie ou non.
      </Text>
      <Text style={pdfStyles.text}>
        ii. transmettre au client les informations communiquées par les candidats concernant leur profil et leurs
        qualifications.
      </Text>
      <Text style={pdfStyles.text}>
        iii. aucune obligation de suivi auprès du client au cours du process de recrutement, elinoï procédant à la seule
        mise en relation par email.
      </Text>

      <Text style={pdfStyles.titleh3}>1.3 - Pendant les 90 jours suivant le recrutement d’un Candidat</Text>
      <Text style={pdfStyles.text}>
        elinoï s’engage à faire ses meilleurs efforts à l’effet de présenter au client, de nouveaux candidats afin de
        remplacer un Candidat partant, si un Candidat recruté par le client devait démissionner du poste pour lequel il
        a été présenté au client par elinoï, ou se faire licencier par le client, dans les quatre-vingt dix (90) jours
        suivant la date de son entrée dans les effectifs du client.
      </Text>

      <Text style={pdfStyles.text}>
        Cette assistance ne fera l’objet d’aucune facturation complémentaire de la part d’elinoï si les Commissions qui
        lui étaient dues au titre du recrutement du Candidat partant ont déjà été versées par le client.
      </Text>

      <Text style={pdfStyles.titleh2}>2/ OBLIGATIONS SPÉCIFIQUES DU CLIENT</Text>
      <Text style={pdfStyles.text}>Le client s’engage à :</Text>

      <Text style={pdfStyles.text}>
        i. communiquer à elinoï toute offre ferme d’emploi adressée à un Candidat dans les deux (2) jours de l’envoi de
        cette offre au Candidat ;
      </Text>
      <Text style={pdfStyles.text}>
        ii. informer elinoï en cas de refus ou d’acceptation par un Candidat d’une offre d’emploi proposée par le client
        dans les deux (2) jours de ce refus ;
      </Text>
      <Text style={pdfStyles.text}>
        iii. Le client demeurera seul responsable de la vérification des qualifications des candidats.
      </Text>

      <Text style={pdfStyles.text}>
        Le client s’engage à respecter la règlementation applicable dans le cadre du recrutement des candidats, et
        notamment les dispositions de la loi sur l’égalité des chances.
      </Text>

      <Text style={pdfStyles.text}>
        Le client devra fournir à elinoï des informations exactes sur l’état d’avancement de son processus de
        recrutement, et plus généralement, répondre dans les meilleurs délais à toute demande de documents ou
        d’informations de la part d’elinoï.
      </Text>

      <Text style={pdfStyles.text}>Le client devra s’acquitter de toutes sommes dues au titre du Contrat.</Text>

      <Text style={pdfStyles.text}>
        Le client demeurera libre (i) de ne pas conclure de contrat de travail avec les candidats présentés, et (ii) de
        définir les conditions dans lesquelles il déciderait de conclure un contrat de travail avec les candidats, quand
        bien même celles-ci devaient être différentes de celles communiquées initialement à elinoï.
      </Text>

      <Text style={pdfStyles.titleh2}>3/ RÉMUNÉRATION D’elinoï</Text>
      <Text style={pdfStyles.titleh3}>3.1 - Frais d'accompagnement du contrat classique</Text>
      <Text style={pdfStyles.text}>
        En contrepartie de la diffusion de la page entreprise et des offres sur la plateforme, ELINOÏ aura le droit de
        percevoir une somme d’un montant de deux mille euros HT (2000 € HT) par an.
      </Text>
      <Text style={pdfStyles.text}>
        En contrepartie du travail de définition du besoin et de la publication de l'offre d’emploi proposée par le
        client, sur sa plateforme ouverte aux candidats, elinoï aura le droit de percevoir une somme d’un montant de
        cinq cents euros HT (500 € HT) pour les offres de l'accompagnement standard, et trois mille euros HT (3000 € HT)
        pour les offres de l'accompagnement avancé.
      </Text>

      <Text style={pdfStyles.text}>
        Ce montant forfaitaire sera facturé par elinoï dès publication de l’offre d’emploi sur la plateforme, et devra
        être payé, par virement bancaire ou chèque, dans un délai de trente (30) jours à compter de la date d’envoi, par
        elinoï, de ladite facture.
      </Text>

      <Text style={pdfStyles.text}>
        L’absence de règlement à l’issue de ce délai de trente (30) jours donnera lieu à l’application d’intérêts de
        retard au taux annuel de 5%.
      </Text>

      <Text style={pdfStyles.titleh3}>3.2 - Commissions du contrat classique</Text>
      <Text style={pdfStyles.text}>
        En cas d’embauche suivant l’offre de l'accompagnement standard, par le Client, de l’un des Candidats présentés
        par Elinoï, Elinoï percevra une commission dont le montant hors taxes s’élèvera à 23% du montant total du
        package annuel de rémunération (la « Commission »).
      </Text>

      <Text style={pdfStyles.text}>
        Le package annuel de rémunération du Candidat pris en compte pour la détermination du montant de la Commission
        comprend :
      </Text>
      <Text style={pdfStyles.text}>
        - le montant brut du salaire devant être versé annuellement au Candidat embauché ;
      </Text>
      <Text style={pdfStyles.text}>
        - le montant brut de toute rémunération variable telle que proposée au candidat lors de la formulation de
        l’offre Si ladite rémunération variable est fixée en fonction de l’atteinte d’objectifs, le montant pris en
        compte pour la détermination de la Commission sera établi en partant du principe que le candidat atteindra 100%
        des objectifs.
      </Text>

      <Text style={pdfStyles.text}>
        Si le Client ne peut pas définir en amont du recrutement le package annuel de rémunération du candidat, le
        client et ELINOÏ s’accorderont a posteriori sur le package offert annoncé au candidat en partant du principe que
        celui-ci atteindra si ce dernier venait à réaliser 100% des objectifs.
      </Text>

      <Text style={pdfStyles.text}>Exemple :</Text>
      <Text style={pdfStyles.text}>
        Si le Client fait une offre d’emploi au candidat avec un package de 60 000 euros dont 53 000 euros de salaire
        fixe + 5 000 euros de salaire variable (à 100% des objectifs atteints) + 2 000 euros de primes, alors la
        commission d’elinoï sera indexée sur les 60 000 euros de package.
      </Text>

      <Text style={pdfStyles.text}>
        Néanmoins, quel que soit le package annuel de rémunération proposé au Candidat, le montant minimum de la
        Commission s’élèvera à six mille cinq cent euros (6.500 €) HT.
      </Text>

      <Text style={pdfStyles.text}>
        Dans le cas d’un recrutement d'une offre de l'accompagnement avancé, Elinoï percevra une commission dont le
        montant hors taxes s’élèvera à 23% du montant total du package annuel de rémunération défini au titre du contrat
        de travail du Candidat (la « Commission »). Elinoï s’engage alors à remplacer un Candidat partant pendant les
        quatre-vingt-dix (90) jours suivant la date de son entrée dans les effectifs du Client.
      </Text>

      <Text style={pdfStyles.titleh3}>3.3 - Commissions du contrat dénicheur</Text>
      <Text style={pdfStyles.text}>
        Dans le cas d’un recrutement via la Newsletter ‘LE DÉNICHEUR', le montant hors taxes de la commission s’élèvera
        à 15% du montant total du package annuel de rémunération (la « Commission »).
      </Text>

      <Text style={pdfStyles.text}>
        Le package annuel de rémunération du Candidat pris en compte pour la détermination du montant de la Commission
        comprend :
      </Text>

      <Text style={pdfStyles.text}>
        - le montant brut du salaire devant être versé annuellement au Candidat embauché ;
      </Text>
      <Text style={pdfStyles.text}>
        - le montant brut de toute rémunération variable telle que proposée au candidat lors de la formulation de
        l’offre Si ladite rémunération variable est fixée en fonction de l’atteinte d’objectifs, le montant pris en
        compte pour la détermination de la Commission sera établi en partant du principe que le candidat atteindra 100%
        des objectifs.
      </Text>

      <Text style={pdfStyles.text}>
        Si le Client ne peut pas définir en amont du recrutement le package annuel de rémunération du candidat, le
        client et ELINOÏ s’accorderont a posteriori sur le package offert annoncé au candidat en partant du principe que
        celui-ci atteindra si ce dernier venait à réaliser 100% des objectifs.
      </Text>
      <Text style={pdfStyles.text}>Exemple :</Text>
      <Text style={pdfStyles.text}>
        Si le Client fait une offre d’emploi au candidat avec un package de 60 000 euros dont 53 000 euros de salaire
        fixe + 5 000 euros de salaire variable (à 100% des objectifs atteints) + 2 000 euros de primes, alors la
        commission d’elinoï sera indexée sur les 60 000 euros de package.
      </Text>
      <Text style={pdfStyles.text}>
        Néanmoins, quel que soit le package annuel de rémunération proposé au Candidat, le montant minimum de la
        Commission s’élèvera à cinq mille euros (5.000 €) HT.
      </Text>

      <Text style={pdfStyles.titleh3}>3.4 - Modalités de paiement des commissions</Text>
      <Text style={pdfStyles.text}>
        La Commission due à elinoï au titre de l’embauche d’un Candidat sera exigible dès le jour de conclusion du
        contrat de travail du Candidat (une promesse d’embauche valant pour contrat). elinoï fera parvenir la facture au
        titre de sa Commission à tout moment à compter de cette date.
      </Text>

      <Text style={pdfStyles.text}>
        La Commission devra être payée, par virement bancaire ou chèque, dans un délai de trente (30) jours à compter de
        la date d’envoi, par elinoï, de la facture relative à ladite Commission.
      </Text>

      <Text style={pdfStyles.text}>
        L’absence de règlement à l’issue de ce délai de trente (30) jours donnera lieu à l’application d’intérêts de
        retard au taux annuel de 5%.
      </Text>

      <Text style={pdfStyles.titleh3}>3.5 - Droit de suite</Text>
      <Text style={pdfStyles.text}>
        En cas de recrutement par le client d’un Candidat présenté par elinoï dans un délai de six (6) mois à compter de
        la résiliation, pour quelque cause que ce soit, du Contrat, elinoï aura droit au versement des commissions
        prévues par l’Article 4, nonobstant la résiliation des présentes.
      </Text>

      <Text style={pdfStyles.titleh2}>4/ FACULTÉ DE DÉDIT DU CLIENT</Text>
      <Text style={pdfStyles.text}>
        Cette clause ne s’applique pas dans le cas où le client recrutait par d’autres moyens (en interne ou via
        d’autres partenaires de recrutement) un candidat pour l’offre en question.
      </Text>

      <Text style={pdfStyles.text}>
        Dans l’hypothèse où le client renoncerait à rechercher un Candidat à l’effet de pourvoir le poste, ou venait à
        repousser cette recherche à une date ultérieure, le client pourra mettre fin de manière anticipée à la recherche
        de candidats via elinoï pour le poste, sous réserve du paiement, à elinoï, d’une indemnité déterminée comme suit
        :
      </Text>

      <View style={pdfStyles.table}>
        {/* TableHeader */}
        <View style={pdfStyles.tableRow}>
          <View style={[pdfStyles.tableCol, pdfStyles.tableColHeader]}>
            <Text style={pdfStyles.tableCell}>
              Package annuel de rémunération proposé dans l’offre initiale par le client (P)
            </Text>
          </View>
          <View style={[pdfStyles.tableCol, pdfStyles.tableColHeader]}>
            <Text style={pdfStyles.tableCell}>Indemnité de dédit</Text>
          </View>
        </View>
        {/* TableContent */}
        <View style={pdfStyles.tableRow}>
          <View style={pdfStyles.tableCol}>
            <Text style={pdfStyles.tableCell}>{"40 000 € < P < 50 000 €"}</Text>
          </View>
          <View style={pdfStyles.tableCol}>
            <Text style={pdfStyles.tableCell}>2000 € HT</Text>
          </View>
        </View>
        <View style={pdfStyles.tableRow}>
          <View style={pdfStyles.tableCol}>
            <Text style={pdfStyles.tableCell}>{"50 000 € < P < 60 000 €"}</Text>
          </View>
          <View style={pdfStyles.tableCol}>
            <Text style={pdfStyles.tableCell}>2400 € HT</Text>
          </View>
        </View>
        <View style={pdfStyles.tableRow}>
          <View style={pdfStyles.tableCol}>
            <Text style={pdfStyles.tableCell}>{"60 000 € < P < 80 000 €"}</Text>
          </View>
          <View style={pdfStyles.tableCol}>
            <Text style={pdfStyles.tableCell}>3500 € HT</Text>
          </View>
        </View>
        <View style={pdfStyles.tableRow}>
          <View style={pdfStyles.tableCol}>
            <Text style={pdfStyles.tableCell}>{"80 000 € < P < 150 000 €"}</Text>
          </View>
          <View style={pdfStyles.tableCol}>
            <Text style={pdfStyles.tableCell}>5000 € HT</Text>
          </View>
        </View>
      </View>

      <Text style={pdfStyles.text}>
        Le montant de l’indemnité sera déterminé sur la base du package de rémunération annuelle prévu par l’offre
        d’emploi communiquée par le client à elinoï en vue de sa transmission aux candidats.
      </Text>

      <Text style={pdfStyles.text}>
        Le package de rémunération annuelle est défini comme indiqué à l’Article 4.1 ci-avant (rémunération brute
        annuelle augmenté du montant des primes).
      </Text>

      <Text style={pdfStyles.titleh2}>5/ DURÉE DU CONTRAT</Text>
      <Text style={pdfStyles.text}>Le présent contrat est conclu pour une durée indéterminée.</Text>

      <Text style={pdfStyles.titleh2}>6/ RÉSILIATION ANTICIPÉE</Text>
      <Text style={pdfStyles.titleh3}>6.1</Text>
      <Text style={pdfStyles.text}>
        Le Contrat sera résiliable à tout moment par l’une ou l’autre des Parties, sous réserve de respecter un préavis
        de quinze (15) jours adressé par courrier recommandé ou par email avec accusé de lecture à l’autre Partie.
      </Text>

      <Text style={pdfStyles.titleh3}>6.2</Text>
      <Text style={pdfStyles.text}>
        Le présent Contrat pourra être résilié unilatéralement par le client, par lettre recommandée avec demande d’avis
        de réception avec effet immédiat en cas de rétractation de sa recherche de candidats pour le poste. A titre de
        rappel, en pareille situation, le client sera redevable de l’indemnité définie par l’Article 6 ci-avant.
      </Text>
      <Text style={pdfStyles.text}>
        La résiliation du Contrat, pour quelque cause que ce soit, ne portera pas atteinte aux droits d’elinoï au titre
        du présent Contrat, notamment au titre de son droit à percevoir toute indemnité de dédit en cas de résiliation
        du Contrat dans les conditions prévues à l’Article 6, ou toutes commissions prévues par l’Article 4 y compris au
        titre du droit de suite.
      </Text>

      <Text style={pdfStyles.titleh2}>7/ INCESSIBILITÉ DU CONTRAT</Text>
      <Text style={pdfStyles.text}>
        Le présent Contrat étant conclu intuitu personae, il ne pourra en aucun cas être cédé ou transféré, pas plus que
        les droits et obligations qui y figurent à quelque personne, et sous quelque forme que ce soit, par l’une ou
        l’autre des Parties, sans l’accord préalable et écrit de l’autre Partie.
      </Text>

      <Text style={pdfStyles.titleh2}>8/ INDÉPENDANCE</Text>
      <Text style={pdfStyles.titleh3}>8.1</Text>
      <Text style={pdfStyles.text}>
        Les Parties déclarent expressément qu’elles sont et demeureront, pendant toute la durée du Contrat, des
        partenaires commerciaux et professionnels indépendants.
      </Text>

      <Text style={pdfStyles.titleh3}>8.2</Text>
      <Text style={pdfStyles.text}>
        L’activité exercée par elinoï dans le cadre du présent Contrat sera indépendante et exclusive de tout mandat de
        recrutement. elinoï se limitera à mettre en relation le client avec les candidats susceptibles de répondre aux
        besoins du client sans pouvoir négocier ou conclure de contrat ou d’engagement avec ces candidats au nom et pour
        le compte du client. Il s’agit d’une condition déterminante du présent Contrat.
      </Text>

      <Text style={pdfStyles.titleh3}>8.3</Text>
      <Text style={pdfStyles.text}>
        Les Parties sont expressément convenues que le présent contrat n’entraîne aucune exclusivité au bénéfice
        d’elinoï.
      </Text>

      <Text style={pdfStyles.titleh2}>9/ COMPORTEMENT LOYAL ET DE BONNE FOI</Text>
      <Text style={pdfStyles.text}>
        Chaque Partie s’engage à toujours se comporter de bonne foi à l’égard de l’autre Partie, et notamment, à porter
        sans délai à la connaissance de l’autre Partie tout différend ou toute difficulté qu’il pourrait rencontrer dans
        le cadre de l’exécution du présent Contrat ou de ses relations avec les candidats.
      </Text>

      <Text style={pdfStyles.titleh2}>10/ DROIT DE CITATION</Text>
      <Text style={pdfStyles.text}>
        Le client autorise expressément à citer son nom parmi ses références clients, que ce soit sur son site internet
        ou sur tout autre support de communication.
      </Text>

      <Text style={pdfStyles.text}>
        La présente autorisation n’emporte aucun transfert de droits de propriété intellectuelle sur ledit logo au
        bénéfice d’elinoï.
      </Text>

      <Text style={pdfStyles.titleh2}>11/ DROIT APPLICABLE – COMPÉTENCE</Text>
      <Text style={pdfStyles.titleh3}>11.1</Text>
      <Text style={pdfStyles.text}>
        Le présent Contrat sera régi et interprété conformément aux dispositions du droit français.
      </Text>

      <Text style={pdfStyles.titleh3}>11.2</Text>
      <Text style={pdfStyles.text}>
        Tous les litiges auxquels le présent Contrat pourrait donner lieu, concernant tant sa validité, son
        interprétation, son exécution, sa résiliation, leurs conséquences et leurs suites seront soumis à la juridiction
        exclusive du Tribunal de Commerce de Paris.
      </Text>
      <Text style={pdfStyles.titleh1}>PARTIE III - GÉNÉRALITÉS</Text>
      <Text style={pdfStyles.titleh2}>1/ OBLIGATION ET RESPONSABILITÉS D'elinoï</Text>
      <Text style={pdfStyles.titleh3}>Accès aux services</Text>
      <Text style={pdfStyles.text}>
        elinoï exclut toutes garanties en matière de fiabilité, d'opportunité et de performance de ses Services. A cet
        égard, elinoï n’est tenue qu’à une obligation de moyens quant à l’accès aux Services et fera ses meilleurs
        efforts pour maintenir un accès continu aux Services.
      </Text>

      <Text style={pdfStyles.text}>
        elinoï aura la possibilité de suspendre ou d’interrompre l’accès aux Services notamment pour des raisons
        techniques (opérations de maintenance, mise à jour du logiciel etc.) ou éditoriales (mise à jour des contenus et
        des interfaces, ajout de nouvelles fonctionnalités etc.) ou pour toute autre raison.
      </Text>

      <Text style={pdfStyles.text}>
        En cas de suspension ou d’interruption des Services, bugs, virus, chevaux de Troie ou autres éléments similaires
        pouvant être transmis de l’Application, elinoï n’est soumise à aucune obligation d’information préalable des
        Utilisateurs et ne pourra être tenue pour responsable des dommages occasionnés par ces suspensions ou
        interruptions aussi bien à l’égard des Utilisateurs que de tous tiers.
      </Text>

      <Text style={pdfStyles.text}>
        La responsabilité d’elinoï ne saurait être engagée en cas d’inaccessibilité de tout ou partie des Services en
        raison de tout problème technique ou défaut de toute nature, tels que notamment :
      </Text>

      <Text style={pdfStyles.text}>- l'encombrement du réseau;</Text>
      <Text style={pdfStyles.text}>- une défaillance des fournisseurs d'accès Internet;</Text>
      <Text style={pdfStyles.text}>- une erreur humaine ou d'origine électrique;</Text>
      <Text style={pdfStyles.text}>- toute intervention malveillante;</Text>
      <Text style={pdfStyles.text}>- une défaillance et/ou un encombrement des liaisons téléphoniques;</Text>
      <Text style={pdfStyles.text}>- tous dysfonctionnements de logiciel ou de matériel, un cas de force majeure.</Text>
      <Text style={pdfStyles.text}>
        Les frais de télécommunications permettant d’accéder aux Services restent à la charge exclusive des
        Utilisateurs.
      </Text>
      <Text style={pdfStyles.text}>
        Les informations diffusées sur le SITE sont publiées dans un but informatif. elinoï met en avant des
        informations complètes et qualitatives sur le site mais n’est pas en mesure d’en assurer l’exactitude. elinoï
        n’est pas responsable des informations fausses qui seraient diffusées et ne garantit pas que l’utilisation du
        SITE ne porte atteinte aux tiers.
      </Text>

      <Text style={pdfStyles.text}>
        elinoï ne peut en aucun cas être tenue responsable si un Talent ne trouve pas d’emploi et/ou si l’emploi ne lui
        convient pas, ou si une start-up ne parvient pas à recruter ou à recruter un Talent qui ne lui donne pas
        satisfaction.
      </Text>

      <Text style={pdfStyles.titleh2}>2/ RESPONSABILITÉ D'elinoï</Text>
      <Text style={pdfStyles.text}>
        elinoï ne saurait être tenue pour responsable des dommages occasionnés au cours de la phase d’entretien avec la
        start-ups. En effet, les entretiens et contacts avec la start-up intervenant après la phase de recrutement
        effectuée par elinoï sont effectués sous la seule responsabilité des Utilisateurs.
      </Text>

      <Text style={pdfStyles.text}>
        elinoï ne pourra être tenue responsable des infractions ou tentatives d’infractions pénales commises par ses
        Utilisateurs par l’intermédiaire des Services et/ou des préjudices en résultant et notamment, sans que cette
        liste soit exhaustive, les faits ou tentatives de :
      </Text>

      <Text style={pdfStyles.text}>- prostitution;</Text>
      <Text style={pdfStyles.text}>- proxénétisme;</Text>
      <Text style={pdfStyles.text}>- escroquerie;</Text>
      <Text style={pdfStyles.text}>- atteintes à la vie privée;</Text>
      <Text style={pdfStyles.text}>- atteintes aux droits de propriété intellectuelle;</Text>
      <Text style={pdfStyles.text}>- usurpation d’identité.</Text>
      <Text style={pdfStyles.text}>
        À cet égard, les Utilisateurs renoncent à exercer tout recours contre la Société dans le cas de poursuites
        diligentées à leur encontre par un Utilisateur et/ou par un tiers, du fait de l'utilisation et/ou de
        l'exploitation illicite des Services.
      </Text>

      <Text style={pdfStyles.text}>
        elinoï met en œuvre tous les moyens à sa disposition pour garantir une sécurité optimale des données des
        Utilisateurs. Néanmoins, il lui est impossible de garantir la sécurité absolue des réseaux. elinoï ne saurait
        être tenue responsable en cas de piratage des données dès lors qu’elle s’est conformée aux obligations qu’elle
        s’était engagée à prendre dans les présentes CGU.
      </Text>

      <Text style={pdfStyles.text}>
        En effet, elinoï ne maîtrise pas les risques liés au fonctionnement d'internet et attire l'attention de
        l'Utilisateur sur l'existence d'éventuels risques en termes de pertes ponctuelles de données ou d'atteinte à la
        confidentialité des données transitant via ce réseau.
      </Text>

      <Text style={pdfStyles.text}>
        En acceptant ces présentes CGU, l’Utilisateur reconnaît qu’il est le seul responsable des contenus qu’il publie
        (messages, correspondances avec les start-ups) et qu’elinoï est un tiers aux correspondances et relations entre
        les Utilisateurs, ce qui exclut toute responsabilité à cet égard.
      </Text>

      <Text style={pdfStyles.text}>
        elinoï n’est pas responsable à l’égard des Utilisateurs et/ou de tous tiers de l’inexactitude des données
        fournies par les Utilisateurs avec qui ils sont entrés en relation.
      </Text>

      <Text style={pdfStyles.text}>
        L’Application n’est qu’un outil de mise en relation entre les Utilisateurs qui décident librement de se
        rencontrer réellement. elinoï ne garantit donc aucunement la réussite de ces mises en relation professionnelles.
      </Text>

      <Text style={pdfStyles.titleh2}>3/ LIENS HYPERTEXTE</Text>
      <Text style={pdfStyles.text}>
        Il est possible que le SITE propose des liens hypertextes vers des sites web édités et/ou gérés par des tiers.
      </Text>

      <Text style={pdfStyles.text}>
        Dans la mesure où aucun contrôle n'est exercé sur ces ressources externes, l'Utilisateur reconnaît que elinoï
        n'assume aucune responsabilité relative à la mise à disposition de ces ressources, et ne peut être tenue
        responsable quant à leur contenu, les publicités, les produits, services ou de toutes autres composantes
        figurant sur ou disponible depuis ces sites web ou ressources, ou des dommages ou pertes découlant de ces sites
        web ou ressources, même si lesdits sites web et ressources sont liés à des partenaires ou à des fournisseurs
        tiers de services d’elinoï.
      </Text>

      <Text style={pdfStyles.titleh3}>Garanties et obligations des utilisateurs</Text>
      <Text style={pdfStyles.titleh3}>exactitude des informations fournies à elinoï</Text>
      <Text style={pdfStyles.text}>
        Les Utilisateurs s’engagent lors de la création de leur Profil à ne délivrer à elinoï que des informations
        exactes, non erronées ni frauduleuses ainsi qu’à les mettre à jour (ex : expériences professionnelles, diplômes,
        centres d’intérêt, requête, etc).
      </Text>

      <Text style={pdfStyles.text}>
        La fourniture d’informations mensongères et/ou dans une intention frauduleuse pourront entraîner le prononcé des
        sanctions prévues à l’article 9 des présentes et donner lieu à des poursuites judiciaires.
      </Text>

      <Text style={pdfStyles.titleh2}>4/ POLITIQUE CONCERNANT LES PHOTOS DES TALENTS</Text>
      <Text style={pdfStyles.text}>
        Par la création de son Profil par le biais de LINKEDIN, le Talent accepte la publication de sa photo sur son
        Profil, qui est transmise sous son unique responsabilité.
      </Text>

      <Text style={pdfStyles.text}>Seule elinoï et la start-up pourra avoir accès aux photos.</Text>

      <Text style={pdfStyles.text}>La photo doit être de bonne qualité, et représenter distinctement le Talent.</Text>

      <Text style={pdfStyles.text}>Sont interdites :</Text>

      <Text style={pdfStyles.text}>
        - les photos contenant l’image de tiers, connus ou inconnus, sans leur autorisation;
      </Text>
      <Text style={pdfStyles.text}>- les photos de groupe;</Text>
      <Text style={pdfStyles.text}>- les photos contenant l’image de mineurs;</Text>
      <Text style={pdfStyles.text}>- les photos à caractère illicite, sexuel, obscène ou pornographique;</Text>
      <Text style={pdfStyles.text}>
        - les photos représentant des symboles racistes, politiquement radicaux, haineux, ou pédophiles;
      </Text>
      <Text style={pdfStyles.text}>- les photos non libres de droit.</Text>
      <Text style={pdfStyles.titleh3}>Comportements illicites des utilisateurs</Text>
      <Text style={pdfStyles.text}>
        Les Utilisateurs s’engagent à ne pas utiliser les Services à des fins illégales et à respecter les lois et
        règlements en vigueur ainsi que les droits des tiers.
      </Text>

      <Text style={pdfStyles.text}>
        Les Utilisateurs s’abstiennent donc de réaliser tous actes ou tentatives d’actes illégaux par l’intermédiaire
        des Services, notamment, et sans que cette liste soit exhaustives, les faits de :
      </Text>

      <Text style={pdfStyles.text}>- prostitution;</Text>
      <Text style={pdfStyles.text}>- proxénétisme;</Text>
      <Text style={pdfStyles.text}>- escroquerie;</Text>
      <Text style={pdfStyles.text}>- atteintes à la vie privée;</Text>
      <Text style={pdfStyles.text}>- atteintes aux droits de propriété intellectuelle;</Text>
      <Text style={pdfStyles.text}>- usurpation d’identité.</Text>

      <Text style={pdfStyles.titleh3}>Utilisation strictement personnelle des services</Text>
      <Text style={pdfStyles.text}>
        Les Utilisateurs s’engagent à utiliser le SITE uniquement à des fins professionnelles à l’exclusion notamment de
        toute utilisation à des fins commerciales, publicitaires, lucratives, électorales.
      </Text>

      <Text style={pdfStyles.text}>
        En conséquence, seules des personnes physiques peuvent s’inscrire sur l’Application pour devenir Talent, à
        l’exclusion de toutes personnes morales dotées ou non de la personnalité juridique (sociétés, associations,
        fondations, etc.).
      </Text>

      <Text style={pdfStyles.text}>
        Par ailleurs, les Utilisateurs s’engagent à ne pas utiliser les Services abusivement, notamment à ne pas
        diffuser de données ou photos à caractère illicite, obscène, pornographique ou politiquement radical, menacer ou
        harceler d'autres Utilisateurs, porter atteinte aux droits d’auteurs de tiers, envoyer des virus par le biais de
        Messages ou du SITE en tant que tel, intercepter des e-mails ou des messages et/ou tenter de les intercepter.
      </Text>

      <Text style={pdfStyles.titleh3}>Protection des données personnelles</Text>
      <Text style={pdfStyles.titleh3}>collecte et traitement loyaux</Text>
      <Text style={pdfStyles.text}>
        Pour s’inscrire et utiliser les Services du Site, les Utilisateurs fournissent à elinoï par l’intermédiaire de
        LINKEDIN ou par le formulaire « recruter », les données à caractère personnel suivantes :
      </Text>

      <Text style={[pdfStyles.text, pdfStyles.bold]}>Pour les Talents :</Text>

      <Text style={pdfStyles.text}>- nom;</Text>
      <Text style={pdfStyles.text}>- prénoms;</Text>
      <Text style={pdfStyles.text}>- adresse IP;</Text>
      <Text style={pdfStyles.text}>- date de naissance;</Text>
      <Text style={pdfStyles.text}>- photo;</Text>
      <Text style={pdfStyles.text}>- adresse email;</Text>
      <Text style={pdfStyles.text}>- numéro de téléphone;</Text>
      <Text style={pdfStyles.text}>- CV;</Text>
      <Text style={pdfStyles.text}>- informations sur la personnalité;</Text>
      <Text style={pdfStyles.text}>- emploi recherché.</Text>

      <Text style={[pdfStyles.text, pdfStyles.bold]}>Pour les start-ups :</Text>
      <Text style={pdfStyles.text}>- nom;</Text>
      <Text style={pdfStyles.text}>- prénoms;</Text>
      <Text style={pdfStyles.text}>- adresse email;</Text>
      <Text style={pdfStyles.text}>- fonction;</Text>
      <Text style={pdfStyles.text}>- dénomination sociale;</Text>
      <Text style={pdfStyles.text}>- numéro de téléphone;</Text>
      <Text style={pdfStyles.text}>- profil de Talent recherché.</Text>
      <Text style={pdfStyles.text}>
        Ces données sont fournies par les Utilisateurs, collectées et traitées par elinoï dans la mesure où leur
        collecte est indispensable au fonctionnement des Services.
      </Text>

      <Text style={pdfStyles.text}>
        Les données personnelles collectées ont un caractère privé et sont uniquement rendues accessibles à elinoï.
      </Text>

      <Text style={pdfStyles.text}>
        Les données personnelles à caractère privé sont protégées et ne sont donc pas accessibles aux autres
        Utilisateurs ou aux tiers.
      </Text>

      <Text style={pdfStyles.text}>
        Le SITE collectant des informations sur les Utilisateurs, il a fait l’objet d’une déclaration préalable auprès
        de la CNIL enregistrée sous le numéro 1982244v1.
      </Text>

      <Text style={pdfStyles.text}>
        elinoï respecte la législation en vigueur en matière de protection des données personnelles de ses Utilisateurs.
        Ainsi, conformément à la loi « informatique et libertés » du 6 janvier 1978 modifiée en 2004, les Utilisateurs
        bénéficient d’un droit d’accès et de rectification aux informations qui les concernent en s’adressant à l’une
        des adresses suivantes :
      </Text>

      <Text style={[pdfStyles.text, pdfStyles.bold]}>david(at)elinoi.com</Text>
      <Text style={[pdfStyles.text, pdfStyles.bold]}>elinoï – 106 rue de Richelieu - 75002 PARIS</Text>

      <Text style={pdfStyles.text}>
        Les données personnelles sont collectées pour répondre à une ou plusieurs des finalités suivantes :
      </Text>

      <Text style={pdfStyles.text}>- gérer le compte des utilisateurs (inscriptions et désabonnements);</Text>
      <Text style={pdfStyles.text}>- suivre les Talents et les start-ups partenaires; rester en contact;</Text>
      <Text style={pdfStyles.text}>- transmission de certaines des données aux start-ups qui recrutent;</Text>
      <Text style={pdfStyles.text}>
        - gérer l’accès à certains Services accessibles sur le SITE et leur utilisation;
      </Text>
      <Text style={pdfStyles.text}>
        - connaître le profil du Talent pour procéder le cas échéant à son recrutement;
      </Text>
      <Text style={pdfStyles.text}>
        - constituer un fichier de Talents inscrits, d’Utilisateurs, de clients et prospects;
      </Text>
      <Text style={pdfStyles.text}>
        adresser des newsletters et sollicitations. Dans le cas où l’Utilisateur ne le souhaiterait pas, elinoï lui
        donne la faculté de ne pas s’abonner à la newsletter en cochant ou non la case correspondante;
      </Text>
      <Text style={pdfStyles.text}>- élaborer des statistiques commerciales et de fréquentation de Site;</Text>
      <Text style={pdfStyles.text}>- respecter les obligations légales et réglementaires.</Text>

      <Text style={pdfStyles.titleh3}>Identité du responsable de la collecte de données</Text>
      <Text style={pdfStyles.text}>Le responsable de la collecte de données personnelles est la société elinoï.</Text>

      <Text style={pdfStyles.titleh3}> 5/ CONSERVATION DES DONNÉES</Text>
      <Text style={pdfStyles.text}>
        Conformément à l’article 6 de la loi sur la confiance dans l’économie numérique (LCEN) du 21 juin 2004, la
        Société est tenue de conserver les données relatives aux contenus hébergés pendant une durée de 1 (un) an à
        compter de leur création afin de les mettre, le cas échéant, à la disposition des autorités compétentes.
      </Text>

      <Text style={pdfStyles.text}>
        Conformément à l’article précité, les données fournies par les Utilisateurs lors de la création de leur Profil
        seront conservées pendant une durée de 1 (un) an à compter de la fermeture du compte ou de la résiliation des
        présentes CGU.
      </Text>

      <Text style={pdfStyles.text}>
        À sa demande, elinoï supprimera les données personnelles de l’Utilisateur, annulant de ce fait son inscription
        au SITE.
      </Text>

      <Text style={pdfStyles.titleh3}>6/ DIVULGATION DES DONNÉES</Text>
      <Text style={pdfStyles.text}>
        Le personnel de elinoï et les services chargés du contrôle (commissaire aux comptes notamment) auront accès aux
        données personnelles.
      </Text>

      <Text style={pdfStyles.text}>
        elinoï peut également appel à des tiers sous-traitants pour assurer la prestation de ses Services (par exemple,
        pour la maintenance, les statistiques, les audits, la détection des fraudes, le marketing et le développement).
        Ils ont un accès aux informations dans la mesure nécessaire pour effectuer ces tâches au nom d’elinoï, et sont
        tenus de ne pas les divulguer ni les utiliser à d’autres fins.
      </Text>

      <Text style={pdfStyles.text}>
        Pour respecter des exigences légales ou réglementaires, elinoï peut se voir contraindre de divulguer des
        informations personnelles des Utilisateurs. elinoï se réserve donc le droit de divulguer les informations de
        certains Utilisateurs pour respecter ses obligations légales, notamment en cas d’ordonnance des tribunaux,
        mandat, assignation ou toute autre procédure légale similaire.
      </Text>

      <Text style={pdfStyles.text}>
        elinoï se réserve également le droit de divulguer les informations de certains Utilisateurs aux autorités
        compétentes dès lors que cette transmission est considérée par ses services comme raisonnablement nécessaire
        pour prévenir un dommage matériel ou un préjudice financier imminent, ou encore une suspicion d’activités
        illégales.
      </Text>

      <Text style={pdfStyles.text}>
        Les données personnelles telles que régulièrement déclarées à la CNIL, pourront faire l’objet de cessions,
        locations ou échanges au bénéfice de tiers.
      </Text>

      <Text style={pdfStyles.text}>
        Lorsque l’Utilisateur choisit de communiquer des Données personnelles, il donne expressément son consentement
        pour la collecte et l’utilisation de celles-ci conformément à ce qui est énoncé dans les présentes CGU et à la
        législation en vigueur.
      </Text>

      <Text style={pdfStyles.titleh3}>7/ CONSEILS DE SÉCURITÉ</Text>
      <Text style={pdfStyles.text}>
        elinoï n’a pas pour obligation de vérifier la réalité de la prétendue identité de ses Utilisateurs et ne dispose
        pas des moyens techniques et/ou légaux le lui permettant. Les Utilisateurs sont par conséquent seuls
        responsables de la vérification de l’identité des personnes avant de les rencontrer réellement lors notamment
        d’un entretien d’embauche.
      </Text>

      <Text style={pdfStyles.text}>
        elinoï ne saurait être tenue responsable de tous dommages occasionnés lors d’un entretien entre un Talent et une
        start-up ou par la suite lors de leur collaboration.
      </Text>

      <Text style={pdfStyles.text}>
        elinoï pourra mettre fin, à tout moment et sans préavis, à l’accès de l’Utilisateur au Site, et/ou fermer son
        compte notamment si elle estime que ce dernier a enfreint les CGU, a agi de manière incompatible avec la lettre
        ou l’esprit de celles-ci ou a violé les droits de elinoï ou d’un tiers.
      </Text>

      <Text style={pdfStyles.text}>
        elinoï pourra également modifier le SITE ou en suspendre l’accès, sans préavis. elinoï ne saurait être tenue
        responsable, à l’égard des Utilisateurs ou à l’égard de tout tiers, des conséquences causées par une telle
        modification ou suspension.
      </Text>

      <Text style={pdfStyles.titleh3}>8/ VIOLATION DES CGU</Text>
      <Text style={pdfStyles.text}>
        Le non-respect par un Utilisateur des obligations mis à sa charge par les présentes CGU peut entraîner des
        sanctions telles que la suspension de l’accès aux Services ou la résiliation du Profil. En outre, certains
        comportements réalisés sur le SITE sont susceptibles d’entraîner des poursuites judiciaires tant pénales que
        civiles et elinoï pourra être amenée à dénoncer une violation de la loi aux autorités compétentes, ou encore
        entamer des poursuites judiciaires (civiles comme pénales) contre les Utilisateurs.
      </Text>

      <Text style={pdfStyles.titleh3}>9/ PROTECTION DES DROITS DE PROPRIÉTÉ INTELLECTUELLE</Text>
      <Text style={pdfStyles.text}>
        Les éléments reproduits sur le Site, visuels, graphismes, photos, textes, articles, logos, informations qui sont
        la propriété exclusive de l’Editeur, sont protégés par le droit d’auteur, le droit des marques et le droit des
        brevets.
      </Text>

      <Text style={pdfStyles.text}>
        Il est strictement interdit de reproduire tout ou partie de ce Site, à l’exception d’un usage strictement
        personnel et à de fins privées uniquement.
      </Text>

      <Text style={pdfStyles.text}>
        Toute reproduction et toute diffusion de ces éléments, sans autorisation écrite préalable de la Société,
        exposent les contrevenants à des poursuites judiciaires.
      </Text>

      <Text style={pdfStyles.titleh3}>10/ POLITIQUE RELATIVE AUX COOKIES</Text>
      <Text style={pdfStyles.text}>
        Lors de la consultation du Site, des cookies sont déposés sur l’ordinateur de l’Utilisateur soit par elinoï soit
        par des sociétés tierces. Les cookies sont des fichiers qui ont pour but de collecter des informations relatives
        à la navigation de l’Utilisateur. Ils sont gérés par le navigateur internet.
      </Text>

      <Text style={pdfStyles.text}>
        L’Utilisateur peut à tout moment choisir de désactiver ses cookies. Certains navigateurs peuvent également être
        paramétrés pour signaler les cookies qui sont déposés dans l’ordinateur de l’Utilisateur et lui demander s’il
        les accepte ou pas. Les cookies peuvent être acceptés ou refusés au cas par cas ou bien refusés
        systématiquement.
      </Text>

      <Text style={pdfStyles.text}>
        Si le navigateur est configuré de manière à refuser l'ensemble des cookies, l’Utilisateur ne pourra pas profiter
        d'une partie des services proposés sur le SITE. Afin de gérer les cookies au plus près de ses attentes
        l’Utilisateur est invité à paramétrer son navigateur en tenant compte de la finalité des différents cookies.
      </Text>

      <Text style={pdfStyles.titleh3}>11/ MODIFICATION DES CGU</Text>
      <Text style={pdfStyles.text}>
        La Société se réserve la possibilité, à tout moment, de modifier en tout ou partie ces Conditions Générales
        d’Utilisation. Les Utilisateurs sont invités à consulter régulièrement les CGU afin de prendre connaissance des
        changements apportés. Toutes modifications remplacent et annulent les précédentes stipulations.
      </Text>

      <Text style={pdfStyles.titleh3}>12/ RENSEIGNEMENTS — RÉCLAMATIONS</Text>
      <Text style={pdfStyles.text}>
        Toute précision relative à l'application des présentes CGU, toute demande d'information ou réclamation relative
        au fonctionnement des Services de elinoï sur internet doit être adressée par E-mail à l'adresse :
        david(at)elinoi.com ou par courrier à l'adresse : elinoï - 106 rue de Richelieu - 75002 PARIS.
      </Text>

      <Text style={pdfStyles.titleh3}>13/ AUTONOMIE DES CLAUSES</Text>
      <Text style={pdfStyles.text}>
        Si une partie quelconque des Conditions Générales d'Utilisation devait s'avérer illégale, invalide ou
        inapplicable pour quelque raison que ce soit, le terme ou les termes en question seraient déclarés inexistants
        et les termes restants garderaient toute leur force et leur portée et continueraient à être applicables. Les
        termes déclarés inexistants seront remplacés par des termes qui se rapprocheront le plus du contenu de la clause
        annulée.
      </Text>

      <Text style={pdfStyles.text}>
        En cas de contradiction entre la version en langue étrangère des présentes CGU et la version française, cette
        dernière prévaudra.
      </Text>

      <Text style={pdfStyles.titleh3}>14/ DROIT APPLICABLE</Text>
      <Text style={pdfStyles.text}>
        Les présentes CGU sont soumises à la loi française et au droit européen en vigueur grâce à l’application directe
        du Règlement européen 2016/ 679 sur le traitement des données à caractère personnel.
      </Text>

      <Text style={pdfStyles.text}>
        Tout litige né de l’exécution des présentes CGU sera soumis aux juridictions compétentes sur le territoire
        français.
      </Text>
    </Page>
  </Document>
);

export const CGU: React.FC<RouteComponentProps> = (props: RouteComponentProps) => {
  const navRef = useRef<MiniSignalBinding>();
  const pageRef = useRef(null);
  const delay = 200;

  useEffect(() => {
    onInitPageLoadTracking();
    enterAnimation();
    eventDispatcher.trigger("_initComponent", { delay });
    navRef.current = eventDispatcher.on("_navigate", exitAnimation);

    return () => {
      if (navRef.current) {
        eventDispatcher.off("_navigate", navRef.current);
      }
    };
  }, []);

  const exitAnimation = (d: NavigateData) => {
    const tl = anime.timeline({
      complete: () => {
        d.callback();
      },
      duration: 600,
      easing: "easeInOutExpo",
    });

    tl.add({
      opacity: [1, 0],
      targets: pageRef.current,
    });
  };
  const enterAnimation = () => {
    const tl = anime.timeline({
      delay,
      duration: 400,
      easing: "linear",
    });

    tl.add({
      opacity: [0, 1],
      targets: pageRef.current,
    });
  };

  return (
    <>
      <SEO
        meta={[
          {
            property: "og:url",
            content: location.href,
          },
        ]}
        title="elinoï - Conditions générales d'utilisation"
      />
      <div style={{ opacity: 0 }} className={global.pageWrapper} ref={pageRef}>
        <div className={styles.introWrapper}>
          <div className={styles.outlineLogoContainer}>
            <LogoSmallOutline />
          </div>
          <div className={styles.introContainer}>
            <div className={cx(styles.halfCircleContainer, global.container, global.medium, global.right)}>
              <div className={styles.halfCircle}></div>
            </div>
            <h2 className={styles.title} ref={React.createRef()}>
              <span>
                <strong>Conditions</strong>
              </span>
              <span>générales</span>
              <span>
                <strong>d'utilisation</strong>
              </span>
            </h2>
          </div>
        </div>

        <div className={cx(global.container, global.small)}>
          <div className={styles.cguContainer}>
            <p>
              Le présent document constitue les Conditions Générales d’Utilisation du site www.elinoi.com et plus
              généralement, du service fourni par elinoï.
              <br />
              Veuillez noter que les Conditions Générales d’elinoï sont susceptibles d’être modifiées à tout moment :
              vous êtes donc invité à les consulter régulièrement.
            </p>
            <div className={styles.introCgu}>
              <p className={styles.upperCase}>
                En complétant le processus d’inscription et cliquant sur « j’accepte », vous déclarez que :
              </p>
              <p className={styles.upperCase}>
                (1) vous avez lu, compris et accepte d’être lié par les présentes conditions generales d’utilisation ;
              </p>
              <p className={styles.upperCase}>(2) vous avez l’âge légal requis pour conclure un contrat avec elinoi,</p>
              <p className={styles.upperCase}>
                Et (3) vous êtes habilité à conclure un engagement de manière personnelle, ou pour le compte de la
                société mentionnée lors de votre inscription.
              </p>
              <p className={styles.upperCase}>
                Dans le cas où vous n’accepteriez pas d’être lié par les présentes conditions générales d’utilisation,
                vous ne seriez pas en mesure d’accéder au service.
              </p>
            </div>

            <h4>Définitions</h4>
            <ul>
              <li>
                <span className={cx(styles.upperCase, styles.strong)}>Site</span> : désigne l’interface{" "}
                <span className={styles.upperCase}>Web</span> accessible depuis le monde entier à l’adresse URL
                www.elinoi.com
              </li>
              <li>
                <span className={cx(styles.upperCase, styles.strong)}>elinoï</span> : désigne la société{" "}
                <span className={styles.upperCase}>elinoï</span>, SAS inscrite au RCS de PARIS sous le numéro 799 174
                701 en date du 18 décembre 2013, situé au 106 rue de Richelieu - 75002 PARIS
              </li>
              <li>
                <span className={cx(styles.upperCase, styles.strong)}>Profil</span> : désigne le compte créé lors de
                l’inscription par l’Utilisateur, accessible par <span className={styles.upperCase}>Elinoi</span> et
                comportant les noms, prénom, date de naissance, adresse IP et les informations relatives au Talent
              </li>
              <li>
                <span className={cx(styles.upperCase, styles.strong)}>Talent</span> : désigne une personne à la
                recherche d’un emploi dans une start-up, s’étant connectée au{" "}
                <span className={styles.upperCase}>Site</span> par le biais de{" "}
                <span className={styles.upperCase}>Linkedin</span> ou avec son adresse email et qui a été acceptée dans
                le <span className={styles.upperCase}>Club des talents</span> par{" "}
                <span className={styles.upperCase}>elinoï</span> après validation de son profil dont la confirmation lui
                aura été communiquée par email
              </li>
              <li>
                <span className={cx(styles.upperCase, styles.strong)}>Start up</span> : désigne toutes start-ups
                souhaitant recruter et ayant pris contact avec <span className={styles.upperCase}>elinoï</span> sur le{" "}
                <span className={styles.upperCase}>Site</span> via le formulaire de contact
              </li>
              <li>
                <span className={cx(styles.upperCase, styles.strong)}>Utilisateur (S)</span> : désigne à la fois les
                Talents et les start-ups qui se sont connectées aux Services
              </li>
            </ul>
            <p>
              L’ensemble des termes employés avec une majuscule aux présentes auront le même sens et la même portée que
              ceux qui leur ont été donnés au présent article.
            </p>

            <h3>Relation contractuelle et objet</h3>
            <p>
              Les présentes conditions d’utilisation (les « CGU ») régissent l’utilisation par l’Utilisateur depuis tout
              pays du site Web <span className={styles.upperCase}>elinoï</span> disponible à l’adresse URL suivante :
              www.elinoi.com, de son contenu et des Services (les « Services ») mis à disposition par la société{" "}
              <span className={styles.upperCase}>elinoï</span> SAS, au capital social de 1.000 euros, immatriculée au
              RCS de PARIS sous le numéro 799 174 701 et dont le siège social est situé au 106 rue de Richelieu - 75002
              PARIS, ci-après dénommée « <span className={styles.upperCase}>elinoï</span> ».
            </p>
            <p>
              En accédant aux Services et en les utilisant, l’Utilisateur accepte d’être lié par les présentes CGU qui
              établissent une relation contractuelle entre lui et <span className={styles.upperCase}>elinoï</span>. Si
              l’Utilisateur n’accepte pas les présentes CGU, il ne pourra pas accéder aux Services ni les utiliser.
            </p>
            <p>
              Des dispositions supplémentaires peuvent s’appliquer à certains Services, notamment des règles spécifiques
              à un événement particulier ou à une activité particulière, et lesdites dispositions supplémentaires seront
              communiquées à l’Utilisateur dans le cadre des Services concernés. Aux fins des Services concernés, les
              dispositions supplémentaires viennent s’ajouter aux CGU, desquelles elles doivent être réputées faire
              partie. Les dispositions supplémentaires prévaudront sur les présentes Conditions en cas d’incompatibilité
              dans le cadre des Services concernés.
            </p>

            <h2>Partie I - Dispositions relatives aux Talents</h2>
            <h3>1/ Présentation du site et des services</h3>
            <p>
              Le <span className={styles.upperCase}>Site</span> est un site internet de recrutement professionnel
              mettant en relation des Talents à la recherche d’un emploi et des start-ups qui cherchent à recruter.
            </p>
            <p>
              Le <span className={styles.upperCase}>Site</span> propose aux Utilisateurs les Services suivants :
            </p>
            <ul>
              <li>
                se connecter au <span className={styles.upperCase}>Site</span> et créer son Profil par le biais de{" "}
                <span className={styles.upperCase}>Linkedin</span> ou de son adresse email;
              </li>
              <li>
                pour le Talent : envoyer à <span className={styles.upperCase}>elinoï</span> son nom, prénom, date de
                naissance et des réponses à des questions posées par <span className={styles.upperCase}>elinoï</span>{" "}
                permettant d’en savoir plus sur la personnalité du Talent et sur son job idéal;
              </li>
              <li>être intégré dans l'incubateur de Talents si le profil professionnel correspond;</li>
              <li>se voir présenter des opportunités professionnelles pour les Talents;</li>
              <li>
                se voir présenter des candidatures pour les start-ups, après avoir pris contact avec{" "}
                <span className={styles.upperCase}>elinoï</span> via le formulaire de contact présent sur le Site;
              </li>
              <li>
                être contacté par le recruteur <span className={styles.upperCase}>elinoï</span> par email ou directement
                par téléphone;
              </li>
              <li>l’envoi d’une newsletter;</li>
              <li>des évènements.</li>
            </ul>
            <p>
              Le <span className={styles.upperCase}>Site</span> est réservé à des Utilisateurs majeurs. En conséquence,
              les personnes âgées de moins de 18 ans ne pourront pas s’inscrire et devenir Utilisateurs.
            </p>

            <h3>2/ Inscription</h3>
            <p>
              Le Talent pourra s'inscrire via <span className={styles.upperCase}>Linkedin</span>.
            </p>
            <p>
              Afin de faciliter l’inscription aux Services et la création du Profil Talent, le{" "}
              <span className={styles.upperCase}>Site</span> utilise le service{" "}
              <span className={styles.upperCase}>Linkedin</span> ce qui lui permet de récupérer des données publiées sur{" "}
              <span className={styles.upperCase}>LINKEDIN</span> telles que :
            </p>
            <ul>
              <li>le nom;</li>
              <li>les prénoms;</li>
              <li>la photo;</li>
              <li>
                la date de naissance (à partir de laquelle le <span className={styles.upperCase}>Site</span> déterminera
                l’âge de l’Utilisateur);
              </li>
              <li>les expériences professionnelles.</li>
            </ul>
            <p>
              <span className={styles.upperCase}>elinoï</span> s’engage à ne rien publier sur le compte{" "}
              <span className={styles.upperCase}>Linkedin</span> des Talents.
            </p>

            <h4>Sécurité</h4>
            <p>
              L’Utilisateur est responsable du maintien de la confidentialité des détails de son compte, et est
              pleinement responsable de toutes les activités qui se produisent sur son compte.
            </p>

            <h4>Désactivation du profil</h4>
            <p>
              Le Talent peut désactiver son Profil via le <span className={styles.upperCase}>Site</span> s’il ne
              souhaite plus être visible dans le fichier d’
              <span className={styles.upperCase}>elinoï</span>.
            </p>
            <p>
              En cas de réactivation du Compte dans la période de 2 ans suite à la désactivation du Profil, les données
              conservées seront réaffectées à son Profil et le profil redevient visible.
            </p>

            <h3>3/ Présentation des services</h3>
            <h4>Sélection du talent</h4>
            <p>
              Le Talent soumet son profil à <span className={styles.upperCase}>elinoï</span> après s’être inscrit sur le{" "}
              <span className={styles.upperCase}>Site</span> et avoir répondu aux questions et aux demandes
              d’informations lors de la création du Profil.
              <br />
              Il reçoit alors un email de validation sur l’adresse email indiquée lui confirmant son inscription dans
              l’incubateur de talents ou lui indiquant que le profil ne correspond pas.
            </p>

            <h4>Entrée dans l’incubateur de talents</h4>
            <p>
              Dans le cas où son entrée dans l’incubateur de talents est validée par{" "}
              <span className={styles.upperCase}>elinoï</span>, le Talent peut alors se voir proposer des postes
              correspondants à ses attentes. elinoï prendra alors contact avec le Talent par email à l’adresse email
              indiquée et/ou par téléphone.
            </p>

            <h4>Parrainage</h4>
            <p>
              Le Talent pourra inviter d'autres Talents à rejoindre l'incubateur et gagner une prime de 300 € s'ils sont
              recrutés en indiquant l’adresse email de la personne concernée.
            </p>

            <h2>Partie II - Dispositions relatives aux recruteurs</h2>
            <h3>1/ Obligations d’elinoï</h3>

            <h4>1.1 - Sur l’offre classique</h4>

            <p>
              Pendant la phase d’accompagnement à la recherche de candidats, selon la formule classique (ie hors offre
              Dénicheur)
              <br />
              elinoï s’engage à faire ses meilleurs efforts à l’effet de :
            </p>
            <ol>
              <li>diffuser sur le job board elinoï la fiche entreprises et la/les fiche(s) offre(s)</li>
              <li>
                présenter au client des candidats susceptibles de répondre aux Qualifications définies par le client
                pour chacun des postes ;
              </li>
              <li>
                transmettre au client les informations communiquées par les candidats concernant leur profil et leurs
                qualifications ;
              </li>
              <li>assurer le suivi du processus de recrutement en lien avec le client et les candidats.</li>
            </ol>

            <p>
              elinoï organisera la mise en relation du client avec les candidats. elinoï s’engage à proposer au client
              des candidats, sans aucune discrimination ou considération de genre, d’origine, de nationalité, d’âge,
              d’handicap, etc.
            </p>
            <p>elinoï ne sera pas tenue de vérifier les informations communiquées par les candidats.</p>

            <h4>1.2 - Sur l’offre Dénicheur</h4>
            <ol>
              <li>présenter au client des candidats susceptibles de l’intéresser, sur une offre définie ou non;</li>
              <li>
                transmettre au client les informations communiquées par les candidats concernant leur profil et leurs
                qualifications ;
              </li>
              <li>
                aucune obligation de suivi auprès du client au cours du process de recrutement,{" "}
                <span className={styles.upperCase}>elinoï</span> procédant à la seule mise en relation par email.
              </li>
            </ol>

            <h4>1.3 - Pendant les 90 jours suivant le recrutement d’un Candidat</h4>
            <p>
              elinoï s’engage à faire ses meilleurs efforts à l’effet de présenter au client, de nouveaux candidats afin
              de remplacer un Candidat partant, si un Candidat recruté par le client devait démissionner du poste pour
              lequel il a été présenté au client par elinoï, ou se faire licencier par le client, dans les quatre-vingt
              dix (90) jours suivant la date de son entrée dans les effectifs du client.
            </p>
            <p>
              Cette assistance ne fera l’objet d’aucune facturation complémentaire de la part d’elinoï si les
              Commissions qui lui étaient dues au titre du recrutement du Candidat partant ont déjà été versées par le
              client.
            </p>

            <h3>2/ Obligations spécifiques du client</h3>

            <p>Le client s’engage à :</p>
            <ol>
              <li>
                communiquer à elinoï toute offre ferme d’emploi adressée à un Candidat dans les deux (2) jours de
                l’envoi de cette offre au Candidat ;
              </li>
              <li>
                informer elinoï en cas de refus ou d’acceptation par un Candidat d’une offre d’emploi proposée par le
                client dans les deux (2) jours de ce refus ;
              </li>
            </ol>

            <p>Le client demeurera seul responsable de la vérification des qualifications des candidats.</p>
            <p>
              Le client s’engage à respecter la règlementation applicable dans le cadre du recrutement des candidats, et
              notamment les dispositions de la loi sur l’égalité des chances.
            </p>
            <p>
              Le client devra fournir à elinoï des informations exactes sur l’état d’avancement de son processus de
              recrutement, et plus généralement, répondre dans les meilleurs délais à toute demande de documents ou
              d’informations de la part d’elinoï.
            </p>
            <p>Le client devra s’acquitter de toutes sommes dues au titre du Contrat.</p>
            <p>
              Le client demeurera libre (i) de ne pas conclure de contrat de travail avec les candidats présentés, et
              (ii) de définir les conditions dans lesquelles il déciderait de conclure un contrat de travail avec les
              candidats, quand bien même celles-ci devaient être différentes de celles communiquées initialement à
              elinoï.
            </p>

            <h3>3/ Rémunération d’elinoï</h3>
            <h4>3.1 - Frais d'accompagnement du contrat classique</h4>
            <p>
              En contrepartie de la diffusion de la page entreprise et des offres sur la plateforme, ELINOÏ aura le
              droit de percevoir une somme d’un montant de deux mille euros HT (2000 € HT) par an.
            </p>
            <p>
              En contrepartie du travail de définition du besoin et de la publication de l'offre d’emploi proposée par
              le client, sur sa plateforme ouverte aux candidats, elinoï aura le droit de percevoir une somme d’un
              montant de cinq cents euros HT (500 € HT) pour les offres de l'accompagnement standard, et trois mille
              euros HT (3000 € HT) pour les offres de l'accompagnement avancé.
            </p>
            <p>
              Ce montant forfaitaire sera facturé par elinoï dès publication de l’offre d’emploi sur la plateforme, et
              devra être payé, par virement bancaire ou chèque, dans un délai de trente (30) jours à compter de la date
              d’envoi, par elinoï, de ladite facture.
            </p>
            <p>
              L’absence de règlement à l’issue de ce délai de trente (30) jours donnera lieu à l’application d’intérêts
              de retard au taux annuel de 5%.
            </p>

            <h4>3.2 - Commissions du contrat classique</h4>
            <p>
              En cas d’embauche suivant l’offre de l'accompagnement standard, par le Client, de l’un des Candidats
              présentés par Elinoï, Elinoï percevra une commission dont le montant hors taxes s’élèvera à 23% du montant
              total du package annuel de rémunération (la « Commission »).
            </p>
            <p>
              Le package annuel de rémunération du Candidat pris en compte pour la détermination du montant de la
              Commission comprend :
            </p>
            <ol>
              <li>le montant brut du salaire devant être versé annuellement au Candidat embauché ;</li>
              <li>
                le montant brut de toute rémunération variable telle que proposée au candidat lors de la formulation de
                l’offre Si ladite rémunération variable est fixée en fonction de l’atteinte d’objectifs, le montant pris
                en compte pour la détermination de la Commission sera établi en partant du principe que le candidat
                atteindra 100% des objectifs.
              </li>
            </ol>
            <p>
              Si le Client ne peut pas définir en amont du recrutement le package annuel de rémunération du candidat, le
              client et ELINOÏ s’accorderont a posteriori sur le package offert annoncé au candidat en partant du
              principe que celui-ci atteindra si ce dernier venait à réaliser 100% des objectifs.
            </p>
            <p>Exemple :</p>
            <p>
              Si le Client fait une offre d’emploi au candidat avec un package de 60 000 euros dont 53 000 euros de
              salaire fixe + 5 000 euros de salaire variable (à 100% des objectifs atteints) + 2 000 euros de primes,
              alors la commission d’elinoï sera indexée sur les 60 000 euros de package.
            </p>
            <p>
              Néanmoins, quel que soit le package annuel de rémunération proposé au Candidat, le montant minimum de la
              Commission s’élèvera à six mille cinq cent euros (6.500 €) HT.
            </p>
            <p>
              Dans le cas d’un recrutement d'une offre de l'accompagnement avancé, Elinoï percevra une commission dont
              le montant hors taxes s’élèvera à 23% du montant total du package annuel de rémunération défini au titre
              du contrat de travail du Candidat (la « Commission »). Elinoï s’engage alors à remplacer un Candidat
              partant pendant les quatre-vingt-dix (90) jours suivant la date de son entrée dans les effectifs du
              Client.
            </p>
            <h4>3.3 - Commissions du contrat dénicheur</h4>
            <p>
              Dans le cas d’un recrutement via la Newsletter ‘LE DÉNICHEUR', le montant hors taxes de la commission
              s’élèvera à 15% du montant total du package annuel de rémunération (la « Commission »).
            </p>
            <p>
              Le package annuel de rémunération du Candidat pris en compte pour la détermination du montant de la
              Commission comprend :
            </p>

            <ol>
              <li>le montant brut du salaire devant être versé annuellement au Candidat embauché ;</li>
              <li>
                le montant brut de toute rémunération variable telle que proposée au candidat lors de la formulation de
                l’offre Si ladite rémunération variable est fixée en fonction de l’atteinte d’objectifs, le montant pris
                en compte pour la détermination de la Commission sera établi en partant du principe que le candidat
                atteindra 100% des objectifs.
              </li>
            </ol>

            <p>
              Si le Client ne peut pas définir en amont du recrutement le package annuel de rémunération du candidat, le
              client et ELINOÏ s’accorderont a posteriori sur le package offert annoncé au candidat en partant du
              principe que celui-ci atteindra si ce dernier venait à réaliser 100% des objectifs.
            </p>

            <p>Exemple : </p>
            <p>
              Si le Client fait une offre d’emploi au candidat avec un package de 60 000 euros dont 53 000 euros de
              salaire fixe + 5 000 euros de salaire variable (à 100% des objectifs atteints) + 2 000 euros de primes,
              alors la commission d’elinoï sera indexée sur les 60 000 euros de package.
            </p>
            <p>
              Néanmoins, quel que soit le package annuel de rémunération proposé au Candidat, le montant minimum de la
              Commission s’élèvera à cinq mille euros (5.000 €) HT.
            </p>

            <h4>3.4 - Modalités de paiement des commissions</h4>
            <p>
              La Commission due à elinoï au titre de l’embauche d’un Candidat sera exigible dès le jour de conclusion du
              contrat de travail du Candidat (une promesse d’embauche valant pour contrat). elinoï fera parvenir la
              facture au titre de sa Commission à tout moment à compter de cette date.
            </p>
            <p>
              La Commission devra être payée, par virement bancaire ou chèque, dans un délai de trente (30) jours à
              compter de la date d’envoi, par elinoï, de la facture relative à ladite Commission.
            </p>
            <p>
              L’absence de règlement à l’issue de ce délai de trente (30) jours donnera lieu à l’application d’intérêts
              de retard au taux annuel de 5%.
            </p>

            <h4>3.5 - Droit de suite</h4>
            <p>
              En cas de recrutement par le client d’un Candidat présenté par elinoï dans un délai de six (6) mois à
              compter de la résiliation, pour quelque cause que ce soit, du Contrat, elinoï aura droit au versement des
              commissions prévues par l’Article 4, nonobstant la résiliation des présentes.
            </p>

            <h3>4/ Faculté de dédit du client</h3>
            <p>
              Cette clause ne s’applique pas dans le cas où le client recrutait par d’autres moyens (en interne ou via
              d’autres partenaires de recrutement) un candidat pour l’offre en question.
            </p>
            <p>
              Dans l’hypothèse où le client renoncerait à rechercher un Candidat à l’effet de pourvoir le poste, ou
              venait à repousser cette recherche à une date ultérieure, le client pourra mettre fin de manière anticipée
              à la recherche de candidats via elinoï pour le poste, sous réserve du paiement, à elinoï, d’une indemnité
              déterminée comme suit :{" "}
            </p>

            <table>
              <thead>
                <tr>
                  <th scope="col">Package annuel de rémunération proposé dans l’offre initiale par le client (P)</th>
                  <th scope="col">Indemnité de dédit</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>40 000 € &lt; P &lt; 50 000 €</td>
                  <td>2000 €HT</td>
                </tr>
                <tr>
                  <td>50 000 € &lt; P &lt; 60 000 €</td>
                  <td>2500 €HT</td>
                </tr>
                <tr>
                  <td>60 000 € &lt; P &lt; 80 000 €</td>
                  <td>3500 €HT</td>
                </tr>
                <tr>
                  <td>80 000 € &lt; P &lt; 150 000 €</td>
                  <td>5000 €HT</td>
                </tr>
              </tbody>
            </table>

            <p>
              Le montant de l’indemnité sera déterminé sur la base du package de rémunération annuelle prévu par l’offre
              d’emploi communiquée par le client à elinoï en vue de sa transmission aux candidats.
            </p>
            <p>
              Le package de rémunération annuelle est défini comme indiqué à l’Article 4.1 ci-avant (rémunération brute
              annuelle augmenté du montant des primes).{" "}
            </p>

            <h3>5/ Durée du Contrat</h3>
            <p>Le présent contrat est conclu pour une durée indéterminée.</p>

            <h3>6/ Résiliation anticipée</h3>
            <h4>6.1</h4>
            <p>
              Le Contrat sera résiliable à tout moment par l’une ou l’autre des Parties, sous réserve de respecter un
              préavis de quinze (15) jours adressé par courrier recommandé ou par email avec accusé de lecture à l’autre
              Partie.
            </p>

            <h4>6.2</h4>
            <p>
              Le présent Contrat pourra être résilié unilatéralement par le client, par lettre recommandée avec demande
              d’avis de réception avec effet immédiat en cas de rétractation de sa recherche de candidats pour le poste.
              A titre de rappel, en pareille situation, le client sera redevable de l’indemnité définie par l’Article 6
              ci-avant.
              <br />
              La résiliation du Contrat, pour quelque cause que ce soit, ne portera pas atteinte aux droits d’elinoï au
              titre du présent Contrat, notamment au titre de son droit à percevoir toute indemnité de dédit en cas de
              résiliation du Contrat dans les conditions prévues à l’Article 6, ou toutes commissions prévues par
              l’Article 4 y compris au titre du droit de suite.
            </p>

            <h3>7/ Incessibilité du contrat</h3>
            <p>
              Le présent Contrat étant conclu intuitu personae, il ne pourra en aucun cas être cédé ou transféré, pas
              plus que les droits et obligations qui y figurent à quelque personne, et sous quelque forme que ce soit,
              par l’une ou l’autre des Parties, sans l’accord préalable et écrit de l’autre Partie.
            </p>

            <h3>8/ Indépendance</h3>
            <h4>8.1</h4>
            <p>
              Les Parties déclarent expressément qu’elles sont et demeureront, pendant toute la durée du Contrat, des
              partenaires commerciaux et professionnels indépendants.{" "}
            </p>

            <h4>8.2</h4>
            <p>
              L’activité exercée par elinoï dans le cadre du présent Contrat sera indépendante et exclusive de tout
              mandat de recrutement. elinoï se limitera à mettre en relation le client avec les candidats susceptibles
              de répondre aux besoins du client sans pouvoir négocier ou conclure de contrat ou d’engagement avec ces
              candidats au nom et pour le compte du client. Il s’agit d’une condition déterminante du présent Contrat.
            </p>

            <h4>8.3</h4>
            <p>
              Les Parties sont expressément convenues que le présent contrat n’entraîne aucune exclusivité au bénéfice
              d’elinoï.
            </p>

            <h3>9/ Comportement loyal et de bonne foi</h3>
            <p>
              Chaque Partie s’engage à toujours se comporter de bonne foi à l’égard de l’autre Partie, et notamment, à
              porter sans délai à la connaissance de l’autre Partie tout différend ou toute difficulté qu’il pourrait
              rencontrer dans le cadre de l’exécution du présent Contrat ou de ses relations avec les candidats.
            </p>

            <h3>10/ Droit de citation</h3>
            <p>
              Le client autorise expressément à citer son nom parmi ses références clients, que ce soit sur son site
              internet ou sur tout autre support de communication.
            </p>
            <p>
              La présente autorisation n’emporte aucun transfert de droits de propriété intellectuelle sur ledit logo au
              bénéfice d’elinoï.
            </p>

            <h3>11/ Droit applicable – Compétence</h3>
            <h4>11.1</h4>
            <p>Le présent Contrat sera régi et interprété conformément aux dispositions du droit français.</p>

            <h4>11.2</h4>
            <p>
              Tous les litiges auxquels le présent Contrat pourrait donner lieu, concernant tant sa validité, son
              interprétation, son exécution, sa résiliation, leurs conséquences et leurs suites seront soumis à la
              juridiction exclusive du Tribunal de Commerce de Paris.
            </p>

            <h2>Partie III - Généralités</h2>
            <h3>1/ Obligation et responsabilités d'elinoï</h3>

            <h4>Accès aux services</h4>
            <p>
              <span className={styles.upperCase}>elinoï</span> exclut toutes garanties en matière de fiabilité,
              d'opportunité et de performance de ses Services. A cet égard,{" "}
              <span className={styles.upperCase}>elinoï</span> n’est tenue qu’à une obligation de moyens quant à l’accès
              aux Services et fera ses meilleurs efforts pour maintenir un accès continu aux Services.
            </p>
            <p>
              <span className={styles.upperCase}>elinoï</span> aura la possibilité de suspendre ou d’interrompre l’accès
              aux Services notamment pour des raisons techniques (opérations de maintenance, mise à jour du logiciel
              etc.) ou éditoriales (mise à jour des contenus et des interfaces, ajout de nouvelles fonctionnalités etc.)
              ou pour toute autre raison.
            </p>
            <p>
              En cas de suspension ou d’interruption des Services, bugs, virus, chevaux de Troie ou autres éléments
              similaires pouvant être transmis de l’Application, <span className={styles.upperCase}>elinoï</span> n’est
              soumise à aucune obligation d’information préalable des Utilisateurs et ne pourra être tenue pour
              responsable des dommages occasionnés par ces suspensions ou interruptions aussi bien à l’égard des
              Utilisateurs que de tous tiers.
            </p>
            <p>
              La responsabilité d’elinoï ne saurait être engagée en cas d’inaccessibilité de tout ou partie des Services
              en raison de tout problème technique ou défaut de toute nature, tels que notamment :
            </p>
            <ul>
              <li>l'encombrement du réseau;</li>
              <li>une défaillance des fournisseurs d'accès Internet;</li>
              <li>une erreur humaine ou d'origine électrique;</li>
              <li>toute intervention malveillante;</li>
              <li>une défaillance et/ou un encombrement des liaisons téléphoniques;</li>
              <li>tous dysfonctionnements de logiciel ou de matériel, un cas de force majeure.</li>
              <li>
                Les frais de télécommunications permettant d’accéder aux Services restent à la charge exclusive des
                Utilisateurs.
              </li>
            </ul>
            <p>
              Les informations diffusées sur le <span className={styles.upperCase}>Site</span> sont publiées dans un but
              informatif. <span className={styles.upperCase}>elinoï</span> met en avant des informations complètes et
              qualitatives sur le site mais n’est pas en mesure d’en assurer l’exactitude.{" "}
              <span className={styles.upperCase}>elinoï</span> n’est pas responsable des informations fausses qui
              seraient diffusées et ne garantit pas que l’utilisation du <span className={styles.upperCase}>Site</span>{" "}
              ne porte atteinte aux tiers.
            </p>
            <p>
              <span className={styles.upperCase}>elinoï</span> ne peut en aucun cas être tenue responsable si un Talent
              ne trouve pas d’emploi et/ou si l’emploi ne lui convient pas, ou si une start-up ne parvient pas à
              recruter ou à recruter un Talent qui ne lui donne pas satisfaction.
            </p>

            <h3>2/ Responsabilité d'elinoï</h3>
            <p>
              <span className={styles.upperCase}>elinoï</span> ne saurait être tenue pour responsable des dommages
              occasionnés au cours de la phase d’entretien avec la start-up. En effet, les entretiens et contacts avec
              la start-up intervenant après la phase de recrutement effectuée par{" "}
              <span className={styles.upperCase}>elinoï</span> sont effectués sous la seule responsabilité des
              Utilisateurs.
            </p>
            <p>
              <span className={styles.upperCase}>elinoï</span> ne pourra être tenue responsable des infractions ou
              tentatives d’infractions pénales commises par ses Utilisateurs par l’intermédiaire des Services et/ou des
              préjudices en résultant et notamment, sans que cette liste soit exhaustive, les faits ou tentatives de :
            </p>
            <ul>
              <li>prostitution;</li>
              <li>proxénétisme;</li>
              <li>escroquerie;</li>
              <li>atteintes à la vie privée;</li>
              <li>atteintes aux droits de propriété intellectuelle;</li>
              <li>usurpation d’identité.</li>
            </ul>
            <p>
              À cet égard, les Utilisateurs renoncent à exercer tout recours contre la Société dans le cas de poursuites
              diligentées à leur encontre par un Utilisateur et/ou par un tiers, du fait de l'utilisation et/ou de
              l'exploitation illicite des Services.
            </p>
            <p>
              <span className={styles.upperCase}>elinoï</span> met en œuvre tous les moyens à sa disposition pour
              garantir une sécurité optimale des données des Utilisateurs. Néanmoins, il lui est impossible de garantir
              la sécurité absolue des réseaux. <span className={styles.upperCase}>elinoï</span> ne saurait être tenue
              responsable en cas de piratage des données dès lors qu’elle s’est conformée aux obligations qu’elle
              s’était engagée à prendre dans les présentes CGU.
            </p>
            <p>
              En effet, <span className={styles.upperCase}>elinoï</span> ne maîtrise pas les risques liés au
              fonctionnement d'internet et attire l'attention de l'Utilisateur sur l'existence d'éventuels risques en
              terme de pertes ponctuelles de données ou d'atteinte à la confidentialité des données transitant via ce
              réseau.
            </p>
            <p>
              En acceptant ces présentes CGU, l’Utilisateur reconnaît qu’il est le seul responsable des contenus qu’il
              publie (messages, correspondances avec les start-ups) et qu’elinoï est un tiers aux correspondances et
              relations entre les Utilisateurs, ce qui exclut toute responsabilité à cet égard.
            </p>
            <p>
              <span className={styles.upperCase}>elinoï</span> n’est pas responsable à l’égard des Utilisateurs et/ou de
              tous tiers de l’inexactitude des données fournies par les Utilisateurs avec qui ils sont entrés en
              relation.
            </p>
            <p>
              L’Application n’est qu’un outil de mise en relation entre les Utilisateurs qui décident librement de se
              rencontrer réellement. <span className={styles.upperCase}>elinoï</span> ne garantit donc aucunement la
              réussite de ces mises en relation professionnelles.
            </p>

            <h3>3/ Liens hypertexte</h3>
            <p>
              Il est possible que le <span className={styles.upperCase}>Site</span> propose des liens hypertextes vers
              des sites web édités et/ou gérés par des tiers.
            </p>
            <p>
              Dans la mesure où aucun contrôle n'est exercé sur ces ressources externes, l'Utilisateur reconnaît que{" "}
              <span className={styles.upperCase}>elinoï</span> n'assume aucune responsabilité relative à la mise à
              disposition de ces ressources, et ne peut être tenue responsable quant à leur contenu, les publicités, les
              produits, services ou de toutes autres composantes figurant sur ou disponible depuis ces sites web ou
              ressources, ou des dommages ou pertes découlant de ces sites web ou ressources, même si lesdits sites web
              et ressources sont liés à des partenaires ou à des fournisseurs tiers de services d’elinoï.
            </p>

            <h4>
              Garanties et obligations des utilisateurs <br />
              exactitude des informations fournies à elinoï
            </h4>

            <p>
              Les Utilisateurs s’engagent lors de la création de leur Profil à ne délivrer à{" "}
              <span className={styles.upperCase}>elinoï</span> que des informations exactes, non erronées ni
              frauduleuses ainsi qu’à les mettre à jour (ex : expériences professionnelles, diplômes, centres d’intérêt,
              requête, etc).
            </p>
            <p>
              La fourniture d’informations mensongères et/ou dans une intention frauduleuse pourront entraîner le
              prononcé des sanctions prévues à l’article 9 des présentes et donner lieu à des poursuites judiciaires.
            </p>

            <h3>4/ Politique concernant les photos des talents</h3>
            <p>
              Par la création de son Profil par le biais de <span className={styles.upperCase}>Linkedin</span>, le
              Talent accepte la publication de sa photo sur son Profil, qui est transmise sous son unique
              responsabilité.
            </p>
            <p>
              Seule <span className={styles.upperCase}>elinoï</span> et la start-up pourra avoir accès aux photos.
            </p>
            <p>La photo doit être de bonne qualité, et représenter distinctement le Talent.</p>
            <p>Sont interdites :</p>
            <ul>
              <li>les photos contenant l’image de tiers, connus ou inconnus, sans leur autorisation;</li>
              <li>les photos de groupe;</li>
              <li>les photos contenant l’image de mineurs;</li>
              <li>les photos à caractère illicite, sexuel, obscène ou pornographique;</li>
              <li>les photos représentant des symboles racistes, politiquement radicaux, haineux, ou pédophiles;</li>
              <li>les photos non libres de droit.</li>
            </ul>

            <h4>Comportements illicites des utilisateurs</h4>
            <p>
              Les Utilisateurs s’engagent à ne pas utiliser les Services à des fins illégales et à respecter les lois et
              règlements en vigueur ainsi que les droits des tiers.
            </p>
            <p>
              Les Utilisateurs s’abstiennent donc de réaliser tous actes ou tentatives d’actes illégaux par
              l’intermédiaire des Services, notamment, et sans que cette liste soit exhaustives, les faits de :
            </p>

            <ul>
              <li>prostitution;</li>
              <li>proxénétisme;</li>
              <li>escroquerie;</li>
              <li>atteintes à la vie privée;</li>
              <li>atteintes aux droits de propriété intellectuelle;</li>
              <li>usurpation d’identité.</li>
            </ul>

            <h4>Utilisation strictement personnelle des services</h4>
            <p>
              Les Utilisateurs s’engagent à utiliser le <span className={styles.upperCase}>Site</span> uniquement à des
              fins professionnelles à l’exclusion notamment de toute utilisation à des fins commerciales, publicitaires,
              lucratives, électorales.
            </p>
            <p>
              En conséquence, seules des personnes physiques peuvent s’inscrire sur l’Application pour devenir Talent, à
              l’exclusion de toutes personnes morales dotées ou non de la personnalité juridique (sociétés,
              associations, fondations, etc.).
            </p>
            <p>
              Par ailleurs, les Utilisateurs s’engagent à ne pas utiliser les Services abusivement, notamment à ne pas
              diffuser de données ou photos à caractère illicite, obscène, pornographique ou politiquement radical,
              menacer ou harceler d'autres Utilisateurs, porter atteinte aux droits d’auteurs de tiers, envoyer des
              virus par le biais de Messages ou du <span className={styles.upperCase}>Site</span> en tant que tel,
              intercepter des e-mails ou des messages et/ou tenter de les intercepter.
            </p>

            <h4>
              Protection des données personnelles
              <br />
              collecte et traitement loyaux
            </h4>

            <p>
              Pour s’inscrire et utiliser les Services du Site, les Utilisateurs fournissent à{" "}
              <span className={styles.upperCase}>elinoï</span> par l’intermédiaire de{" "}
              <span className={styles.upperCase}>Linkedin</span> ou par le formulaire « recruter », les données à
              caractère personnel suivantes :
            </p>
            <p>Pour les Talents :</p>
            <ul>
              <li>nom;</li>
              <li>prénoms;</li>
              <li>adresse IP;</li>
              <li>date de naissance;</li>
              <li>photo;</li>
              <li>adresse email;</li>
              <li>numéro de téléphone;</li>
              <li>CV;</li>
              <li>informations sur la personnalité;</li>
              <li>emploi recherché.</li>
            </ul>

            <p>Pour les start-ups :</p>
            <ul>
              <li>nom;</li>
              <li>prénoms;</li>
              <li>adresse email;</li>
              <li>fonction;</li>
              <li>dénomination sociale;</li>
              <li>numéro de téléphone;</li>
              <li>profil de Talent recherché.</li>
            </ul>

            <p>
              Ces données sont fournies par les Utilisateurs, collectées et traitées par{" "}
              <span className={styles.upperCase}>elinoï</span> dans la mesure où leur collecte est indispensable au
              fonctionnement des Services.
            </p>
            <p>
              Les données personnelles collectées ont un caractère privé et sont uniquement rendues accessibles à{" "}
              <span className={styles.upperCase}>elinoï</span>.
            </p>
            <p>
              Les données personnelles à caractère privé sont protégées et ne sont donc pas accessibles aux autres
              Utilisateurs ou aux tiers.
            </p>
            <p>
              Le <span className={styles.upperCase}>Site</span> collectant des informations sur les Utilisateurs, il a
              fait l’objet d’une déclaration préalable auprès de la CNIL enregistrée sous le numéro 1982244v1.
            </p>
            <p>
              <span className={styles.upperCase}>elinoï</span> respecte la législation en vigueur en matière de
              protection des données personnelles de ses Utilisateurs. Ainsi, conformément à la loi « informatique et
              libertés » du 6 janvier 1978 modifiée en 2004, les Utilisateurs bénéficient d’un droit d’accès et de
              rectification aux informations qui les concernent en s’adressant à l’une des adresses suivantes :
            </p>
            <p>
              david(at)elinoi.com
              <br />
              elinoï – 106 rue de Richelieu - 75002 PARIS
            </p>
            <p>Les données personnelles sont collectées pour répondre à une ou plusieurs des finalités suivantes :</p>
            <ul>
              <li>gérer le compte des utilisateurs (inscriptions et désabonnements);</li>
              <li>suivre les Talents et les start-ups partenaires; rester en contact;</li>
              <li>transmission de certaines des données aux start-ups qui recrutent;</li>
              <li>
                gérer l’accès à certains Services accessibles sur le <span className={styles.upperCase}>Site</span> et
                leur utilisation;
              </li>
              <li>connaître le profil du Talent pour procéder le cas échéant à son recrutement;</li>
              <li>constituer un fichier de Talents inscrits, d’Utilisateurs, de clients et prospects;</li>
              <li>
                adresser des newsletters et sollicitations. Dans le cas où l’Utilisateur ne le souhaiterait pas,{" "}
                <span className={styles.upperCase}>elinoï</span> lui donne la faculté de ne pas s’abonner à la
                newsletter en cochant ou non la case correspondante;
              </li>
              <li>élaborer des statistiques commerciales et de fréquentation de Site;</li>
              <li>respecter les obligations légales et réglementaires.</li>
            </ul>

            <h4>Identité du responsable de la collecte de données</h4>
            <p>
              Le responsable de la collecte de données personnelles est la société{" "}
              <span className={styles.upperCase}>elinoï</span>.
            </p>

            <h3>5/ Conservation des données</h3>
            <p>
              Conformément à l’article 6 de la loi sur la confiance dans l’économie numérique (LCEN) du 21 juin 2004, la
              Société est tenue de conserver les données relatives aux contenus hébergés pendant une durée de 1 (un) an
              à compter de leur création afin de les mettre, le cas échéant, à la disposition des autorités compétentes.
            </p>
            <p>
              Conformément à l’article précité, les données fournies par les Utilisateurs lors de la création de leur
              Profil seront conservées pendant une durée de 1 (un) an à compter de la fermeture du compte ou de la
              résiliation des présentes CGU.
            </p>
            <p>
              À sa demande, <span className={styles.upperCase}>elinoï</span> supprimera les données personnelles de
              l’Utilisateur, annulant de ce fait son inscription au <span className={styles.upperCase}>Site</span>.
            </p>

            <h3>6/ Divulgation des données</h3>
            <p>
              Le personnel de <span className={styles.upperCase}>elinoï</span> et les services chargés du contrôle
              (commissaire aux comptes notamment) auront accès aux données personnelles.
            </p>
            <p>
              <span className={styles.upperCase}>elinoï</span> peut également appel à des tiers sous-traitants pour
              assurer la prestation de ses Services (par exemple, pour la maintenance, les statistiques, les audits, la
              détection des fraudes, le marketing et le développement). Ils ont un accès aux informations dans la mesure
              nécessaire pour effectuer ces tâches au nom d’elinoï, et sont tenus de ne pas les divulguer ni les
              utiliser à d’autres fins.
            </p>
            <p>
              Pour respecter des exigences légales ou réglementaires, <span className={styles.upperCase}>elinoï</span>{" "}
              peut se voir contraindre de divulguer des informations personnelles des Utilisateurs.{" "}
              <span className={styles.upperCase}>elinoï</span> se réserve donc le droit de divulguer les informations de
              certains Utilisateurs pour respecter ses obligations légales, notamment en cas d’ordonnance des tribunaux,
              mandat, assignation ou toute autre procédure légale similaire.
            </p>
            <p>
              <span className={styles.upperCase}>elinoï</span> se réserve également le droit de divulguer les
              informations de certains Utilisateurs aux autorités compétentes dès lors que cette transmission est
              considérée par ses services comme raisonnablement nécessaire pour prévenir un dommage matériel ou un
              préjudice financier imminent, ou encore une suspicion d’activités illégales.
            </p>
            <p>
              Les données personnelles telles que régulièrement déclarées à la CNIL, pourront faire l’objet de cessions,
              locations ou échanges au bénéfice de tiers.
            </p>
            <p>
              Lorsque l’Utilisateur choisit de communiquer des Données personnelles, il donne expressément son
              consentement pour la collecte et l’utilisation de celles-ci conformément à ce qui est énoncé dans les
              présentes CGU et à la législation en vigueur.
            </p>

            <h3>7/ Conseils de sécurité</h3>
            <p>
              <span className={styles.upperCase}>elinoï</span> n’a pas pour obligation de vérifier la réalité de la
              prétendue identité de ses Utilisateurs et ne dispose pas des moyens techniques et/ou légaux le lui
              permettant. Les Utilisateurs sont par conséquent seuls responsables de la vérification de l’identité des
              personnes avant de les rencontrer réellement lors notamment d’un entretien d’embauche.
            </p>
            <p>
              <span className={styles.upperCase}>elinoï</span> ne saurait être tenue responsable de tous dommages
              occasionnés lors d’en entretien entre un Talent et une start-up ou par la suite lors de leur
              collaboration.
            </p>
            <p>
              <span className={styles.upperCase}>elinoï</span> pourra mettre fin, à tout moment et sans préavis, à
              l’accès de l’Utilisateur au Site, et/ou fermer son compte notamment si elle estime que ce dernier a
              enfreint les CGU, a agi de manière incompatible avec la lettre ou l’esprit de celles-ci ou a violé les
              droits de <span className={styles.upperCase}>elinoï</span> ou d’un tiers.
            </p>
            <p>
              <span className={styles.upperCase}>elinoï</span> pourra également modifier le{" "}
              <span className={styles.upperCase}>Site</span> ou en suspendre l’accès, sans préavis.{" "}
              <span className={styles.upperCase}>elinoï</span> ne saurait être tenue responsable, à l’égard des
              Utilisateurs ou à l’égard de tout tiers, des conséquences causées par une telle modification ou
              suspension.
            </p>

            <h3>8/ Violation des cgu</h3>
            <p>
              Le non-respect par un Utilisateur des obligations mises à sa charge par les présentes CGU peut entraîner
              des sanctions telles que la suspension de l’accès aux Services ou la résiliation du Profil. En outre,
              certains comportements réalisés sur le <span className={styles.upperCase}>Site</span> sont susceptibles
              d’entraîner des poursuites judiciaires tant pénales que civiles et{" "}
              <span className={styles.upperCase}>elinoï</span> pourra être amenée à dénoncer une violation de la loi aux
              autorités compétentes, ou encore entamer des poursuites judiciaires (civiles comme pénales) contre les
              Utilisateurs.
            </p>

            <h3>9/ Protection des droits de propriété intellectuelle</h3>
            <p>
              Les éléments reproduits sur le Site, visuels, graphismes, photos, textes, articles, logos, informations
              qui sont la propriété exclusive de l’Editeur, sont protégés par le droit d’auteur, le droit des marques et
              le droit des brevets.
            </p>
            <p>
              Il est strictement interdit de reproduire tout ou partie de ce Site, à l’exception d’un usage strictement
              personnel et à des fins privées uniquement.
            </p>
            <p>
              Toute reproduction et toute diffusion de ces éléments, sans autorisation écrite préalable de la Société,
              exposent les contrevenants à des poursuites judiciaires.
            </p>

            <h3>10/ Politique relative aux cookies</h3>
            <p>
              Lors de la consultation du Site, des cookies sont déposés sur l’ordinateur de l’Utilisateur soit par{" "}
              <span className={styles.upperCase}>elinoï</span> soit par des sociétés tierces. Les cookies sont des
              fichiers qui ont pour but de collecter des informations relatives à la navigation de l’Utilisateur. Ils
              sont gérés par le navigateur internet.
            </p>
            <p>
              L’Utilisateur peut à tout moment choisir de désactiver ses cookies. Certains navigateurs peuvent également
              être paramétrés pour signaler les cookies qui sont déposés dans l’ordinateur de l’Utilisateur et lui
              demander s’il les accepte ou pas. Les cookies peuvent être acceptés ou refusés au cas par cas ou bien
              refusés systématiquement.{" "}
            </p>
            <p>
              Si le navigateur est configuré de manière à refuser l'ensemble des cookies, l’Utilisateur ne pourra pas
              profiter d'une partie des services proposés sur le <span className={styles.upperCase}>Site</span>. Afin de
              gérer les cookies au plus près de ses attentes, l’Utilisateur est invité à paramétrer son navigateur en
              tenant compte de la finalité des différents cookies.
            </p>

            <h3>11/ Modification des cgu</h3>
            <p>
              La Société se réserve la possibilité, à tout moment, de modifier en tout ou partie ces Conditions
              Générales d’Utilisation. Les Utilisateurs sont invités à consulter régulièrement les CGU afin de prendre
              connaissance des changements apportés. Toutes modifications remplacent et annulent les précédentes
              stipulations.
            </p>

            <h3>12/ Renseignements — réclamations</h3>
            <p>
              Toute précision relative à l'application des présentes CGU, toute demande d'information ou réclamation
              relative au fonctionnement des Services de elinoï sur internet doit être adressée par E-mail à l'adresse :
              david(at)elinoi.com ou par courrier à l'adresse : elinoï - 106 rue de Richelieu - 75002 PARIS.
            </p>

            <h3>13/ Autonomie des clauses</h3>
            <p>
              Si une partie quelconque des Conditions Générales d'Utilisation devait s'avérer illégale, invalide ou
              inapplicable pour quelque raison que ce soit, le terme ou les termes en question seraient déclarés
              inexistants et les termes restants garderaient toute leur force et leur portée et continueraient à être
              applicables. Les termes déclarés inexistants seront remplacés par des termes qui se rapprocheront le plus
              du contenu de la clause annulée.
            </p>
            <p>
              En cas de contradiction entre la version en langue étrangère des présentes CGU et la version française,
              cette dernière prévaudra.
            </p>

            <h3>14/ Droit applicable</h3>
            <p>
              Les présentes CGU sont soumises à la loi française et au droit européen en vigueur grâce à l’application
              directe du Règlement européen 2016/ 679 sur le traitement des données à caractère personnel.
            </p>
            <p>
              Tout litige né de l’exécution des présentes CGU sera soumis aux juridictions compétentes sur le territoire
              français.
            </p>
            <div className={styles.ctaCGUWrapper}>
              <PDFDownloadLink className={cx(styles.ctaCGU)} document={<PDFCGU />} fileName="elinoi-cgu.pdf">
                {({ loading }) => (loading ? "Création du PDF..." : "Télécharger")}
              </PDFDownloadLink>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
