import React, { PropsWithChildren, useEffect, useRef, useState } from "react";
import useRaf from "../../hook/useRaf.js";
import useWindowResize from "../../hook/useWindowResize.js";
import { setRef } from "../../utils/dom";
import eventDispatcher from "../eventDispatcher/eventDispatcher";
import Navigation from "./navigation";
import styles from "./slideshow.module.scss";

interface Options {
  id: string;
  items: any[];
  slidesToScroll?: number;
  oldSlidesToScroll?: number;
  slidesVisible?: number;
  oldSlidesVisible?: number;
  isVertical?: boolean;
  hasNavigation?: boolean;
  styles: { [key: string]: string };
  onIndexChange?: () => void;
}

const Slideshow: React.FC<PropsWithChildren<Options>> = (props: PropsWithChildren<Options>) => {
  eventDispatcher.set("indexChange." + props.id);
  eventDispatcher.set("setIndex." + props.id);
  eventDispatcher.set("animate." + props.id);
  eventDispatcher.set("prev." + props.id);
  eventDispatcher.set("next." + props.id);

  // State
  const [mobile, setMobile] = useState<boolean>(typeof window !== "undefined" ? window.innerWidth <= 750 : false);

  let index = 0;
  const spring = 0.35;
  const friction = 0.2;

  const isTouch = useRef(false);

  let x = 0;
  let _x = 0;
  let vx = 0;
  let progress = 0;
  let y = 0;
  let _y = 0;
  let vy = 0;
  let maxX = 0;
  let maxY = 0;
  let length = 0;

  let itemsProps: any[] = [];
  let isDragging = false;
  let origin: { x: number; y: number } | null = { x: 0, y: 0 };
  let translate: { x: number; y: number } = { x: 0, y: 0 };

  let containerWidth = 0;
  let containerHeight = 0;
  let slideshowLeft = 0;
  let slideshowWidth = 0;
  let slideshowHeight = 0;

  let enable = false;
  let mouseActive = false;

  // Options
  let { slidesToScroll = 1, slidesVisible = 1 } = props;
  const { isVertical = false, hasNavigation = false } = props;

  const oldSlidesToScroll: number = slidesToScroll;
  const oldSlidesVisible: number = slidesVisible;

  // Refs
  const $root = useRef<any>(null);
  const $container = useRef<any>(null);
  const $items = useRef<HTMLElement[]>([]);

  useEffect(() => {
    isTouch.current = "ontouchstart" in window || navigator.msMaxTouchPoints > 0;
    resize();
  }, [props.items]);

  const handleMouseDown = (e: any) => {
    if (enable) {
      isDragging = false;
      mouseActive = true;

      // Touch
      e = e.touches ? e.touches[0] || e.changedTouches[0] : e;

      origin = { x: e.screenX, y: e.screenY };
      translate = { x: 0, y: 0 };

      if (!isTouch.current) {
        window.addEventListener("mouseup", handleMouseUp);
      } else {
        window.addEventListener("touchend", handleMouseUp);
      }
    }
  };

  const handleMouseMove = (e: any) => {
    if (enable && origin && mouseActive) {
      // Get translate
      e = e.touches ? e.touches[0] || e.changedTouches[0] : e;

      const p: { x: number; y: number } = { x: e.screenX, y: e.screenY };
      // tslint:disable-next-line: no-shadowed-variable
      const x = itemsProps[index].x;
      // tslint:disable-next-line: no-shadowed-variable
      const y = itemsProps[index].y;

      translate = { x: p.x - origin.x, y: p.y - origin.y };

      // Is dragging
      if (Math.abs(translate.x) < 2 || Math.abs(translate.y) < 2) {
        isDragging = false;
      } else {
        isDragging = true;
      }

      // Set translate
      _x = x - translate.x;
      // _y = app.w < 750 ? y - translate.x * 2 : y - translate.y;
      _y = y - translate.y;

      // Constrains x
      if (_x < 0) {
        _x -= _x * 0.85;
      } else if (_x > maxX) {
        _x += (maxX - _x) * 0.85;
      }

      // Constrains y
      if (_y < 0) {
        _y -= _y * 0.85;
      } else if (_y > maxY) {
        _y += (maxY - _y) * 0.85;
      }
    }
  };

  const handleMouseUp = () => {
    if (enable && origin && translate) {
      if (!isTouch.current) {
        window.removeEventListener("mouseup", handleMouseUp);
      } else {
        window.removeEventListener("touchend", handleMouseUp);
      }

      // Go to index
      if (isVertical) {
        if (
          Math.abs(translate.y) > itemsProps[index].height * 0.2
          && Math.abs(translate.y) <= itemsProps[index].height
        ) {
          if (translate.y > itemsProps[index].height * 0.2 && _y >= 0) {
            prev(true);
          } else if (translate.y < itemsProps[index].height * 0.2 && _y <= maxY) {
            next(true);
          } else {
            goToIndex(index);
          }
        } else {
          if (_y >= 0 && _y <= maxY) {
            for (let i = itemsProps.length - 1; i >= 0; i--) {
              if (
                _y + itemsProps[i].height * 0.5 > itemsProps[i].y
                && _y + itemsProps[i].height * 0.5 < itemsProps[i].endY
              ) {
                goToIndex(i);
                break;
              }
            }
          } else if (_y < 0) {
            goToIndex(0);
          } else if (_y > maxY) {
            goToIndex(length - slidesVisible);
          }
        }
      } else {
        if (Math.abs(translate.x) > itemsProps[index].width * 0.2 && Math.abs(translate.x) <= itemsProps[index].width) {
          if (translate.x > itemsProps[index].width * 0.2 && _x >= 0) {
            prev(true);
          } else if (translate.x < itemsProps[index].width * 0.2 && _x <= maxX) {
            next(true);
          } else {
            goToIndex(index);
          }
        } else {
          if (_x >= 0 && _x <= maxX) {
            for (let i = itemsProps.length - 1; i >= 0; i--) {
              if (
                _x + itemsProps[i].width * 0.5 > itemsProps[i].x
                && _x + itemsProps[i].width * 0.5 < itemsProps[i].endX
              ) {
                goToIndex(i);
                break;
              }
            }
          } else if (_x < 0) {
            goToIndex(0);
          } else if (_x > maxX) {
            goToIndex(length - slidesVisible);
          }
        }
      }

      setTimeout(() => {
        isDragging = false;
      }, 0);

      origin = null;
    }

    mouseActive = false;
  };

  const prev = (one?: boolean | number) => {
    goToIndex(typeof one === "boolean" ? index - 1 : index - slidesToScroll);
  };

  const next = (one?: boolean | number) => {
    goToIndex(typeof one === "boolean" ? index + 1 : index + slidesToScroll);
  };

  const goToIndex = (i: number, animate = true) => {
    if (typeof props.onIndexChange === "function" && index !== i) {
      props.onIndexChange();
    }
    index =
      i < 0
        ? length - slidesVisible
        : i >= length || ($items.current[index + slidesVisible] === undefined && i > index)
        ? 0
        : i;

    if (isVertical) {
      if (animate) {
        _y = itemsProps[index].y;
      } else {
        _y = y = itemsProps[index].y;
      }
    } else {
      if (animate) {
        _x = itemsProps[index].x;
      } else {
        _x = x = itemsProps[index].x;
      }
    }

    eventDispatcher.trigger("indexChange." + props.id, { index });
  };

  const update = () => {
    if (enable) {
      if (isVertical) {
        vy += (_y - y) * spring;
        y += vy *= friction;

        for (let i = length - 1; i >= 0; i--) {
          itemsProps[i].rIn = 1 - (itemsProps[i].y - y) / itemsProps[i].height;
          itemsProps[i].rOut = itemsProps[i].rIn - 1;
        }
        // tslint:disable-next-line: no-bitwise
        y = ((y * 1000) | 0) / 1000;
        // tslint:disable-next-line: no-bitwise
        progress = (((y / maxY) * 1000) | 0) / 1000;
      } else {
        vx += (_x - x) * spring;
        x += vx *= friction;

        for (let i = length - 1; i >= 0; i--) {
          itemsProps[i].rIn = 1 - ((itemsProps[i].x - x) / itemsProps[i].width) * 2;
          itemsProps[i].rOut = itemsProps[i].rIn - 1;
        }

        // tslint:disable-next-line: no-bitwise
        x = ((x * 1000) | 0) / 1000;

        // tslint:disable-next-line: no-bitwise
        progress = (((x / maxX) * 1000) | 0) / 1000;
      }

      eventDispatcher.trigger("animate." + props.id, itemsProps);

      if ($container.current) {
        $container.current.style.transform = "translate3d(" + -x + "px, " + -y + "px, 0)";
      }
    }
  };

  const resize = () => {
    enable = false;

    itemsProps = [];
    containerWidth = 0;
    containerHeight = 0;
    maxX = 0;
    maxY = 0;
    length = props.items.length;
    slideshowLeft = ($root.current && $root.current.getBoundingClientRect().left) || 0;
    slideshowWidth = ($root.current && $root.current.offsetWidth) || 0;
    slideshowHeight = ($root.current && $root.current.offsetHeight) || 0;

    $items.current.forEach((item, i) => {
      const style = window.getComputedStyle(item);
      const marginH = parseFloat(style.marginLeft) + parseFloat(style.marginRight);
      const marginV = parseFloat(style.marginTop) + parseFloat(style.marginBottom);

      itemsProps[i] = {
        x: containerWidth,
        y: containerHeight,
        width: item.offsetWidth + marginH,
        height: item.offsetHeight + marginV,
        rOut: 0,
        rIn: 0,
      };

      itemsProps[i].endX = itemsProps[i].x + itemsProps[i].width;
      itemsProps[i].endY = itemsProps[i].y + itemsProps[i].height;

      containerWidth += itemsProps[i].width;
      containerHeight += itemsProps[i].height;

      if (i < length - slidesVisible) {
        maxX += itemsProps[i].width;
        maxY += itemsProps[i].height;
      }
    });

    slidesToScroll = oldSlidesToScroll;
    slidesVisible = oldSlidesVisible;

    enable = true;
    setMobile(window.innerWidth <= 750);
  };

  eventDispatcher.on("setIndex." + props.id, goToIndex);
  eventDispatcher.on("prev." + props.id, prev);
  eventDispatcher.on("next." + props.id, next);

  useWindowResize(() => resize());
  useRaf(() => update());

  const children = React.Children.toArray(props.children);

  return (
    <div
      ref={$root}
      className={`${styles.slideshow} ${props.styles.slideshow || ""}`}
      onMouseDown={handleMouseDown}
      onTouchStart={handleMouseDown}
      onMouseMove={handleMouseMove}
      onTouchMove={handleMouseMove}
    >
      <ul ref={$container} className={props.styles.slideshowContainer}>
        {props.items.map((_item, i) => (
          <li key={i} ref={(el) => setRef(el, $items)} className={props.styles.slideshowItem}>
            {children[i]}
          </li>
        ))}
      </ul>

      {children.map((item: any) => (typeof item.type !== "function" ? item : ""))}

      {props.hasNavigation ? <Navigation id={props.id} items={props.items} styles={styles} /> : ""}
    </div>
  );
};

export default Slideshow;
