import anime from "animejs";
import cx from "classnames";
import { formatDistance, subDays } from "date-fns";
import { fr } from "date-fns/locale";
import { MiniSignalBinding } from "mini-signals";
import React, { useEffect, useRef, useState } from "react";
import { MdReplay } from "react-icons/md";

import { chunkArray } from "../../helpers/chunkArray";

import eventDispatcher from "../../helpers/eventDispatcher/eventDispatcher";
import { QuoteIcon } from "../../helpers/Svg";
import { sendGA } from "../../helpers/tracking";
import global from "../../scss/global.module.scss";
import { getPublishedReviews } from "../../utils/api";
import { setRef } from "../../utils/dom";
import { IReview } from "../../utils/interfaces";
import { LoginModalOpen } from "../loginModal/loginModal";
import { reviewsData } from "./reviews";
import styles from "./userReviews.module.scss";

const UserReviews: React.FC = (props) => {
  const {} = props;
  const [reviews, setReviews] = useState<IReview[]>(reviewsData);
  const resizeRef = useRef<MiniSignalBinding>();
  const [mobile, setMobile] = useState<boolean>(typeof window !== "undefined" ? window.innerWidth <= 750 : false);

  const $overlays = useRef<HTMLElement[]>([]);
  let currPage = useRef<number>(0).current;
  const [page, setPage] = useState<number>(0);
  const perPage = mobile ? 1 : 3;
  const reviewsPages: IReview[][] = chunkArray(reviews, perPage);
  const maxPage = useRef<number>(reviewsPages.length - 1).current;
  const isAnimating = useRef<boolean>(false);
  const onClickSwitchCta = () => {
    if (isAnimating.current) {
      return;
    }
    isAnimating.current = true;
    currPage = page + 1 > maxPage ? 0 : page + 1;
    swtichPage(currPage);
    sendGA("refresh", {
      event_label: "avis - refresh",
    });
  };
  const swtichPage = (pageIndex: number) => {
    const tl = anime.timeline({
      duration: 600,
      easing: "cubicBezier(0.74, 0.04, 0.34, 0.95)",
      complete: () => {
        if (pageIndex === page) {
          showTeamItem();
          return;
        }
        $overlays.current = [];
        setPage(pageIndex);
      },
    });

    tl.add({
      translateY: ["-101%", 0],
      targets: $overlays.current,
      delay: anime.stagger(80),
    });
  };

  const showTeamItem = () => {
    const tl = anime.timeline({
      duration: 600,
      easing: "cubicBezier(0.74, 0.04, 0.34, 0.95)",
      complete: () => {
        isAnimating.current = false;
      },
    });

    tl.add({
      translateY: [0, "101%"],
      targets: $overlays.current,
      delay: anime.stagger(80),
    });
  };

  useEffect(() => {
    resizeRef.current = eventDispatcher.on("_resize", resize);
    showTeamItem();

    return () => {
      if (resizeRef.current) {
        eventDispatcher.off("_resize", resizeRef.current);
      }
    };
  }, []);

  useEffect(() => {
    showTeamItem();
  }, [page]);

  const resize = ({ wW }: { wW: number }) => {
    setMobile(wW <= 750);
  };

  if (!reviews.length) {
    return null;
  }

  return (
    <div className={global.container}>
      <div className={cx(styles.titleContainer, global.container, global.medium, global.right)}>
        <h2 className={cx(styles.title, global.title)}>
          Découvrez tous <strong>leurs avis</strong> :
        </h2>
        <p className={styles.reviewBlocText}>
          Qui de mieux placé pour parler de nous que des talents qui ont vécu l'expérience elinoï !
        </p>
      </div>
      <div className={styles.listSwitchContainer}>
        <div className={styles.switchContainer}>
          <span className={styles.switchCta} onClick={onClickSwitchCta}>
            <MdReplay />
          </span>
        </div>
        <ul className={styles.list}>
          {reviewsPages[page].map((r, i) => {
            // const date = r.responded ? formatDistance(Number(r.responded), new Date(), { locale: fr }) : null;
            return (
              <li key={`reviewitem-${i}`} className={styles.listItem}>
                <QuoteIcon className={styles.quoteSvg} />
                <QuoteIcon className={styles.quoteSvgSmall} />
                <div className={styles.overlayContainer}>
                  <span className={styles.overlay} ref={(el) => setRef(el, $overlays)}></span>
                </div>
                <div className={styles.itemContent}>
                  <div className={styles.reviewTextContainer}>
                    <p className={styles.reviewText}>{r.comment}</p>
                  </div>
                  <div className={styles.nameDateContainer}>
                    <span className={styles.name}>{r.name}</span>
                    <span className={styles.date}>{r.responded}</span>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
      <div className={cx(global.container, global.medium, global.right)}>
        <p className={styles.reviewBlocText}>
          Et si vous aussi vous souhaitez rejoindre l’incubateur et vivre l’expérience elinoï, c’est par ici :
        </p>
      </div>
      <div className={cx(styles.ctaContainer, global.container, global.small, global.right)}>
        <span
          className={cx(global.cta, global.ctaPrimary)}
          onClick={() => {
            eventDispatcher.trigger(LoginModalOpen);
          }}
        >
          Rejoindre l'incubateur
        </span>
      </div>
    </div>
  );
};

export default UserReviews;
