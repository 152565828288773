import cx from "classnames";
import { FieldProps, useField } from "formik";
import * as React from "react";
import PhoneInput from "react-phone-number-input";
import fr from "react-phone-number-input/locale/fr.json";
import "react-phone-number-input/style.css";
import { ErrorMessage } from "../ErrorMessage";

interface Props extends FieldProps {
  className: string;
  label: React.ReactNode;
  id: string;
  type: string;
  placeholder: string;
  showErrorMessage: boolean;
}

export const PhoneNumberInput: React.FC<Props> = ({
  className,
  field,
  form: { setFieldTouched, setFieldValue },
  label,
  id,
  type,
  placeholder,
  showErrorMessage = true,
}: Props) => {
  const [, meta] = useField(field.name);
  const [isFocused, setIsFocused] = React.useState(false);
  const [isNotEmpty, setIsNotEmpty] = React.useState(false);
  const [phoneNumber, setPhoneNumber] = React.useState("");

  const fieldLabel =
    typeof label === "string" ? (
      <label className="label-fix" htmlFor={field.name}>
        {label}
      </label>
    ) : (
      label
    );

  const onBlurInput = (e: React.FocusEvent<string>) => {
    setIsNotEmpty(!!field.value);
    setFieldTouched(field.name, true);
    setIsFocused(false);
    field.onBlur(e);
  };
  const onFocusInput = () => {
    setIsFocused(true);
  };
  const onChangeInput = (phone: string) => {
    setPhoneNumber(phone);
    setFieldValue(field.name, phone);
  };
  return (
    <>
      {fieldLabel}
      <PhoneInput
        value={phoneNumber}
        name={field.name}
        className={cx(
          className,
          { isNotEmpty },
          { isFocused },
          { isDirty: meta.touched },
          { isNotValid: !!meta.error },
        )}
        type={type}
        id={id ? id : field.name}
        onFocus={onFocusInput}
        onBlur={onBlurInput}
        onChange={onChangeInput}
        placeholder={placeholder}
        size={5}
        defaultCountry="FR"
        labels={fr}
      />

      {showErrorMessage && <ErrorMessage name={field.name} />}
    </>
  );
};
