import { RouteComponentProps } from "@reach/router";
import anime from "animejs";
import cx from "classnames";
import { MiniSignalBinding } from "mini-signals";
import React, { useEffect, useRef } from "react";

import SEO from "../components/seo";
import eventDispatcher from "../helpers/eventDispatcher/eventDispatcher";
import { NavigateData } from "../helpers/NavLink";
import { LogoSmallOutline } from "../helpers/Svg";
import { onInitPageLoadTracking } from "../helpers/tracking";

import global from "../scss/global.module.scss";
import styles from "../scss/pages/mentions-legales.module.scss";

export const MentionsLegales: React.FC<RouteComponentProps> = (props: RouteComponentProps) => {
  const { location } = props;
  const navRef = useRef<MiniSignalBinding>();
  const pageRef = useRef(null);
  const delay = 200;

  useEffect(() => {
    onInitPageLoadTracking();
    enterAnimation();
    eventDispatcher.trigger("_initComponent", { delay });
    navRef.current = eventDispatcher.on("_navigate", exitAnimation);

    return () => {
      if (navRef.current) {
        eventDispatcher.off("_navigate", navRef.current);
      }
    };
  }, []);

  const exitAnimation = (d: NavigateData) => {
    const tl = anime.timeline({
      complete: () => {
        d.callback();
      },
      duration: 600,
      easing: "easeInOutExpo",
    });

    tl.add({
      opacity: [1, 0],
      targets: pageRef.current,
    });
  };
  const enterAnimation = () => {
    const tl = anime.timeline({
      delay,
      duration: 400,
      easing: "linear",
    });

    tl.add({
      opacity: [0, 1],
      targets: pageRef.current,
    });
  };

  return (
    <>
      <SEO
        meta={[
          {
            property: "og:url",
            content: location.href,
          },
        ]}
        title="elinoï - Mentions légales"
      />
      <div style={{ opacity: 0 }} className={global.pageWrapper} ref={pageRef}>
        <div className={styles.introWrapper}>
          <div className={styles.outlineLogoContainer}>
            <LogoSmallOutline />
          </div>
          <div className={styles.introContainer}>
            <div className={cx(styles.halfCircleContainer, global.container, global.medium, global.right)}>
              <div className={styles.halfCircle}></div>
            </div>
            <h2 className={styles.title} ref={React.createRef()}>
              <span>Mentions</span>
              <span>
                <strong>Légales</strong>
              </span>
            </h2>
          </div>
        </div>

        <div className={cx(global.container, global.medium)}>
          <div className={styles.mentionLegalesContainer}>
            <p>
              Conformément aux dispositions des articles 6-III-1° et 19 de la loi n° 2004-575 du 21 juin 2004 pour la
              confiance dans l’économie numérique entrée en vigueur le 23 juin 2004, les coordonnées de l’éditeur et de
              l’hébergeur du Site sont indiquées ci-dessous.
            </p>

            <div className={styles.threeItems}>
              <div>
                <h2 className={styles.mentionsTitle}>Éditeur</h2>
                <p>
                  Ce site est édité par ELINOÏ - 106 rue de Richelieu - 75002 PARIS (FRANCE).
                  <br />
                  <br />
                  Directeur de la publication: David SIMEONI
                  <br />
                  <br />
                  Email: david(at)elinoi.com
                </p>
              </div>
              <div>
                <h2 className={styles.mentionsTitle}>Hébergeur</h2>
                <p>
                  Ce site est hébergé par AMAZON WEB SERVICES, INC. dont le siège social est situé à l'adresse:
                  <br />
                  <br />
                  P.O. Box 81226
                  <br />
                  <br />
                  Seattle, WA 98108-1226
                  <br />
                  <br />
                  Site: http://aws.amazon.com
                </p>
              </div>
              <div>
                <h2 className={styles.mentionsTitle}>Crédits</h2>
                <p>
                  Développement du site: elinoï
                  <br />
                  <br />
                  Tous droits réservés © 2018 ELINOÏ
                </p>
              </div>
            </div>

            <h2 className={styles.mentionsTitle}>Propriété intellectuelle</h2>
            <p>
              Tous les éléments constituant le présent site, qu’ils soient visuels ou sonores, appartiennent à l’éditeur
              et sont à ce titre protégés par la législation relative à la Propriété Intellectuelle. Les internautes
              reconnaissent donc que, en l’absence d’autorisation, toute copie totale ou partielle et toute diffusion ou
              exploitation d’un ou plusieurs de ces éléments, même modifiés, seront susceptibles de donner lieu à des
              poursuites judiciaires menées à leur encontre par l’Editeur ou ses ayants droits. Cette protection portera
              sur tous les contenus textuels et graphiques du site, mais également sur sa structure, sur son nom et sa
              charte graphique.
            </p>
            <p>
              Toute reproduction totale ou partielle de son logo, effectuée à partir des éléments du site sans
              l’autorisation expresse de l’Editeur est donc prohibée, au sens de l’article L.713-2 du Code la Propriété
              Intellectuelle.
            </p>
            <p>
              Tout lien hypertexte renvoyant au présent site ou placé sur la page d’accueil du site doit faire l’objet
              d’une autorisation préalable expresse de la part de l’Editeur.
            </p>
            <p>
              L’utilisateur est notamment informé que conformément à l’article 32 de la loi Informatique et libertés du
              6 janvier 1978 modifiée, les informations qu’il communique par le formulaire présent sur le site «
              www.elinoi.com» sont nécessaires pour répondre à sa demande.
            </p>
            <p>
              Conformément à la loi « informatique et libertés », vous bénéficiez d’un droit d’accès et de rectification
              aux informations qui vous concernent, que vous pouvez exercer en vous adressant à David SIMEONI - 106 rue
              de Richelieu - 75002 PARIS ou par email à david(at)elinoi.com.
            </p>
            <p>
              Ce site ne saurait être tenu pour responsable des erreurs rencontrées sur le site, problèmes techniques,
              interprétation des informations publiée et conséquences de leur utilisation. En conséquence, l’utilisateur
              reconnaît utiliser ces informations sous sa responsabilité exclusive.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
